import { setCookie, CookieKeys, getCookie } from "../../../lib/cookies";
import {
  createAccessToken,
  createUser,
  setAccessToken,
} from "../../visitWidgetApi";
import {
  registerError,
  registerFetch,
  registerSuccess,
} from "../authActionTypes";
import { initializeUser } from "../helpers/initializeUser";
import { buildErrorMessage } from "../helpers/buildErrorMessage";

/*
 * Register user.
 */
export const registerUserAction =
  ({
    email,
    password,
    name,
    newsletters,
    captcha,
    postalCode,
  }: {
    email: string;
    password: string;
    name: string;
    newsletters: { id: number }[];
    captcha: string;
    postalCode?: string;
  }) =>
  async (dispatch) => {
    try {
      dispatch(registerFetch());

      const planId = await getCookie(CookieKeys.planId, undefined);
      const user = await createUser({
        email,
        password,
        name,
        newsletters,
        captcha,
        postalCode,
        planId,
      });

      const accessToken = await createAccessToken(email, password);
      setCookie(CookieKeys.authToken, accessToken);
      setAccessToken(accessToken);
      await initializeUser(accessToken, dispatch);

      dispatch(registerSuccess(user, accessToken));

      // BRIDGE: hybrid action
      VisitWidget.Modal.close($(".modal"));
    } catch (error) {
      const errorMessage = buildErrorMessage(error);
      alert(errorMessage);
      dispatch(registerError(errorMessage));
    }
  };
