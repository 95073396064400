/* eslint-disable complexity */
import React, { FunctionComponent } from "react";
import { Modal as AntModal } from "antd";
import "./Modal.scss";
import { CloseIcon } from "../icons";

const ModalTitle: FunctionComponent<{
  children: React.ReactNode;
  fontSize?: "tiny" | "small" | "large" | "regular";
}> = ({ children, fontSize = "medium" }) => {
  return <div className={`modal-title-${fontSize}`}>{children}</div>;
};

const ModalContent: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <div className={"modal-content"}>{children}</div>;
};

const ModalAction: FunctionComponent<{
  children: React.ReactNode;
  position: "left" | "right";
}> = ({ children }) => {
  return <div className={"modal-actions"}>{children}</div>;
};

type ModalChildType =
  | typeof ModalTitle
  | typeof ModalContent
  | typeof ModalAction;

const Modal: FunctionComponent<ModalProps> = ({
  open,
  width,
  onClose,
  children,
  afterClose,
}) => {
  let title = null;
  const actionsLeft = [];
  const actionsRight = [];
  let content = null;
  React.Children.toArray(children).forEach(
    (child: {
      type: ModalChildType;
      props: { position?: "left" | "right" };
    }) => {
      if (child.type === ModalTitle) {
        title = child;
      } else if (child.type === ModalContent) {
        content = child;
      } else if (
        child.type === ModalAction &&
        child.props.position === "left"
      ) {
        actionsLeft.push(child);
      } else if (child.type === ModalAction) {
        actionsRight.push(child);
      }
    },
  );
  return (
    <>
      <AntModal
        afterClose={afterClose}
        title={title ?? <>&nbsp;</>}
        centered
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        open={open}
        width={width}
        closeIcon={<CloseIcon size={22} />}
        footer={
          (actionsLeft.length > 0 || actionsRight.length > 0) && (
            <div className={"modal-actions"}>
              <div className="modal-actions-left">
                {actionsLeft.map((action, index) => (
                  <React.Fragment key={index}>{action}</React.Fragment>
                ))}
              </div>
              <div className="modal-actions-right">
                {actionsRight.map((action, index) => (
                  <React.Fragment key={index}>{action}</React.Fragment>
                ))}
              </div>
            </div>
          )
        }
        onCancel={onClose}>
        {content}
      </AntModal>
    </>
  );
};

interface ModalProps {
  open: boolean;
  width?: number;
  onClose: () => void;
  afterClose?: () => void;
  children: React.ReactNode | React.ReactNode[];
}

export { Modal, ModalTitle, ModalContent, ModalAction };
