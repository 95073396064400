import React, { FunctionComponent, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../store/rootReducer";
import translate from "../../../lib/queries/translate";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { updatePasswordAction } from "../../../store/auth/actions/updatePasswordAction";
import { getQueryStringValue } from "../../../lib/url";
import "./UpdatePassword.scss";
import { AppDispatch } from "../../../store/store";

// eslint-disable-next-line no-shadow
const enum UpdatePasswordTestIds {
  passwordInput = "password-input",
  passwordConfirmInput = "password-confirm-input",
  submitInput = "submit-button",
}

// temporary hack for Santa Fe Margarita Trail, should be removed no later than end of Sep 2023
const deepLinkTemporaryHack = {
  953: {
    apple: 1237416826,
    android: "co.ibounce.bouncechat.santafemargarita",
  },
};

const getAppUrl = (): string | undefined => {
  const isApple = navigator.userAgent.match(/iPhone/i);
  const isAndroid = navigator.userAgent.match(/Android/i);
  const deepLinkHack =
    deepLinkTemporaryHack[VisitWidget.settings.currentClientId];
  if (deepLinkHack === undefined || (!isApple && !isAndroid)) {
    return;
  }

  if (isAndroid) {
    return `http://play.google.com/store/apps/details?id=${deepLinkHack.android}`;
  } else {
    return `https://itunes.apple.com/us/app/id${deepLinkHack.apple}`;
  }
};

const UpdatePasswordBase: FunctionComponent = () => {
  const passwordInput = useRef<HTMLInputElement | null>(null);
  const passwordConfirmInput = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const updatePasswordStatus = useSelector(
    (x: RootReducerState) => x.auth.updatePasswordStatus,
  );

  const appUrl = getAppUrl();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (updatePasswordStatus === "fetch") {
        return;
      }

      if (passwordInput.current.value !== passwordConfirmInput.current.value) {
        alert(
          translate(
            "authentication.reset_password.passwords_do_not_match_message",
          ),
        );
        return;
      }
      dispatch(
        updatePasswordAction({
          newPassword: passwordInput.current.value,
          resetPasswordToken: getQueryStringValue("reset_password_token"),
        }),
      );
    },
    [updatePasswordStatus, dispatch],
  );

  const handleBackPress = useCallback((event) => {
    event.preventDefault();
    VisitWidget.registrationModal.showSignInView();
  }, []);

  return (
    <>
      {updatePasswordStatus !== "success" && (
        <form id="edit_user_password">
          <div className="form-inputs">
            <label htmlFor="edit_user_password_user_password">
              {translate("authentication.form.password_label")}
            </label>
            <input
              id="edit_user_password_user_password"
              className="password required"
              data-testid={UpdatePasswordTestIds.passwordInput}
              type="password"
              name="user[password]"
              ref={passwordInput}
              tabIndex={0}
            />
            <label htmlFor="edit_user_password_user_password_confirmation">
              {translate("authentication.form.confirm_password_label")}
            </label>
            <input
              id="edit_user_password_user_password_confirmation"
              className="password required"
              data-testid={UpdatePasswordTestIds.passwordConfirmInput}
              type="password"
              name="user[password_confirmation]"
              ref={passwordConfirmInput}
              tabIndex={0}
            />
          </div>

          <div className="form-actions">
            <input
              type="submit"
              name="commit"
              data-testid={UpdatePasswordTestIds.submitInput}
              value={translate("authentication.reset_password.reset_button")}
              className="btn"
              tabIndex={0}
              onClick={handleSubmit}
            />
          </div>

          <a onClick={handleBackPress} className="open-sign-in all-caps">
            {translate("authentication.back_link")}
          </a>
        </form>
      )}
      {/* generally, the user never sees success message but instead is taken to home screen */}
      {updatePasswordStatus === "success" && (
        <div className="update-password-result">
          <div>
            {translate("authentication.reset_password.success_message")}
          </div>
          {appUrl && (
            <div>
              <a href={appUrl} target="_blank" rel="noreferrer">
                Open App
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const UpdatePassword = withReduxProvider(UpdatePasswordBase);
export { UpdatePassword, UpdatePasswordTestIds };
