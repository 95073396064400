import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { updateSharedPlanAction } from "../../../store/plan/actions/updateSharedPlanAction";
import { Button } from "../../Button";
import "./TourEditGlobalBar.scss";

const TourEditGlobalBar: FunctionComponent<TourEditGlobalBarProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [state, setState] = useState<{ status: string }>({ status: "idle" });

  const handleSave = () => {
    (async () => {
      try {
        setState({ status: "fetching" });
        await dispatch(updateSharedPlanAction());
        setState({ status: "idle" });
      } catch (error) {
        setState({ status: "error" });
      }
    })();
  };

  return (
    <>
      <div className="tour-edit-global-bar-container">
        <div className="tour-edit-global-bar-instructions">
          Shared Plan Edit Mode: Add or remove places and events to this shared
          plan, then click done editing:
        </div>
        <div className="tour-edit-global-bar-save">
          <Button
            type="dangerDefault"
            onPress={handleSave}
            disabled={state.status === "fetching"}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TourEditGlobalBarProps {}

export { TourEditGlobalBar, TourEditGlobalBarProps };
