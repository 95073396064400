import { Category } from "../../../../lib/types";
import React from "react";
import Select from "react-select";
import SingleValue from "./components/SingleValue";
import createOptions from "./helpers/createOptions";

import getCustomStyles from "./helpers/getCustomStyles";
import getTheme from "./helpers/getTheme";
import useChangeCallback from "./hooks/useChangeCallback";

type Props = {
  categories: Array<Category>;
  onChange?: Function;
  selectedExclusiveCategoryId?: number;
};

const ExclusiveCategoriesDropDown = ({
  categories,
  onChange: onSelectedCategoryIdChanged,
  selectedExclusiveCategoryId,
}: Props) => {
  const options = createOptions(categories);

  const defaultValue = options.find((option) => option.value === null);

  const selectedOption =
    selectedExclusiveCategoryId === undefined
      ? undefined
      : options.find((option) => option.value === selectedExclusiveCategoryId);

  const changeCallback = useChangeCallback(
    onSelectedCategoryIdChanged,
    selectedOption,
  );

  return (
    <div>
      <Select
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          SingleValue: SingleValue,
        }}
        onChange={changeCallback}
        options={options}
        styles={getCustomStyles()}
        theme={getTheme}
        defaultValue={defaultValue}
        value={selectedOption}
        isSearchable={false}
      />
    </div>
  );
};

export default ExclusiveCategoriesDropDown;
