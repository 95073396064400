type WindowMessageType = "setCookie";

interface WindowMessage {
  messageType: WindowMessageType;
  payload: { [key: string]: unknown };
  id: string;
}

let messageIdCounter = 1;
const subscriptions = new Map<
  string,
  (data: { [key: string]: unknown }) => void
>();

const addWindowMessageListener = (
  messageId: string,
  callback: (data: { [key: string]: unknown } | string) => void,
) => {
  subscriptions.set(messageId, callback);
};

const removeWindowMessageListener = (messageId: string) => {
  subscriptions.delete(messageId);
};

const receiveMessage = (data: string) => {
  let messageData: WindowMessage;
  try {
    messageData = JSON.parse(data) as WindowMessage;
  } catch (e) {
    // do nothing
    return;
  }

  const listener = subscriptions.get(messageData.id);
  if (listener) {
    listener(messageData.payload);
  }
};

const sendMessage = (
  messageType: string,
  payload: { [key: string]: unknown },
  timeout = 5000,
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const messageId = `${messageIdCounter++}`;
    window.parent.postMessage(
      JSON.stringify({ type: messageType, payload: payload, id: messageId }),
      // TODO: origin
      "*",
    );
    let hasCompleted = false;
    addWindowMessageListener(messageId, (data: string) => {
      if (hasCompleted) {
        return;
      }
      hasCompleted = true;
      removeWindowMessageListener(messageId);
      resolve(data);
    });
    setTimeout(() => {
      if (hasCompleted) {
        return;
      }
      hasCompleted = true;
      removeWindowMessageListener(messageId);
      reject("timeout");
    }, timeout);
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isValidMessage = (event: MessageEvent<string>) => {
  return true;
  // TODO: needs to be dynamic based on client
  // return event.origin === "https://oxenfur.test";
};

window.addEventListener("message", function (event: MessageEvent<string>) {
  if (!isValidMessage(event)) {
    return;
  }
  receiveMessage(event.data);
});

export { sendMessage };
