import React, { useState, useEffect, FunctionComponent } from "react";
import "./OtpVerification.css";
import { Form } from "antd";
import { Button } from "src/components/Button/Button";
import { TextInput } from "src/components/TextInput/TextInput";
import { resendOtp } from "../../../store/visitWidgetApi";
import { loginAction } from "src/store/auth/actions/loginAction";
import { useDispatch } from "react-redux";

interface OtpVerificationValueTypes {
  verificationCode: string | null;
}

const instructionText = {
  initial: "To continue, please enter the 6-digit code we sent to your email.",
  resent: "A new code has been sent to your email.",
};

const OtpVerification: FunctionComponent = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [instruction, setInstruction] = useState<string>(
    instructionText.initial,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (errors.length > 0) {
      void form.validateFields(["verificationCode"]);
    }
  }, [errors, form]);

  const onResendCode = async () => {
    setLoading(true);

    try {
      await resendOtp();
    } catch (error) {
      // TODO: Replace with logger
      // eslint-disable-next-line no-console
      console.warn(error);
      setErrors(["Error resending OTP code. Please try logging in again."]);
    }
    setInstruction(instructionText.resent);
    setLoading(false);
  };

  const onSubmit = (values: OtpVerificationValueTypes) => {
    const { verificationCode } = values;
    setLoading(true);

    if (verificationCode) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(loginAction({ otp: verificationCode }) as any);
    }

    setLoading(false);
  };

  const resetMessaging = () => {
    setInstruction(instructionText.initial);
    setErrors([]);
  };

  const initialValues: OtpVerificationValueTypes = {
    verificationCode: null,
  };

  return (
    <>
      <div>
        <p>{instruction}</p>
      </div>

      <div className="form">
        <Form
          form={form}
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
          initialValues={initialValues}>
          <TextInput
            name="verificationCode"
            label="Verification code"
            maxLength={6}
            placeholder="Enter 6-digit code"
            onChange={() => resetMessaging()}
            rules={[
              {
                required: true,
                len: 6,
                message: "Please enter a 6-digit code",
              },
            ]}
            form={form}
          />

          <div className="login-action">
            <Button
              type="primary"
              disabled={loading}
              shape="round"
              configProviderOverride="regular"
              onPress={() => {
                form.submit();
              }}>
              Verify
            </Button>
          </div>

          <div className="login-sub-action">
            <Button
              type="link"
              disabled={loading}
              size="small"
              configProviderOverride="regular"
              onPress={() => void onResendCode()}>
              Resend code
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export { OtpVerification };
