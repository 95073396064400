import React, { FunctionComponent } from "react";
import { FormInstance, TimePicker as AntTimePicker } from "antd";
import { ConfigProvider } from "../ConfigProvider";
import { ArrowRightIcon } from "../icons";
const { RangePicker } = AntTimePicker;

const TimeRangePicker: FunctionComponent<TimeRangePickerProps> = ({
  disabled = false,
  size = "large",
  name,
  form,
  onChange,
  testID,
}) => {
  return (
    <ConfigProvider type="regular">
      <RangePicker
        style={{ width: "100%", height: size === "large" ? "46px" : "" }}
        disabled={disabled}
        size={size}
        name={name}
        onChange={onChange}
        defaultValue={form?.getFieldValue(name) ?? []}
        data-testid={testID}
        separator={<ArrowRightIcon size={16} />}
        use12Hours
        minuteStep={5}
        format="h:mm A"
      />
    </ConfigProvider>
  );
};

interface TimeRangePickerProps {
  /**
   * The height of the component.
   */
  size?: "small" | "middle" | "large";
  /**
   * Disable editing of the input.
   */
  disabled?: boolean;
  /**
   * DO NOT SET: Automatically passed in via Form.Item being the direct parent element.
   */
  onChange?: (values: any, formatString: [string, string]) => void;
  /**
   * Parent form, needed for default value to work.
   */
  form?: FormInstance<any>;
  /**
   * Parent form name of input, needed for default value to work.
   */
  name?: string;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
}

export { TimeRangePicker, TimeRangePickerProps };
