import { UseCallBackType } from "../../../lib/types";
import { useCallback } from "react";

export default (setSelectedExclusiveCategoryId?: Function): UseCallBackType => {
  return useCallback(
    (categoryId) => {
      for (const storageMenuItem of VisitWidget.store.menuItems) {
        if (
          storageMenuItem.category_ids.includes(categoryId) ||
          categoryId === null
        ) {
          storageMenuItem.selectedExclusiveCategoryId = categoryId;
        }
      }
      if (setSelectedExclusiveCategoryId) {
        setSelectedExclusiveCategoryId(categoryId);
      }
    },
    [setSelectedExclusiveCategoryId],
  );
};
