import translate from "../../../../../lib/queries/translate";
import React from "react";
import { components, SingleValueProps } from "react-select";

const SingleValue = (props: SingleValueProps) => (
  <components.SingleValue {...props}>
    {translate("exclusive_category_filter.label") + " " + props.children}
  </components.SingleValue>
);

export default SingleValue;
