import React, { FunctionComponent } from "react";
import { selectUser } from "../../../store/auth/authSelectors";
import { UnauthenticatedProfileMenu } from "./UnauthenticatedProfileMenu";
import { AuthenticatedProfileMenu } from "./AuthenticatedProfileMenu";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { useSelector } from "react-redux";

const ProfileBase: FunctionComponent<{}> = () => {
  const user = useSelector(selectUser());
  return (
    <>
      {!!user && <AuthenticatedProfileMenu user={user} />}
      {!user && <UnauthenticatedProfileMenu />}
    </>
  );
};

const Profile = withReduxProvider(ProfileBase);
export { Profile };
