import { SetFlyoutAction } from "./flyoutActionTypes";

export interface FlyoutState {
  component?: any;
  props?: any;
}

const initialState = (): FlyoutState => {
  return {};
};

type FlyoutActions = SetFlyoutAction;

const flyoutReducer = (state = initialState(), action: FlyoutActions) => {
  if (JSON.stringify(state) === "{}") {
    // ReactOnRails is a hack, and on bootstrap it will randomly lose initial
    // state, so reset it until it stick
    state = initialState();
  }

  const { payload } = action;

  return {
    ...state,
    ...payload,
  };
};

export { flyoutReducer };
