import { setFlyoutAction } from "../../flyout/actions/setFlyoutAction";
import { deleteTour } from "../../visitWidgetApi";
import { listToursAction } from "./listToursAction";

const deleteTourAction = (tourId: number) => async (dispatch) => {
  try {
    await deleteTour(tourId);
  } catch (exception) {
    // do nothing
  }

  dispatch(listToursAction(VisitWidget.store.categoryIds, true));
  dispatch(setFlyoutAction(undefined, undefined));
};

export { deleteTourAction };
