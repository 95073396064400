import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import ExclusiveCategoriesDropDown from "../controls/ExclusiveCategoriesDropDown";
import ResetButton from "../controls/ResetButton";
import getMenuItemExclusiveCategories from "../../../domain/navigation/queries/getMenuItemExclusiveCategories";
import useChangeExclusiveCategoryCallback from "../../shared/hooks/useChangeExclusiveCategoryCallback";
import { MenuItem } from "../../../lib/types";

type Props = {
  menuItem: MenuItem;
};

const EntityListHeader = ({ menuItem }: Props) => {
  const exclusiveCategories = getMenuItemExclusiveCategories(menuItem);
  const [selectedExclusiveCategoryId, setSelectedExclusiveCategoryId] =
    useState(null);

  useEffect(() => {
    const categoriesChangedEventHandler = () => {
      // WARNING: this is called when categories change, including from other
      // EntityListHeader components
      const storageMenuItem = VisitWidget.store.menuItems.find(
        (m) => m.id === menuItem.id,
      );

      if (
        storageMenuItem.selectedExclusiveCategoryId !==
          selectedExclusiveCategoryId &&
        storageMenuItem.selectedExclusiveCategoryId !== undefined
      ) {
        setSelectedExclusiveCategoryId(
          storageMenuItem.selectedExclusiveCategoryId,
        );
      }
    };
    VisitWidget.EventBus.subscribe(
      "CategoriesChanged",
      categoriesChangedEventHandler,
    );

    return function unsubscribe() {
      VisitWidget.EventBus.unsubscribe(
        "CategoriesChanged",
        categoriesChangedEventHandler,
      );
    };
  }, [
    menuItem.id,
    selectedExclusiveCategoryId,
    setSelectedExclusiveCategoryId,
  ]);

  const onChange = useChangeExclusiveCategoryCallback(
    setSelectedExclusiveCategoryId,
  );

  const onReset = useCallback(() => {
    setSelectedExclusiveCategoryId(null);
  }, []);

  if (exclusiveCategories.length === 0) {
    return <></>;
  }

  return (
    <div className="EntityListHeader">
      <ExclusiveCategoriesDropDown
        categories={exclusiveCategories}
        onChange={onChange}
        selectedExclusiveCategoryId={selectedExclusiveCategoryId}
      />
      <ResetButton onClick={onReset} />
    </div>
  );
};

export default EntityListHeader;
