/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const MapItCircleIcon = ({ size = 24, testID }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    data-testid={testID}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM7.33334 10C7.33334 7.42268 9.42268 5.33334 12 5.33334C14.5773 5.33334 16.6667 7.42268 16.6667 10C16.6667 13.3333 12 18.6667 12 18.6667C12 18.6667 7.33334 13.3333 7.33334 10ZM10.3333 10C10.3333 10.9207 11.0793 11.6667 12 11.6667C12.9207 11.6667 13.6667 10.9207 13.6667 10C13.6667 9.07934 12.9207 8.33334 12 8.33334C11.0793 8.33334 10.3333 9.07934 10.3333 10Z"
      fill="currentColor"
    />
  </svg>
);

export { MapItCircleIcon };
