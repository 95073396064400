import React, { FunctionComponent } from "react";
import "./OtpLoginModal.css";
import { OtpVerification } from "../OtpVerification/OtpVerification";
import { Modal, ModalContent, ModalTitle } from "src/components/Modal";
import { selectIsOtpRequested } from "src/store/auth/authSelectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoginOtpRequested } from "src/store/auth/authActionTypes";

const OtpLoginModal: FunctionComponent = () => {
  const isOtpRequested = useSelector(selectIsOtpRequested());
  const dispatch = useDispatch();
  return (
    <Modal
      open={isOtpRequested}
      width={442}
      onClose={() => {
        dispatch(setLoginOtpRequested(false));
      }}>
      <ModalTitle fontSize="large">Verify your login</ModalTitle>
      <ModalContent>
        <div id="login-page">
          <main>
            <div className="card">
              <OtpVerification />
            </div>
          </main>
        </div>
      </ModalContent>
    </Modal>
  );
};

export { OtpLoginModal };
