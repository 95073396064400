VisitWidget.ShareModal = class ShareModal {
  title: string;
  showShareToItineraryPlanner: () => void;
  shareUrl: string;
  socialText: string;
  onUserSelectionSubmit: (
    parameters: Record<any, any>,
    callback: () => void,
  ) => void;
  topDefaultMessage: string;
  topShareToItineraryPlannerMessage: string;
  socialShareControl: any;
  planQrCodeImgSrc: string;

  constructor(
    title,
    showShareToItineraryPlanner,
    shareUrl,
    socialText,
    onUserSelectionSubmit,
  ) {
    this.show = this.show.bind(this);
    this.registerEvents = this.registerEvents.bind(this);
    this.parseInputValues = this.parseInputValues.bind(this);
    this.reset = this.reset.bind(this);
    this.title = title;
    this.showShareToItineraryPlanner = showShareToItineraryPlanner;
    this.shareUrl = shareUrl;
    this.socialText = socialText;
    this.onUserSelectionSubmit = onUserSelectionSubmit;
    if (this.showShareToItineraryPlanner) {
      this.topDefaultMessage = VisitWidget.lib.translate(
        "plan.share_modal.itinerary_planner_top_message",
      );
    } else {
      this.topDefaultMessage = VisitWidget.lib.translate(
        "plan.share_modal.top_message",
      );
    }
    this.topShareToItineraryPlannerMessage = VisitWidget.lib.translate(
      "plan.share_modal.itinerary_planner.top_message",
    );
  }

  public initialize() {
    return this.initializeSocialShare(this.socialText);
  }

  public initializeSocialShare(socialText) {
    this.socialShareControl = new VisitWidget.SocialShareControl(
      ".modal.share",
      socialText,
      this.shareUrl,
      undefined,
      undefined,
      undefined,
      "plan",
    );
    return this.socialShareControl.initialize();
  }

  public show() {
    this.reset();
    this.planQrCodeImgSrc = `/plans/${VisitWidget.store.currentPlanId}/share.svg`;
    $("#plan-qr-code-id").attr("src", this.planQrCodeImgSrc);
    $(".modal.share h2").text(this.title);

    if (!VisitWidget.settings.isMobile) {
      ($(".modal.share .content") as any).slimScroll({ height: "294px" });
    }

    VisitWidget.modal.open($(".modal.share"), () => {
      $(".modal.share .top-message .message").html(this.topDefaultMessage);
      this.registerEvents();
    });
  }

  private registerEvents() {
    this.setupToggleShareToItineraryPlannerEvent();
    this.setupShareViaEmailSendEvent();
    this.setupShareViaSmsSendEvent();
    this.setupShareToItineraryPlannerEvent();
    this.disableFormSubmit();
  }

  private setupToggleShareToItineraryPlannerEvent() {
    $(".modal.share").off("click", ".toggle-share-to-itinerary-planner.enable");
    $(".modal.share").on(
      "click",
      ".toggle-share-to-itinerary-planner.enable",
      () => {
        $(".toggle-share-to-itinerary-planner.enable").hide();
        $(".toggle-share-to-itinerary-planner.disable").show();

        $(".modal.share .top-message .message").html(
          this.topShareToItineraryPlannerMessage,
        );
        $(".share_to_itinerary_planner_container_section").show();
        $(".modal.share .share-options").hide();
      },
    );

    $(".modal.share").off(
      "click",
      ".toggle-share-to-itinerary-planner.disable",
    );
    return $(".modal.share").on(
      "click",
      ".toggle-share-to-itinerary-planner.disable",
      (event) => {
        $(".toggle-share-to-itinerary-planner.disable").hide();
        $(".toggle-share-to-itinerary-planner.enable").show();

        $(".modal.share .top-message .message").html(this.topDefaultMessage);
        $(".share_to_itinerary_planner_container_section").hide();
        return $(".modal.share .share-options").show();
      },
    );
  }

  private setupShareViaEmailSendEvent() {
    $(".modal.share .email-entry").off("click", ".send");
    return $(".modal.share .email-entry").on("click", ".send", (event) => {
      if (this.emails().length > 0) {
        const $sendButton = $(".modal.share .email-entry .send");
        VisitWidget.Analytics.createSharePlanClick("email");
        return this.sendPlan(
          $sendButton,
          { emails: this.emails() },
          $(".modal.share #share_plan_email"),
        );
      }
    });
  }

  private setupShareViaSmsSendEvent() {
    $(".modal.share .phone-number-entry").off("click", ".send");
    return $(".modal.share .phone-number-entry form").on("submit", (event) => {
      event.preventDefault();
      if (this.phoneNumbers().length > 0) {
        const $sendButton = $(".modal.share .phone-number-entry .send");
        VisitWidget.Analytics.createSharePlanClick("sms");
        return this.sendPlan(
          $sendButton,
          { phone_numbers: this.phoneNumbers() },
          $(".modal.share input.phone-number"),
        );
      }
    });
  }

  private sendPlan($sendButton, parameters, $toInput) {
    $sendButton.addClass("sending");
    return this.onUserSelectionSubmit(parameters, () => {
      $toInput.val("");
      return window.setTimeout(() => {
        $sendButton.removeClass("sending").addClass("sent");
        return window.setTimeout(() => {
          return $sendButton.removeClass("sent");
        }, 1000);
      }, 1000);
    });
  }

  private setupShareToItineraryPlannerEvent() {
    $(".modal.share").off("click", "#share_to_itinerary_planner");
    return $(".modal.share").on("click", "#share_to_itinerary_planner", () => {
      const name = $("#share_to_itinerary_planner_container .name").val();
      const email = $(".email-for-itinerary-planner").val();

      if (name === "" || email === "") {
        return alert(
          VisitWidget.lib.translate(
            "plan.share_modal.itinerary_planner.name_and_email_required",
          ),
        );
      } else {
        const params = {
          plan_user_name: name,
          plan_user_email: email,
          plan_user_phone_number: $(
            ".phone-number-for-itinerary-planner",
          ).val(),
          comments: $(".comments-for-itinerary-planner").val(),
        };
        return VisitWidget.Plan.shareToItineraryPlanner(params, () => {
          return alert(
            VisitWidget.lib.translate(
              "plan.share_modal.itinerary_planner.success_message",
            ),
          );
        });
      }
    });
  }

  private disableFormSubmit() {
    return $(".modal.share form").submit(function (e) {
      e.preventDefault();
      return false;
    });
  }

  private emails() {
    return this.parseInputValues($(".modal.share  input.email").val());
  }

  private phoneNumbers() {
    return this.parseInputValues($(".modal.share  input.phone-number").val());
  }

  private parseInputValues(value) {
    if (value.length > 0) {
      return value.replace(",", ";").split(";");
    } else {
      return [];
    }
  }

  private reset() {
    $(".modal.share")
      .find(
        `#share_plan_email, #share_plan_phone_number, .comments-for-itinerary-planner`,
      )
      .val("");
    $(".toggle-share-to-itinerary-planner.disable").hide();
    $(".toggle-share-to-itinerary-planner.enable").show();

    $(".share_to_itinerary_planner_container_section").hide();
    return $(".modal.share .share-options").show();
  }
};
