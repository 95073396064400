import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectFlyoutSharedPlanId } from "../../../store/flyout/flyoutSelectors";
import { TourListItemDto } from "../../../store/apiTypes";
import { SharedPlan } from "../SharedPlan/SharedPlan";
import "./SharedPlanList.scss";
import { clientTheme } from "../../../components/ConfigProvider";

/**
 * List of shared plans, only applicable to admins and rendered as part of the Shared Plan
 * menu item.
 */
const SharedPlanList: FunctionComponent<SharedPlanListProps> = ({
  sharedPlans,
}) => {
  const selectedSharedPlan = useSelector(selectFlyoutSharedPlanId());
  return (
    <div className="shared-plan-list-container">
      {sharedPlans.map((sharedPlan) => (
        <div
          key={sharedPlan.id}
          style={{
            border:
              selectedSharedPlan === sharedPlan.id
                ? `1px solid ${clientTheme.colorPrimary}`
                : "",
          }}>
          <SharedPlan sharedPlan={sharedPlan} />
        </div>
      ))}
      {sharedPlans.length === 0 && (
        <div className="shared-plan-list-no-items-container">
          <div className="shared-plan-list-no-items-title">
            No shared plans yet
          </div>
          <div className="shared-plan-list-no-items-description">
            To create a shared plan, click the create button.
          </div>
        </div>
      )}
    </div>
  );
};

interface SharedPlanListProps {
  sharedPlans: TourListItemDto[];
}

export { SharedPlanList, SharedPlanListProps };
