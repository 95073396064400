import React, { FunctionComponent, ReactNode } from "react";
import { Button as AntButton } from "antd";
import "./Button.scss";
import { ConfigProvider } from "../ConfigProvider";

interface MappingProps {
  shape?: "default" | "circle" | "round";
  size?: "large" | "middle" | "small";
  color?: string;
  type?: "link" | "text" | "default" | "primary" | "dashed";
  danger?: boolean;
}

interface Mapping {
  pillOutline: MappingProps;
  pillOutlineMiddle: MappingProps;
  pillMiddle: MappingProps;
  icon: MappingProps;
  iconWhite: MappingProps;
  iconRound: MappingProps;
  outline: MappingProps;
  primary: MappingProps;
  link: MappingProps;
  text: MappingProps;
  tab: MappingProps;
  tabOutline: MappingProps;
  action: MappingProps;
  linkSmall: MappingProps;
  graySquare: MappingProps;
  danger: MappingProps;
}

const mappings: Mapping = {
  pillOutline: {
    shape: "round",
    size: "small",
    type: "primary",
  },
  pillMiddle: {
    shape: "round",
    size: "middle",
    type: "primary",
  },
  pillOutlineMiddle: {
    shape: "round",
    size: "middle",
    type: "default",
  },
  icon: {
    shape: "circle",
    size: "small",
    type: "default",
  },
  iconWhite: {
    shape: "circle",
    size: "small",
    type: "default",
  },
  iconRound: {
    shape: "circle",
    type: "text",
  },
  outline: {
    shape: "default",
    size: "middle",
  },
  primary: {
    shape: "default",
    size: "middle",
    type: "primary",
  },
  link: {
    type: "link",
    shape: "default",
    size: "middle",
  },
  linkSmall: {
    type: "link",
    shape: "default",
    size: "middle",
  },
  text: {
    type: "text",
  },
  tab: {
    type: "primary",
    shape: "round",
  },
  tabOutline: {
    shape: "round",
  },
  action: {
    shape: "default",
    size: "middle",
  },
  graySquare: {
    shape: "default",
    type: "default",
    size: "middle",
  },
  danger: {
    danger: true,
  },
};

const Button: FunctionComponent<ButtonProps> = ({
  children,
  testID,
  disabled = false,
  onPress,
  href,
  value,
  loading = false,
  icon,
  type,
  target,
  iconOverlay,
  style = {},
}) => {
  return (
    <ConfigProvider
      type="client"
      primaryOverride={type === "iconWhite" ? "#FFFFFF" : undefined}>
      <AntButton
        disabled={disabled}
        onClick={onPress}
        href={href}
        target={target}
        style={style}
        icon={
          iconOverlay ? (
            <div className="button-icon-overlay">
              {icon}
              <span className="button-icon-overlay-text">{iconOverlay}</span>
            </div>
          ) : (
            icon
          )
        }
        loading={loading}
        value={value}
        data-testid={testID}
        className={`button-${type}`}
        ghost={type === "pillOutline"}
        {...mappings[type]}>
        {children}
      </AntButton>
    </ConfigProvider>
  );
};

interface ButtonProps {
  /**
   * Disable clicking the button
   */
  disabled?: boolean;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
  /**
   * The button text
   */
  children?: string | React.ReactNode;
  /**
   * Callback when button is pressed
   */
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   * Location to open when button is pressed
   */
  href?: string;
  /**
   * Target window to open href in.
   */
  target?: string;
  /**
   * Value of button
   */
  value?: string;
  /**
   * Whether the loading state of the button should show
   */
  loading?: boolean;
  /**
   * Icon of the button
   */
  icon?: ReactNode;
  /**
   * Dynamic text centered on top of icon.
   */
  iconOverlay?: string | number;
  /**
   * The style of Visit Widget preset style of button.
   *
   * primary: Use client primary color for background of button.
   * outline: Only a gray outline, and outline will use client primary color on hover.
   * pillOutline: Small pill button with white background, uses client primary color for outline.
   * icon: Icon only, will use current color for color of icon, hover uses client primary color.
   * text: A text button, on hover will show button background of gray.
   * link: Link similar to an html anchor tag.
   */
  type: keyof Mapping;
  style?: object;
}

export { Button, ButtonProps };
