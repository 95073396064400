import React, { FunctionComponent, useCallback, useState } from "react";
import { TextInput } from "../../TextInput";
import { Button } from "../../Button";
import { Modal, ModalAction, ModalContent, ModalTitle } from "../../Modal";
import "./SharePlanModal.scss";
import { Form } from "../../form/Form";
import copy from "copy-to-clipboard";
import { shareSharedPlan } from "../../../store/visitWidgetApi";

const SharePlanModal: FunctionComponent<SharePlanModalProps> = ({
  tourId,
  coverPhotoUrl,
  open,
  onClose,
  title,
  shareUrl,
}) => {
  const [form] = Form.useForm();
  const [state, setState] = useState<SharePlanModalState>({ status: "idle" });

  const handleClose = useCallback(() => {
    form.resetFields();
    setState({ status: "idle" });
    onClose();
  }, [form, setState, onClose]);

  const handleSubmit = useCallback(
    async (values: { emailAddress: string }) => {
      const emailAddresses = values.emailAddress
        .split(",")
        .map((x) => x.trim());

      setState({ status: "fetching" });
      try {
        await shareSharedPlan(tourId, emailAddresses);
        handleClose();
      } catch (e) {
        setState({ status: "error" });
      }
    },
    [tourId, handleClose],
  );

  const handleCopyShareUrl = () => {
    copy(shareUrl);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} width={560}>
        <ModalTitle fontSize="large">Share Plan</ModalTitle>
        <ModalContent>
          <div className="share-plan-modal-container">
            <div className="share-plan-modal-header">
              <div
                className="share-plan-modal-cover-photo"
                style={{ backgroundImage: `url(${coverPhotoUrl})` }}></div>
              <div className="share-plan-modal-details">
                <div className="share-plan-modal-title">{title}</div>
              </div>
            </div>
            <div>
              <Form
                form={form}
                name="register"
                onFinish={handleSubmit}
                initialValues={{ emailAddress: "" }}>
                <Form.Item
                  extra="Enter multiple emails separated by commas."
                  rules={[
                    {
                      required: true,
                      message: "Please add email addresses(s).",
                    },
                  ]}
                  name="emailAddress">
                  <TextInput
                    size="large"
                    name="emailAddress"
                    form={form}
                    disabled={state.status === "fetching"}
                  />
                </Form.Item>
              </Form>
              {state.status === "error" && (
                <div className="share-plan-modal-error">
                  Error sharing plan.
                </div>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalAction position="left">
          <Button
            type="outline"
            onPress={handleCopyShareUrl}
            disabled={state.status === "fetching"}>
            Copy Link
          </Button>
        </ModalAction>
        <ModalAction position="right">
          <Button
            type="primary"
            disabled={state.status === "fetching"}
            onPress={() => {
              form.submit();
            }}>
            Share
          </Button>
        </ModalAction>
      </Modal>
    </>
  );
};

interface SharePlanModalProps {
  tourId: number;
  open: boolean;
  onClose: () => void;
  coverPhotoUrl: string;
  title: string;
  shareUrl: string;
}

interface SharePlanModalState {
  status: "idle" | "fetching" | "error";
}

export { SharePlanModal, SharePlanModalProps };
