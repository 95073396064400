import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "../../Button";
import "./SharedPlan.scss";
import { ShareIcon, MapItCircleIcon } from "../../icons";
import { SharePlanModal } from "../SharePlanModal";
import { SharedPlanScreen, SharedPlanScreenProps } from "../SharedPlanScreen";
import { Plan } from "../../../lib/apiTypes";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { useDispatch } from "react-redux";
import { setFlyoutAction } from "../../../store/flyout/actions/setFlyoutAction";
import { TourListItemDto } from "../../../store/apiTypes";
import { adaptTourDtoToPlan, getTour } from "../../../store/visitWidgetApi";
import { AppDispatch } from "../../../store/store";

/**
 * An individual Shared Plan list item that is part of the Shared Plans menu item.
 */
const SharedPlanBase: FunctionComponent<SharedPlanProps> = ({ sharedPlan }) => {
  const [isSharePlanOpen, setIsSharePlanOpen] = useState(false);
  const [plan, setPlan] = useState<Plan>(null);
  const [mapItControl, setMapItControl] = useState(null);

  useEffect(() => {
    (async () => {
      const tour = await getTour(sharedPlan.id);
      const mappedSharedPlan = adaptTourDtoToPlan(tour);
      setPlan(mappedSharedPlan);
    })();
  }, [sharedPlan]);

  const dispatch = useDispatch<AppDispatch>();

  const handleClose = () => {
    setIsSharePlanOpen(false);
  };

  const handleSelectPlan = () => {
    // tell the flyout component to open with PlanScreen as its child component.
    dispatch(
      setFlyoutAction(SharedPlanScreen, {
        tourId: sharedPlan.id,
      } as SharedPlanScreenProps),
    );
  };

  const handleMapIt = async (event: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    if (mapItControl) {
      mapItControl.showMapItLocation();
    }
  };

  useEffect(() => {
    const mapItControlLegacy = new VisitWidget.TourMapItControl(
      undefined,
      sharedPlan.id,
      { routingEnabled: true },
    );
    const markerIds = JSON.parse(sharedPlan.marker_ids);
    mapItControlLegacy.initialize(markerIds);
    setMapItControl(mapItControlLegacy);
  }, [sharedPlan.id, sharedPlan.marker_ids]);

  const coverPhotoUrl =
    plan?.coverPhotoUrl ?? "/assets/default_cover_photo.png";

  return (
    <>
      <div
        className="shared-plan-container"
        onClick={handleSelectPlan}
        onMouseEnter={handleMapIt}>
        <div
          className="shared-plan-cover-photo"
          style={{
            backgroundImage: `url(${coverPhotoUrl})`,
          }}></div>

        <div className="shared-plan-details">
          <div className="shared-plan-title">{sharedPlan.name}</div>
          <div className="shared-plan-stops-number">
            {sharedPlan.plan_item_count} stops
          </div>
        </div>

        <div className="shared-plan-actions">
          {!!sharedPlan.access_token && (
            <div>
              <Button
                type="icon"
                icon={<ShareIcon />}
                onPress={(event) => {
                  event.stopPropagation();
                  setIsSharePlanOpen(true);
                }}
              />
            </div>
          )}
          {sharedPlan.plan_item_count > 0 && (
            <div>
              <Button
                type="icon"
                icon={<MapItCircleIcon />}
                onPress={(event) => {
                  handleMapIt(event);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <SharePlanModal
        tourId={sharedPlan.id}
        coverPhotoUrl={coverPhotoUrl}
        onClose={handleClose}
        open={isSharePlanOpen}
        title={sharedPlan.name}
        // eslint-disable-next-line max-len
        shareUrl={`${window.location.origin}/tours/${sharedPlan.id}?access_token=${sharedPlan.access_token}`}
      />
    </>
  );
};

interface SharedPlanProps {
  /**
   * The Tour data for the Shared Plan.
   */
  sharedPlan: TourListItemDto;
}

const SharedPlan = withReduxProvider(SharedPlanBase);
export { SharedPlan, SharedPlanProps };
