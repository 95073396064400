/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */

// eslint-disable-next-line no-shadow
export enum FlyoutActionTypes {
  SET_FLYOUT = "SET_FLYOUT",
}

export interface SetFlyoutAction {
  type: FlyoutActionTypes.SET_FLYOUT;
  payload: {
    component: any;
    props: any;
  };
}

export const setFlyout = (component: any, props: any): SetFlyoutAction => {
  return {
    type: FlyoutActionTypes.SET_FLYOUT,
    payload: {
      component: component,
      props: props,
    },
  };
};
