import { Notifier } from "@airbrake/browser";

if (
  VisitWidget.settings.airbrakeProjectId &&
  VisitWidget.settings.airbrakeKey
) {
  const airbrake = new Notifier({
    projectId: VisitWidget.settings.airbrakeProjectId,
    projectKey: VisitWidget.settings.airbrakeKey,
  });

  airbrake.addFilter((notice) => {
    notice.context.environment = "browser";
    return notice;
  });
}
