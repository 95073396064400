import { UseCallBackType } from "../../../../../lib/types";
import { useCallback } from "react";

const useChangeCallback = (
  onCategoryIdChange: Function,
  selectedOption: Record<string, unknown>,
): UseCallBackType => {
  return useCallback(
    (option) => {
      const categoryId = option.value;

      if (selectedOption.value === categoryId) {
        return;
      }

      const changedCategoryIds = [categoryId];
      if (selectedOption.value) {
        changedCategoryIds.push(selectedOption.value);
      }

      if (onCategoryIdChange) {
        onCategoryIdChange(categoryId);
      }
      const event = new VisitWidget.Events.CategoriesChanged(
        changedCategoryIds,
      );
      VisitWidget.EventBus.publish(event);
    },
    [onCategoryIdChange, selectedOption],
  );
};
export default useChangeCallback;
