import { Plan } from "../../lib/apiTypes";
import { TourListItemDto, UserPlanAggregate } from "../apiTypes";
import { SetPlanAction } from "./planActionTypes";

export interface planState {
  plan?: UserPlanAggregate;
  sharedPlans?: TourListItemDto[];
  sharedPlansCategoryIds?: number[];
  sharedPlansCurrentPage?: number;
  sharedPlansEnd?: boolean;
  sharedPlansLastPage?: number;
  sharedPlan?: Plan;
  isSharedPlanEdit?: boolean;
}

const initialState = (): planState => {
  return {
    plan: undefined,
    sharedPlans: undefined,
    isSharedPlanEdit: false,
  };
};

type planActions = SetPlanAction;

const planReducer = (state = initialState(), action: planActions) => {
  if (JSON.stringify(state) === "{}") {
    // ReactOnRails is a hack, and on bootstrap it will randomly lose initial
    // state, so reset it until it stick
    state = initialState();
  }

  const { payload } = action;

  return {
    ...state,
    ...payload,
  };
};

export { planReducer };
