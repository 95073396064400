import React, { FunctionComponent } from "react";
import { Dropdown } from "antd";
import "./styles.scss";
import { ConfigProvider } from "../ConfigProvider";

const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  children,
  menuItems,
  testID,
}) => {
  return (
    <ConfigProvider>
      <Dropdown
        trigger={["click"]}
        menu={{ items: menuItems }}
        data-testid={testID}>
        <a>{children ?? "Menu"}</a>
      </Dropdown>
    </ConfigProvider>
  );
};

export interface DropdownMenuProps {
  /**
   * Menu items
   */
  menuItems?: DropdownMenuItem[];
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
}

export { DropdownMenu };

interface DropdownMenuItem {
  label?: string;
  value?: string;
  key: string;
}
