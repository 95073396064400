/* eslint-disable */
import { RoutesResponse } from "../../domain/navigation/queries/getDirectionsForGeoCoordinates";

export enum MapActionTypes {
  SET_ROUTES_CACHE = "SET_ROUTES_CACHE",
  SET_MAP_READY = "SET_MAP_READY",
}

export interface SetRoutesCacheAction {
  type: MapActionTypes.SET_ROUTES_CACHE;
  payload: {
    routesCache: Record<string, RoutesResponse>;
  };
}

export interface SetMapReadyAction {
  type: MapActionTypes.SET_MAP_READY;
  payload: {
    isMapReady: boolean;
  };
}

export const setRoutesCache = (
  routesCache: Record<string, RoutesResponse>,
): SetRoutesCacheAction => {
  return {
    type: MapActionTypes.SET_ROUTES_CACHE,
    payload: {
      routesCache: routesCache,
    },
  };
};

export const setMapReady = (): SetMapReadyAction => {
  return { type: MapActionTypes.SET_MAP_READY, payload: { isMapReady: true } };
};
