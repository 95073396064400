import { setPlan } from "../../plan/planActionTypes";
import { CookieKeys, getCookie, setCookie } from "../../../lib/cookies";
import { PlanItemDto } from "../../../store/apiTypes";
import { listPlanItems } from "../../../store/visitWidgetApi";
import { getInitialPlanData } from "./getInitialPlanData";
import { setLegacyState } from "./setLegacyState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializeAnonymousUser = async (dispatch: any) => {
  const planData = await getInitialPlanData();

  const planTourIds = VisitWidget.store.planTourIds;

  const categoryIds = await getCookie(CookieKeys.categoryIds, undefined);
  if (categoryIds === undefined) {
    await setCookie(
      CookieKeys.categoryIds,
      JSON.stringify(VisitWidget.store.categoryIds),
    );
  }

  let planItems = [] as Array<PlanItemDto>;
  if (planData.planId) {
    planItems = await listPlanItems(planData.planId);
  }
  setLegacyState({
    planId: planData.planId,
    planItems,
    planTourIds,
  });
  dispatch(setPlan({ id: planData.planId, plan_items: planItems }));
};

export { initializeAnonymousUser };
