const formatTime = (timespanMinutes: number) => {
  const timespanMinutesRounded = Math.ceil(timespanMinutes);
  if (timespanMinutesRounded < 60) {
    return `${timespanMinutesRounded} min`;
  }
  const hours = Math.floor(timespanMinutesRounded / 60);
  const minutes = timespanMinutesRounded - hours * 60;
  return `${hours} hr${minutes > 0 ? ` ${minutes} min` : ""}`;
};

export { formatTime };
