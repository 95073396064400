import React, { useCallback } from "react";
import "./styles.scss";
import translate from "../../../lib/queries/translate";
import resetKiosk from "../../../domain/kiosk/commands/resetKiosk";

const KioskResetBanner = () => {
  const onResetPress = useCallback(() => {
    resetKiosk();
  }, []);

  return (
    <div className="KioskResetBanner">
      <div className="KioskResetBanner-container">
        <div>{translate("kiosk.reset_banner.description")}</div>
        <button
          className="KioskResetBanner-resetButton client-background-color"
          onClick={onResetPress}>
          {translate("kiosk.reset_banner.button")}
        </button>
      </div>
    </div>
  );
};

export default KioskResetBanner;
