import { MarkerPicture } from "../../lib/types";
import getSvgDataUrl from "./getSvgDataUrl";

export default function getMultipleMarkerPicture(
  markerColor: string,
  numberOfEntities: number,
): MarkerPicture {
  const content = `<text text-anchor="middle" x="26" y="33" fill="#FFFFFF" 
    font-family="'open sans', helvetica, arial, sans-serif" 
    font-size="24">${numberOfEntities}</text>`;

  const svg = `<svg width="52px" height="52px" viewBox="0 0 52 52" 
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="23" stroke="white" stroke-width="3" 
    fill="${markerColor}"/>${content}</svg>`;

  return {
    url: getSvgDataUrl(svg),
    width: 26,
    height: 26,
  };
}
