import { Coordinates } from "../../lib/types";
import getQueryStringVariable from "./getQueryStringVariable";

function getQueryStringCoordinates(): Coordinates {
  const latitude = parseFloat(
    getQueryStringVariable("currentLocationLatitude") as string,
  );
  const longitude = parseFloat(
    getQueryStringVariable("currentLocationLongitude") as string,
  );
  return latitude && longitude ? { latitude, longitude } : null;
}

function getCurrentLocationFromBrowser(
  onLocationRetrieved: Function,
  onLocationRetrievalError: (positionError: GeolocationPositionError) => void,
) {
  navigator.geolocation.getCurrentPosition(
    (geolocationPosition) =>
      onLocationRetrieved({
        latitude: geolocationPosition.coords.latitude,
        longitude: geolocationPosition.coords.longitude,
      }),
    onLocationRetrievalError,
    { timeout: 10000 },
  );
}

export default function getCurrentLocation(
  onLoading: Function,
  onLocationRetrieved: (coordinates: Coordinates) => void,
  onLocationRetrievalError: (error: GeolocationPositionError) => void,
): void {
  const coordinates = getQueryStringCoordinates();
  if (coordinates) {
    onLoading();
    onLocationRetrieved(coordinates);
  } else if (navigator.geolocation) {
    onLoading();
    getCurrentLocationFromBrowser(
      onLocationRetrieved,
      onLocationRetrievalError,
    );
  } else {
    alert("Geolocation is not supported by this browser.");
  }
}
