/* eslint-disable */
import { RootReducerState } from "../../rootReducer";
import { listSharedPlans } from "../../visitWidgetApi";
import { setSharedPlans } from "../planActionTypes";

const isCategoryIdsChanged = (
  previousCategoryIds: number[] = [],
  newCategoryIds: number[] = [],
) => {
  if (previousCategoryIds.length !== newCategoryIds.length) {
    return true;
  }
  previousCategoryIds = previousCategoryIds.sort();
  newCategoryIds = newCategoryIds.sort();
  for (let i = 0; i < newCategoryIds.length; ++i) {
    if (previousCategoryIds[i] !== newCategoryIds[i]) {
      return true;
    }
  }
  return false;
};

const listToursAction =
  (categoryIds: number[], forceRefresh = false) =>
  async (dispatch, getState: () => RootReducerState) => {
    const state = getState().plan;
    const isResetPaging =
      forceRefresh ||
      isCategoryIdsChanged(state.sharedPlansCategoryIds, categoryIds);

    if (!isResetPaging && state.sharedPlansEnd) {
      return;
    }

    const newPage = (isResetPaging ? 0 : state.sharedPlansCurrentPage ?? 0) + 1;
    const tours = await listSharedPlans(categoryIds, newPage);
    // the api doesn't currently display the total number of items, we know when nothing is returned
    const isEndOfPages = tours.length === 0;
    const newTours = isResetPaging ? tours : [...state.sharedPlans, ...tours];

    dispatch(setSharedPlans(newTours, isEndOfPages, categoryIds, newPage));
  };

export { listToursAction };
