import { MarkerPicture } from "../../lib/types";
import getSvgDataUrl from "./getSvgDataUrl";

export default function getSmallMarkerPicture(
  markerColor: string,
): MarkerPicture {
  const svg = `<svg width="26px" height="26px" viewBox="0 0 26 26"
     xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="11" stroke="white" stroke-width="6" 
    fill="${markerColor}"/></svg>`;

  return {
    url: getSvgDataUrl(svg),
    height: 13,
    width: 13,
  };
}
