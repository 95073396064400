import { RootReducerState } from "../rootReducer";

export const selectUser = () => (x: RootReducerState) => {
  return x.auth.user;
};

export const selectAccessToken = () => (x: RootReducerState) => x.auth.token;

export const selectIsAdminMode = () => (x: RootReducerState) =>
  x.auth.isAdminMode;

export const selectIsOtpRequested = () => (x: RootReducerState) =>
  x.auth.isOtpRequested;
