export default function translate(
  key: string,
  variables?: { [key: string]: string },
): string | undefined {
  const translation = VisitWidget.store.translations.find((t) => t.key === key);

  if (translation) {
    return translation.value;
  }

  let text = I18n.lookup(key);
  if (text) {
    if (!!variables) {
      const variableKeys = Object.keys(variables);
      variableKeys.forEach((variableKey) => {
        text = text.replace(`%{${variableKey}}`, variables[variableKey]);
      });
    }
    return text;
  } else {
    // This will cause acceptance tests to fail without affecting users
    // eslint-disable-next-line no-console
    console.error(`No translation found for ${key}`);
  }
}
