import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction } from "../../../store/auth/actions/resetPasswordAction";
import { ReCaptcha } from "../../../components/reCaptcha";
import { RootReducerState } from "../../../store/rootReducer";
import translate from "../../../lib/queries/translate";
import { withReduxProvider } from "../../../store/withReduxProvider";

// eslint-disable-next-line no-shadow
const enum ResetPasswordTestIds {
  submitInput = "submit-input",
  emailInput = "email-input",
  backButton = "back-button",
}

const ResetPasswordBase: FunctionComponent = () => {
  const emailInput = useRef<HTMLInputElement | null>(null);
  const captchaRef = useRef<{
    reset: () => void;
    getValue: () => string | undefined;
  } | null>(null);
  const dispatch = useDispatch();

  const resetPasswordStatus = useSelector(
    (x: RootReducerState) => x.auth.resetPasswordStatus,
  );

  const handleBack = useCallback((event) => {
    event.preventDefault();
    VisitWidget.registrationModal.showSignInView();
  }, []);

  useEffect(() => {
    if (resetPasswordStatus === "error") {
      // must reset the captcha after an error
      captchaRef.current.reset();
    }
  }, [resetPasswordStatus, captchaRef]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const captchaValue = captchaRef.current.getValue();
      dispatch(
        resetPasswordAction({
          email: emailInput.current.value,
          captcha: captchaValue,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      );
    },
    [dispatch, emailInput],
  );

  return (
    <>
      {resetPasswordStatus !== "success" && (
        <>
          <p>{translate("authentication.forgot_password.instructions")}</p>
          <form>
            <div className="form-inputs">
              <label htmlFor="user_email">Email</label>
              <input
                id="user_email"
                className="email required"
                data-testid={ResetPasswordTestIds.emailInput}
                type="text"
                name="user_email"
                ref={emailInput}
                tabIndex={0}
              />
            </div>

            <ReCaptcha ref={captchaRef} />

            <div className="form-actions">
              <input
                type="submit"
                name="commit"
                data-testid={ResetPasswordTestIds.submitInput}
                value={translate(
                  "authentication.forgot_password.submit_button",
                )}
                className="btn"
                tabIndex={0}
                disabled={resetPasswordStatus === "fetch"}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </>
      )}
      {resetPasswordStatus === "success" && (
        <>
          <p>{translate("authentication.forgot_password.success_message")}</p>
        </>
      )}

      <a
        onClick={handleBack}
        className="open-sign-in all-caps"
        data-testid={ResetPasswordTestIds.backButton}>
        {translate("authentication.back_link")}
      </a>
    </>
  );
};

const ResetPassword = withReduxProvider(ResetPasswordBase);
export { ResetPassword, ResetPasswordTestIds };
