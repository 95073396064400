import React from "react";

/**
 * For dev use only, allow testing any React from React on Rails
 */
const TestContainer = () => {
  return <></>;
};

export { TestContainer };
