import React, { FunctionComponent } from "react";
import { ConfigProvider as AntConfigProvider } from "antd";
import de_DE from "antd/locale/de_DE";
import en_GB from "antd/locale/en_GB";
import en_US from "antd/locale/en_US";
import es_ES from "antd/locale/es_ES";
import fr_CA from "antd/locale/fr_CA";
import fr_FR from "antd/locale/fr_FR";
import it_IT from "antd/locale/it_IT";
import nl_NL from "antd/locale/nl_NL";
import pt_BR from "antd/locale/pt_BR";
import zh_CN from "antd/locale/zh_CN";

const clientTheme = {
  colorPrimary: VisitWidget.settings.currentClientPrimaryColor,
  colorPrimaryActive: VisitWidget.settings.currentClientPrimaryColor,
  colorPrimaryHover: VisitWidget.settings.currentClientPrimaryColor,
  colorLink: VisitWidget.settings.currentClientPrimaryColor,
};

const locales = {
  de: de_DE,
  "de-DE": de_DE,
  en: en_US,
  "en-GB": en_GB,
  "en-US": en_US,
  es: es_ES,
  "es-ES": es_ES,
  "es-US": es_ES,
  "es-419": es_ES,
  fr: fr_FR,
  "fr-CA": fr_CA,
  "fr-FR": fr_FR,
  it: it_IT,
  "it-IT": it_IT,
  nl: nl_NL,
  "nl-NL": nl_NL,
  pt: pt_BR,
  "pt-BR": pt_BR,
  cn: zh_CN,
  zh: zh_CN,
  "zh-CN": zh_CN,
};

const theme = {
  borderRadius: 4,
  colorBgContainerDisabled: "#F0F1F2",
  colorBgTextHover: "#f0f1f2",
  colorBorder: "#AEB1B5",
  colorEnabled: "#4bb848",
  colorError: "#C81414",
  colorPrimaryHover: "#3A3D40",
  colorTextDisabled: "#AEB1B5",
  colorTextPlaceholder: "#83868a",
  fontFamily: "Open Sans",
  fontSize: 16,
  colorBlack: "#000",
  colorWhite: "#fff",
  colorGrayBase: "#83868a",
  colorGray60: "#606366",
  colorGray80: "#3a3d40",
};

const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
  children,
  type = "regular",
}) => {
  return (
    <AntConfigProvider
      locale={locales[I18n.locale]}
      theme={{
        token: type === "client" ? clientTheme : theme,
      }}>
      {children}
    </AntConfigProvider>
  );
};

export interface ConfigProviderProps {
  children: React.ReactNode;
  type?: "client" | "regular";
}

export { ConfigProvider, theme, clientTheme };
