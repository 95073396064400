type ShareType =
  | string
  | {
      share: string;
      label: string;
    };

VisitWidget.SocialShareControl = class SocialShareControl {
  containerSelector: string;
  text: string | undefined;
  url: string;
  entityId: number | undefined;
  entityType: string | undefined;
  entityName: string | undefined;
  shareType: "plan" | "entity";

  constructor(
    containerSelector: string,
    text: string,
    url: string,
    entityId: number | undefined,
    entityType: string | undefined,
    entityName: string | undefined,
    shareType: "plan" | "entity",
  ) {
    this.containerSelector = containerSelector;
    this.text = text;
    if (url == null) {
      url = window.location.href;
    }

    this.entityId = entityId;
    this.entityType = entityType;
    this.entityName = entityName;
    this.shareType = shareType;
    this.url = url;
  }

  initialize(): void {
    jsSocials.shares.email = {
      label: "Email",
      logo: "fa fa-envelope-o",
      shareUrl: "mailto:{to}?subject={text}&body={url}",
      shareIn: "blank",
    };

    jsSocials.shares.sms = {
      label: "Text",
      logo: "fa fa-mobile",
      shareUrl: "sms:&body={text}%20{url}",
      shareIn: "self",
    };

    const shares = [
      "email",
      "twitter",
      { share: "facebook", label: "Post" },
    ] as ShareType[];

    if (VisitWidget.settings.isMobile) {
      shares.push("sms");
    }

    const shareIn = VisitWidget.settings.isMobile ? "self" : "popup";

    $(`${this.containerSelector} .share-on-social`).jsSocials({
      shares,
      showLabel: VisitWidget.settings.showSocialShareLabels,
      showCount: false,
      url: this.url,
      text: this.text,
      shareIn,
      on: {
        click: (e) => {
          if (this.shareType === "entity") {
            VisitWidget.Analytics.createShareClick(
              this.entityId,
              this.entityType,
              this.entityName,
            );
          } else if (this.shareType === "plan") {
            const className = $(e.target)
              .closest(".jssocials-share")
              .attr("class");
            let shareType = undefined;
            if (className.indexOf("twitter") > -1) {
              shareType = "twitter";
            } else if (className.indexOf("email") > -1) {
              shareType = "email";
            } else if (className.indexOf("facebook") > -1) {
              shareType = "facebook";
            }
            VisitWidget.Analytics.createSharePlanClick(shareType);
          }
        },
      },
    });
    this.setupAdaCompliance(shares);
  }

  private setupAdaCompliance(shares: ShareType[]): void {
    for (let share of shares) {
      const platform = typeof share === "string" ? share : share.share;
      $(`${this.containerSelector} .jssocials-share-${platform} a`).attr(
        "aria-label",
        `Share on ${platform}`,
      );
    }
  }
};
