/* eslint-disable */
import React, { FunctionComponent } from "react";
import { Form } from "src/components/form/Form";
import { FormInstance, FormListFieldData } from "antd";
import { Checkbox } from "src/components/Checkbox";
import { Radio, RadioOption } from "src/components/Radio";
import { TextInput } from "src/components/TextInput";
import { TrashIcon } from "src/components/icons";
import translate from "src/lib/queries/translate";
import "../ChallengeSubmissionConfig.scss";
import "./CustomInput.scss";

const inputTypes: Array<RadioOption> = [
  { value: "textinput", label: "Text input" },
  { value: "dropdown", label: "Dropdown" },
];
// eslint-disable-next-line complexity
const CustomInput: FunctionComponent<CustomInputProps> = ({
  formListFieldData,
  mode,
  form,
  onRemove,
}) => {
  const getItemNameForField = (fieldName: string): Array<number | string> => {
    return [formListFieldData.name, fieldName];
  };

  const getFieldNameForField = (fieldName: string): Array<number | string> => {
    return new Array("customInputs", ...getItemNameForField(fieldName));
  };

  const fieldType = Form.useWatch(getFieldNameForField("input_type"), form);
  const isDisabled = mode !== "edit";

  const removeInput = () => {
    if (!isDisabled) {
      onRemove(formListFieldData.name);
    }
  };

  return (
    <>
      <div className="sort-item-container">
        <div className="custom-input-form-row">
          <div className="custom-input-form-row-column">
            <Form.Item
              name={[formListFieldData.name, "label"]}
              label="Field Label"
              rules={[
                {
                  required: true,
                  message: translate(
                    "challenge_submission_config.hints.field_label",
                  ),
                },
              ]}>
              <TextInput
                name={[formListFieldData.name, "label"]}
                size="large"
                form={form}
                placeholder={translate(
                  "challenge_submission_config.placeholders.field_label",
                )}
                disabled={isDisabled}
              />
            </Form.Item>
          </div>
        </div>

        <div className="custom-input-form-row">
          <div className="custom-input-form-row-column">
            <Form.Item
              name={getItemNameForField("input_type")}
              label={translate("challenge_submission_config.input_type")}>
              <Radio
                name={[formListFieldData.name, "input_type"]}
                form={form}
                direction={"vertical"}
                options={inputTypes}
                disabled={isDisabled}
              />
            </Form.Item>
          </div>
          <div className="custom-input-form-row-column align-items-flex-end">
            <Form.Item
              name={getItemNameForField("required")}
              valuePropName="checked">
              <Checkbox
                disabled={isDisabled}
                name={[formListFieldData.name, "required"]}
                form={form}>
                {translate("challenge_submission_config.field_required")}
              </Checkbox>
            </Form.Item>
          </div>
        </div>

        {fieldType === "dropdown" && (
          <div className="custom-input-form-row">
            <Form.Item
              name={getItemNameForField("values")}
              label={translate(
                "challenge_submission_config.dropdown_input_values",
              )}>
              <TextInput
                name={[formListFieldData.name, "values"]}
                size="large"
                form={form}
                placeholder={translate(
                  "challenge_submission_config.placeholders.dropdown_input_values",
                )}
                hint={translate(
                  "challenge_submission_config.hints.dropdown_input_values",
                )}
                disabled={isDisabled}
              />
            </Form.Item>
          </div>
        )}
      </div>
      <div className="custom-input-item-trash">
        <a onClick={removeInput}>
          <TrashIcon size={30} color={"#aeb1b5"} />
        </a>
      </div>
    </>
  );
};

interface CustomInputProps {
  formListFieldData: FormListFieldData;
  mode?: "summary" | "view" | "edit";
  form: FormInstance<any>;
  onRemove: (index: number) => void;
}

export { CustomInput, CustomInputProps };
