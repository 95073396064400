/* eslint-disable max-len */

VisitWidget.GoalListController = class GoalListController {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  menuItem: any;
  sidebarSelector: string;
  containerSelector: string;
  $container: JQuery<HTMLElement>;
  goalListItemsControl: any;
  getGoalListItemHtml: any;
  listItemTemplate: any;
  listDragAndDropControl: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  constructor(menuItem) {
    this.menuItem = menuItem;

    this.sidebarSelector = `#sidebar_${this.menuItem.entity}${this.menuItem.id}`;
    this.containerSelector = `${this.sidebarSelector} .goal-list-items`;
  }

  initialize() {
    this.$container = $(this.containerSelector);

    if (VisitWidget.settings.adminModeOn) {
      this.listDragAndDropControl = new VisitWidget.ListDragAndDropControl(
        this.containerSelector,
        this.getGoalId,
        this.onDragAndDrop,
      );
    }
    this.goalListItemsControl = new VisitWidget.GoalListItemsControl(
      this.containerSelector,
      { panMapOnHover: VisitWidget.settings.listHoverMapPanEnabled },
    );

    this.getGoalListItemHtml = new VisitWidget.GetGoalListItemHtml();

    const templateName = "#goal_list_item_template";
    this.listItemTemplate = Handlebars.compile($(templateName).html());

    this.goalListItemsControl.initialize();
  }

  populate = async (callback) => {
    this.$container.empty();

    return VisitWidget.Goal.query((goals) => {
      this.addGoalsToList(goals);
      if (VisitWidget.settings.adminModeOn) {
        this.listDragAndDropControl.initialize();
      }
      if (!VisitWidget.settings.isMobile) {
        VisitWidget.navigationController.setupSidebarScrolling();
      }
      if (!!callback) {
        return callback(goals);
      }
    });
  };

  addGoalsToList(goals) {
    const goalsHtml = this.getGoalListItemHtml.call(
      goals,
      this.listItemTemplate,
    );
    return this.goalListItemsControl.addListItemsHtml(goalsHtml);
  }

  onDragAndDrop(goalsAttributes) {
    return VisitWidget.Goal.updatePositions({ goals: goalsAttributes });
  }

  getGoalId($post) {
    return $post.data("id");
  }
};
