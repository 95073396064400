import React, { FunctionComponent, useEffect } from "react";
import getQueryStringVariable from "../../lib/queries/getQueryStringVariable";
import { useSelector } from "react-redux";
import { selectIsMapReady } from "../../store/map/mapSelectors";
import "./CurrentLocationMapPin.scss";
interface CurrentLocationMapPinProps {
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
}

const CurrentLocationMapPin: FunctionComponent<CurrentLocationMapPinProps> = ({
  testID,
}) => {
  const isMapReady = useSelector(selectIsMapReady());

  useEffect(() => {
    if (isMapReady) {
      const latitude = getQueryStringVariable("latitude");
      const longitude = getQueryStringVariable("longitude");
      const kioskMode = getQueryStringVariable("kiosk");

      if (kioskMode && latitude && longitude) {
        new google.maps.Marker({
          icon: "/images/you-are-here-map-pin.svg",
          map: VisitWidget.map.googleMap(),
          label: {
            text: "You are here",
            className: "you-are-here-pin",
            fontFamily: "Open Sans",
          },
          position: { lat: Number(latitude), lng: Number(longitude) },
        });
      }
    }
  }, [isMapReady]);

  return <div data-testid={testID}></div>;
};

export { CurrentLocationMapPin, CurrentLocationMapPinProps };
