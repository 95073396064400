export const getQueryStringValue = (key: string): string | undefined => {
  const result = new URLSearchParams(window.location.search).get(key);
  return result === null ? undefined : result;
};

export const getQueryStringValues = (key: string): string[] => {
  return new URLSearchParams(window.location.search).getAll(key);
};

export const getFragmentValue = (key: string): string | undefined => {
  const result = new URLSearchParams(window.location.hash.slice(1)).get(key);
  return result === null ? undefined : result;
};
