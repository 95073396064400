/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const CloseIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.9902 3.99067C4.79126 3.99072 4.59687 4.0501 4.43186 4.16121C4.26685 4.27233 4.13872 4.43013 4.06387 4.61444C3.98901 4.79875 3.97082 5.0012 4.01162 5.1959C4.05243 5.39061 4.15037 5.56872 4.29293 5.70747L10.5859 12.0004L4.29293 18.2934C4.19696 18.3855 4.12033 18.4959 4.06755 18.618C4.01476 18.7402 3.98688 18.8716 3.98553 19.0046C3.98417 19.1377 4.00938 19.2697 4.05967 19.3928C4.10996 19.516 4.18432 19.6279 4.2784 19.722C4.37248 19.8161 4.48438 19.8904 4.60756 19.9407C4.73073 19.991 4.86271 20.0162 4.99575 20.0149C5.12879 20.0135 5.26022 19.9856 5.38235 19.9328C5.50448 19.8801 5.61485 19.8034 5.70699 19.7075L12 13.4145L18.2929 19.7075C18.3851 19.8034 18.4954 19.8801 18.6176 19.9328C18.7397 19.9856 18.8711 20.0135 19.0042 20.0149C19.1372 20.0162 19.2692 19.991 19.3924 19.9407C19.5155 19.8904 19.6275 19.8161 19.7215 19.722C19.8156 19.6279 19.89 19.516 19.9403 19.3928C19.9906 19.2697 20.0158 19.1377 20.0144 19.0047C20.0131 18.8716 19.9852 18.7402 19.9324 18.618C19.8796 18.4959 19.803 18.3855 19.707 18.2934L13.414 12.0004L19.707 5.70747C19.8515 5.567 19.9502 5.38616 19.9902 5.18864C20.0301 4.99112 20.0095 4.78614 19.9309 4.60055C19.8524 4.41497 19.7196 4.25744 19.55 4.14861C19.3804 4.03978 19.1819 3.98473 18.9804 3.99067C18.7206 3.99841 18.4741 4.10699 18.2929 4.2934L12 10.5864L5.70699 4.2934C5.6138 4.19761 5.50236 4.12146 5.37924 4.06947C5.25613 4.01747 5.12384 3.99068 4.9902 3.99067Z" />
  </svg>
);

export { CloseIcon };
