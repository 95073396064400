/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const ImageIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.22 4.20001H4.38002C3.28607 4.20001 2.40002 5.1062 2.40002 6.22501V18.375C2.40002 19.4938 3.28607 20.4 4.38002 20.4H20.22C21.314 20.4 22.2 19.4938 22.2 18.375V6.22501C22.2 5.1062 21.314 4.20001 20.22 4.20001ZM10.32 8.25001C10.8665 8.25001 11.31 8.70361 11.31 9.26251C11.31 9.82141 10.8665 10.275 10.32 10.275C9.77354 10.275 9.33002 9.82141 9.33002 9.26251C9.33002 8.70361 9.77354 8.25001 10.32 8.25001ZM19.23 17.3625H5.37002L8.83403 12.8073L11.31 15.8539L14.775 11.2885L19.23 17.3625Z"
      fill="currentColor"
    />
  </svg>
);

export { ImageIcon };
