import { RootReducerState } from "../rootReducer";
import { cyrb53 } from "../../lib/cyrb53";
import { RoutesResponse } from "../../domain/navigation/queries/getDirectionsForGeoCoordinates";

export const selectRoutesForCoordinates =
  (coordinates: string) =>
  (x: RootReducerState): RoutesResponse | undefined => {
    const hashKey = cyrb53(coordinates);
    return x.map.routesCache[hashKey];
  };

export const selectIsMapReady =
  () =>
  (x: RootReducerState): boolean => {
    return x.map.isMapReady;
  };
