VisitWidget.AdInterpolator = class AdInterpolator {
  nextAdIndex: number;
  adsQueried: boolean;
  lastListIndex: number;
  viewedAdIds: any[];
  $adsWithImpressions: any[];
  allAds: any;
  filteredAds: any;
  adTemplate: any;

  constructor() {
    this.nextAdIndex = 0;
    this.adsQueried = false;
    this.lastListIndex = -1;
    this.viewedAdIds = [];
    this.$adsWithImpressions = [];
  }

  initialize() {
    this.setupAdViews();
    return this.setupClickEvents();
  }

  setupClickEvents() {
    return $(document).on(
      "click",
      "#main_sidebar_list_container .ad.post",
      (event) => {
        const $target = $(event.target);
        const $parent = $target.closest(".ad.post");

        if (VisitWidget.settings.isKiosk) {
          event.preventDefault();

          const label = $parent.find("img").prop("alt") ?? "Ad";
          const websiteUrl = $parent.find("a").prop("href");

          VisitWidget.modal.open($(".modal.web_view"), () => {
            VisitWidget.modal.open(
              $(".modal.web_view"),
              () => {
                $(".modal.web_view .header h2").text(label);
                return $(".modal.web_view iframe").attr("src", websiteUrl);
              },
              () => {
                return $(".modal.web_view iframe").removeAttr("src");
              },
            );
          });
        }

        const ad = this.getAdById($parent.data("id"));
        VisitWidget.Analytics.createAdClick(ad.id, ad.name);
        return true;
      },
    );
  }

  getAds(callback) {
    if (this.allAds === undefined) {
      return VisitWidget.Ad.query(false, (ads) => {
        this.allAds = ads;
        return this.getAdsFilteredByCategories(this.allAds).then(
          (filteredAds) => {
            this.filteredAds = filteredAds;
            return callback(this.filteredAds);
          },
        );
      });
    } else {
      return this.getAdsFilteredByCategories(this.allAds).then(
        (filteredAds) => {
          this.filteredAds = filteredAds;
          return callback(this.filteredAds);
        },
      );
    }
  }

  insertAdsIntoList(menuItem) {
    if (this.filteredAds.length === 0) {
      return;
    }

    const $items = this.getContainer(menuItem).find(".post:not(.ad)");

    return (() => {
      const result = [];
      for (
        let start = this.lastListIndex + 1,
          i = start,
          end = $items.length,
          asc = start <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        this.lastListIndex = i;
        const nthPosition = i + 1;
        if (nthPosition % (VisitWidget.settings.adNthPosition - 1) === 0) {
          const ad = this.getNextAd();
          result.push($($items[i]).after(this.createAdListItemHtml(ad)));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  createAdListItemHtml(ad) {
    if (typeof this.adTemplate === "undefined") {
      const source = $("#ad_list_item_template").html();
      this.adTemplate = Handlebars.compile(source);
    }

    const context = {
      ad,
      interpolated: "interpolated",
    };
    const html = this.adTemplate(context);
    return html;
  }

  getAdsFilteredByCategories(ads) {
    const filteredAds = [];
    return VisitWidget.global.currentListCategoryIds().then((categoryIds) => {
      for (let ad of ads) {
        for (let categoryId of categoryIds) {
          if (ad.category_ids.indexOf(categoryId) > -1) {
            filteredAds.push(ad);
            break;
          }
        }
      }

      return filteredAds;
    });
  }

  resetListAds(menuItem, callback) {
    this.lastListIndex = -1;
    this.$adsWithImpressions = [];
    this.getContainer(menuItem).find(".post.ad").remove();
    return this.getAds((ads) => {
      this.nextAdIndex = 0;
      if (this.viewedAdIds.length > 0) {
        const lastIndex = this.getLastViewedAdIndex();
        this.incrementNextAdIndex(lastIndex);
      }
      this.insertAdsIntoList(menuItem);
      if (callback != null) {
        return callback();
      }
    });
  }

  getLastViewedAdIndex() {
    for (let i = this.viewedAdIds.length - 1; i >= 0; i--) {
      for (
        let k = 0, end = this.filteredAds.length, asc = 0 <= end;
        asc ? k < end : k > end;
        asc ? k++ : k--
      ) {
        if (this.viewedAdIds[i] === this.filteredAds[k].id) {
          return k;
        }
      }
    }

    return null;
  }

  setupAdViews() {
    return $("#main_sidebar .nano > .content").scroll((e) => {
      const $container = this.getContainer(VisitWidget.store.currentMenuItem);
      const $ads = $container.find(".post.ad");
      const windowHeight = $(window).height() + 10; // so last ad gets impression
      const sidebarTopOffset = $("#main_sidebar_list_container").offset().top;
      return $ads.each((_, adElement) => {
        const $ad = $(adElement);
        const adTop = $ad.offset().top;
        const adBottomOffset = adTop + $ad.outerHeight();
        this.trackUniqueAdViews($ad, windowHeight, adBottomOffset);
        return this.trackAdImpressions(
          $ad,
          windowHeight,
          sidebarTopOffset,
          adBottomOffset,
        );
      });
    });
  }

  trackUniqueAdViews($ad, windowHeight, adBottomOffset) {
    if (adBottomOffset < windowHeight) {
      const adId = $ad.data("id");
      const viewedAdIndex = this.viewedAdIds.indexOf(adId);
      if (viewedAdIndex !== -1) {
        this.viewedAdIds.splice(viewedAdIndex, 1);
      }

      return this.viewedAdIds.push(adId);
    }
  }

  trackAdImpressions($ad, windowHeight, sidebarTopOffset, adBottomOffset) {
    const adBottomFromContainer = adBottomOffset - sidebarTopOffset;
    if (adBottomOffset < windowHeight && adBottomFromContainer > 0) {
      if (!this.isAdViewed($ad)) {
        this.$adsWithImpressions.push($ad);
        const ad = this.getAdById($ad.data("id"));
        return VisitWidget.Analytics.createAdImpression(ad.id, ad.name);
      }
    }
  }

  isAdViewed($ad) {
    const adsViewed = this.$adsWithImpressions.filter(($item) => {
      return $item.is($ad);
    });
    return adsViewed.length > 0;
  }

  getAdById(id) {
    const ads = this.filteredAds.filter((ad) => ad.id === id);
    return ads[0];
  }

  getNextAd() {
    const ad = this.filteredAds[this.nextAdIndex];
    this.incrementNextAdIndex(this.nextAdIndex);
    return ad;
  }

  incrementNextAdIndex(lastAdIndex) {
    if (lastAdIndex < this.filteredAds.length - 1) {
      return (this.nextAdIndex = lastAdIndex + 1);
    } else {
      return (this.nextAdIndex = 0);
    }
  }

  getContainer(menuItem) {
    return $(`#sidebar_${menuItem.entity}${menuItem.id}`);
  }
};
