import { RoutesResponse } from "../../domain/navigation/queries/getDirectionsForGeoCoordinates";
import { SetRoutesCacheAction } from "./mapActionTypes";

export interface MapState {
  routesCache?: Record<string, RoutesResponse>;
  isMapReady: boolean;
}

const initialState = (): MapState => {
  return {
    routesCache: {},
    isMapReady: false,
  };
};

type MapActions = SetRoutesCacheAction;

const mapReducer = (state = initialState(), action: MapActions) => {
  if (JSON.stringify(state) === "{}") {
    // ReactOnRails is a hack, and on bootstrap it will randomly lose initial
    // state, so reset it until it stick
    state = initialState();
  }

  const { payload } = action;

  return {
    ...state,
    ...payload,
  };
};

export { mapReducer };
