import getMarkerColor from "./getMarkerColor";
import getSingleMarkerPicture from "./getSingleMarkerPicture";
import getSmallMarkerPicture from "./getSmallMarkerPicture";
import getMultipleMarkerPicture from "./getMultipleMarkerPicture";
import shouldUseEventMarkerColor from "./shouldUseEventMarkerColor";
import { MarkerPicture } from "../../lib/types";

function getMarkerPictureUsingOptions(
  compiledLocation,
  shouldUseSmallMarkers: boolean,
  markerColor: string,
  useEventMarkerColor: boolean,
): MarkerPicture {
  if (shouldUseSmallMarkers) {
    return getSmallMarkerPicture(markerColor);
  } else if (compiledLocation.entities.length > 1) {
    return getMultipleMarkerPicture(
      markerColor,
      compiledLocation.entities.length,
    );
  } else {
    return getSingleMarkerPicture(
      compiledLocation.category,
      useEventMarkerColor,
    );
  }
}

export default function getMarkerPicture(
  compiledLocation,
  shouldUseSmallMarkers: boolean,
): MarkerPicture {
  const useEventMarkerColor = shouldUseEventMarkerColor(
    compiledLocation.category,
    compiledLocation.entities,
  );

  const markerColor = getMarkerColor(
    compiledLocation.category,
    useEventMarkerColor,
  );

  return getMarkerPictureUsingOptions(
    compiledLocation,
    shouldUseSmallMarkers,
    markerColor,
    useEventMarkerColor,
  );
}
