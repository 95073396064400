/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const AudioIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1334 3.60001L5.53338 9.20001H3.66672C2.63538 9.20001 1.80005 10.0353 1.80005 11.0667V12.9333C1.80005 13.9647 2.63538 14.8 3.66672 14.8H5.53338L11.1334 20.4V3.60001ZM19.0521 4.08126L17.7323 5.40105C21.3853 9.05399 21.3852 14.947 17.7323 18.599L19.0521 19.9188C23.4185 15.5534 23.4184 8.44752 19.0521 4.08126ZM16.4125 6.72084L15.0928 8.04063C17.2879 10.2357 17.2877 13.7653 15.0928 15.9594L16.4125 17.2792C19.3211 14.3717 19.3209 9.62919 16.4125 6.72084ZM13.773 9.36042L12.4532 10.6802C13.1906 11.4176 13.1906 12.5824 12.4532 13.3198L13.773 14.6414C15.2233 13.1911 15.2233 10.8107 13.773 9.36042Z"
      fill="currentColor"
    />
  </svg>
);

export { AudioIcon };
