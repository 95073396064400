import { Category, MarkerPicture } from "../../lib/types";
import * as libQueries from "../../lib/queries";

function getEventMapMarkerInnerIconUrl(category: Category): string {
  return libQueries.isIe11()
    ? category.event_map_pin_icon_3x_url
    : category.event_map_pin_svg_url;
}

function getMapMarkerInnerIconUrl(category: Category): string {
  return libQueries.isIe11()
    ? category.map_pin_icon_3x_url
    : category.map_pin_svg_url;
}

function getSingleMarkerInnerIconUrl(
  category: Category,
  shouldUseEventMarkerColor: boolean,
): string {
  if (!category) {
    return VisitWidget.settings.defaultMapPinIconUrl;
  }

  return shouldUseEventMarkerColor
    ? getEventMapMarkerInnerIconUrl(category)
    : getMapMarkerInnerIconUrl(category);
}

export default function getSingleMarkerPicture(
  category: Category,
  shouldUseEventMarkerColor: boolean,
): MarkerPicture {
  const innerIconUrl = getSingleMarkerInnerIconUrl(
    category,
    shouldUseEventMarkerColor,
  );

  return {
    url: innerIconUrl,
    width: 26,
    height: 26,
  };
}
