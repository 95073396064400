/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootReducerState } from "../../rootReducer";
import { removePlanItem } from "../../visitWidgetApi";
import { setPlan } from "../planActionTypes";
import { setTourEditAction } from "./setTourEditAction";

const removePlanItemFromTour = async (
  state: RootReducerState,
  planItemId: number,
  dispatch: any,
) => {
  const tourId = state.plan.sharedPlan.id;
  await removePlanItem(planItemId, tourId, "tours");
  dispatch(setTourEditAction(tourId, true));
};

const removePlanItemFromPlan = async (
  state: RootReducerState,
  planItemId: number,
  dispatch: any,
) => {
  const planId = state.plan.plan.id;

  await removePlanItem(planItemId, planId, "plans");

  const updatedPlan = { ...state.plan.plan };
  updatedPlan.plan_items = updatedPlan.plan_items.filter((x) => {
    return x.id !== planItemId;
  });

  VisitWidget.store.planItems = updatedPlan.plan_items;

  VisitWidget.EventBus.publish(new VisitWidget.Events.PlanItemRemoved());

  dispatch(setPlan(updatedPlan));
};

/*
 * Remove plan item from user's plan or tour if in global edit mode.
 */
const removePlanItemAction =
  (planItemId: number, plannableId: number, plannableType: string) =>
  async (dispatch, getState) => {
    const state = getState() as RootReducerState;
    if (state.plan.isSharedPlanEdit) {
      removePlanItemFromTour(state, planItemId, dispatch);
    } else {
      removePlanItemFromPlan(state, planItemId, dispatch);
    }

    VisitWidget.PlanItemControl.updatePlanItemControlsToAddState({
      plannable_id: plannableId,
      plannable_type: plannableType,
    });
  };

export { removePlanItemAction };
