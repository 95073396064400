import { setCookie, CookieKeys } from "../../../lib/cookies";
import {
  createAccessToken,
  setAccessToken,
  setOtpToken,
} from "../../visitWidgetApi";
import {
  loginError,
  loginFetch,
  loginSuccess,
  setLoginOtpRequested,
} from "../authActionTypes";
import { initializeUser } from "../helpers/initializeUser";
import { buildErrorMessage } from "../helpers/buildErrorMessage";

const handleAuthTokenSuccess = async (authToken: string, dispatch: any) => {
  setCookie(CookieKeys.authToken, authToken);
  setAccessToken(authToken);
  const { user } = await initializeUser(authToken, dispatch);

  if (user.roles.indexOf("admin") > -1) {
    // reload the page so admin markup is loaded
    window.location.reload();
    return;
  }

  dispatch(loginSuccess(user, authToken));
};

export interface LoginActionParams {
  email?: string;
  password?: string;
  otp?: string;
}

/*
 * Login user.
 */
const loginAction =
  ({ email, password, otp }: LoginActionParams) =>
  async (dispatch) => {
    try {
      dispatch(loginFetch());
      const response = await createAccessToken(email, password, otp);

      if (response.authToken) {
        await handleAuthTokenSuccess(response.authToken, dispatch);
      } else if (response.otpToken) {
        setCookie(CookieKeys.otpToken, response.otpToken);
        setOtpToken(response.otpToken);
        dispatch(setLoginOtpRequested(true, response.otpToken));
      } else {
        dispatch(loginError(response.errors));
      }

      // BRIDGE: hybrid action
      VisitWidget.Modal.close($(".modal"));
    } catch (error) {
      const errorMessage = buildErrorMessage(error);
      alert(errorMessage);
      dispatch(loginError(errorMessage));
    }
  };

export { loginAction };
