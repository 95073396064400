/* eslint-disable */
import { PlanItemDto } from "../../apiTypes";
import { RootReducerState } from "../../rootReducer";
import {
  addTourToPlan,
  createPlan,
  getPlan,
  listPlanItems,
} from "../../visitWidgetApi";
import { setPlan } from "../planActionTypes";

/**
 * Update non-React elements to display the updated plan name and plan notes. Plan menu item is
 * rendered on page load, and the updated name and notes will not show without this or page reload
 */
const updateLegacyUI = async (planId: number, planItems: PlanItemDto[]) => {
  const plan = await getPlan(planId);

  // the element name is different depending on if page was loaded when user plan is set or not
  const nameElement =
    document.getElementById("best_in_place_plan_name") ??
    document.getElementById(`best_in_place_plan_${planId}_name`);

  if (nameElement) {
    nameElement.setAttribute("data-bip-value", plan.name);
    nameElement.innerText = plan.name;
  }

  const notes = plan?.notes[0]?.body;
  if (!!notes) {
    let notesContainer = document.getElementById("plan_notes_button");
    if (notesContainer === null) {
      document
        .getElementsByClassName("share-plan-wrapper")[0]
        .insertAdjacentHTML(
          "beforebegin",
          '<div style="background-color: #989c9f; padding: 15px 10px 0 ;" id="plan_notes_button">',
        );
      notesContainer = document.getElementById("plan_notes_button");
      ReactOnRails.render(
        "NotesButton",
        {
          notes: notes,
          coverPhotoUrl:
            planItems[0]?.plannable?.cover_photo_url ??
            "/assets/default_cover_photo.png",
          title: plan.name,
          type: "plan",
        },
        "plan_notes_button",
        false,
      );
    }
  }
};

/*
 * Remove item from user plan.
 */
const addTourToPlanAction =
  (tourId: number) => async (dispatch, getState: () => RootReducerState) => {
    const state = getState() as RootReducerState;
    let plan = state.plan.plan;
    if (!plan?.id) {
      const newPlan = await createPlan();
      VisitWidget.store.currentPlanId = newPlan.id;
      VisitWidget.planItemListController.enablePlanNameEditing(newPlan);
      plan = {
        id: newPlan.id,
        plan_items: [],
      };
    }

    await addTourToPlan(tourId, plan.id);
    // the plan items returned by add to tour are a minimal version, refetch plan items to get
    // the full list data
    const planItems = await listPlanItems(plan.id);

    const updatedPlan = { ...plan };
    updatedPlan.plan_items = planItems;

    VisitWidget.store.planItems = updatedPlan.plan_items;

    planItems.forEach((x) => {
      VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState({
        plannable_id: x.plannable_id,
        plannable_type: x.plannable_type,
      });
    });

    updateLegacyUI(updatedPlan.id, planItems);
    VisitWidget.EventBus.publish(new VisitWidget.Events.TourAddedToPlan());

    dispatch(setPlan(updatedPlan));
  };

export { addTourToPlanAction };
