import { setPlan } from "../../plan/planActionTypes";
import { getCookie, CookieKeys, setCookie } from "../../../lib/cookies";
import { PlanItemDto } from "../../../store/apiTypes";
import { getUser, listPlanItems } from "../../visitWidgetApi";
import { getInitialPlanData } from "./getInitialPlanData";
import { setLegacyState } from "./setLegacyState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializeUser = async (accessToken: string, dispatch: any) => {
  const user = await getUser();
  const categoryIds = await getCookie(CookieKeys.categoryIds, undefined);
  if (categoryIds === undefined) {
    await setCookie(
      CookieKeys.categoryIds,
      JSON.stringify(VisitWidget.store.categoryIds),
    );
  }

  const planData = await getInitialPlanData(user);

  let planItems = [] as Array<PlanItemDto>;
  if (planData.planId) {
    planItems = await listPlanItems(planData.planId);
  }

  const planTourIds = VisitWidget.store.planTourIds;
  setLegacyState({
    user,
    accessToken,
    planItems,
    planId: planData.planId,
    planTourIds,
  });
  dispatch(setPlan({ id: planData.planId, plan_items: planItems }));
  return {
    planItems,
    user,
    accessToken,
  };
};

export { initializeUser };
