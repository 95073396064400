/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const ShareIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8 2L7.9 5.9H10.825V17.6H12.775V5.9H15.7L11.8 2ZM5.95 6.875C4.88426 6.875 4 7.75926 4 8.825V19.55C4 20.6157 4.88426 21.5 5.95 21.5H17.65C18.7157 21.5 19.6 20.6157 19.6 19.55V8.825C19.6 7.75926 18.7157 6.875 17.65 6.875H16.675V8.825H17.65V19.55H5.95V8.825H6.925V6.875H5.95Z" />
  </svg>
);

export { ShareIcon };
