/* eslint-disable max-len */
import React from "react";
import { theme } from "../ConfigProvider/ConfigProvider";
import { IconProps } from "./IconProps";

const NotificationIcon = ({ size = 24, testID, enabled }: IconProps) => {
  const balloonColor = enabled ? theme.colorEnabled : theme.colorTextDisabled;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      data-testid={testID}
      viewBox="0 0 24 24"
      fill="none">
      <g>
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0752 6.80157C16.7414 6.92975 16.3789 7 16 7C14.3431 7 13 5.65685 13 4C13 3.54385 13.1018 3.11148 13.2839 2.72436C13.021 2.29029 12.5442 2 12 2C11.172 2 10.5 2.672 10.5 3.5V4.19531C7.91318 4.86209 6 7.2048 6 10V15L4 17V19H10.2695H13.7285H20V17L18 15V10C18 8.82322 17.6609 7.72665 17.0752 6.80157ZM10.5858 21.4142C10.2107 21.0391 10 20.5304 10 20H14C14 20.5304 13.7893 21.0391 13.4142 21.4142C13.0391 21.7893 12.5304 22 12 22C11.4696 22 10.9609 21.7893 10.5858 21.4142Z"
          fill="currentColor"
        />
        <circle id="Ellipse 2" cx="16" cy="4" r="2" fill={balloonColor} />
      </g>
    </svg>
  );
};

export { NotificationIcon };
