import React, { FunctionComponent, useCallback } from "react";
import translate from "../../../../lib/queries/translate";
const UnauthenticatedProfileMenu: FunctionComponent<{}> = () => {
  const onLoginClick = useCallback(() => {
    VisitWidget.registrationModal.openModalAction("sign_in");
  }, []);

  return (
    <div className="touch-control">
      <div
        onClick={onLoginClick}
        className="profile dropdown"
        data-dropdownanchor="true">
        <div className="middle">
          <div className="content">
            <span className="profile_name">
              {translate("profile_menu.sign_in")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UnauthenticatedProfileMenu };
