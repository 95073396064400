type Params = {
  entityId: number;
  entityType: string;
  entitySlug: string;
  entityPlanItems: Record<string, unknown>;
};

export default function enableEditMode({
  entityId,
  entityType,
  entitySlug,
  entityPlanItems,
}: Params): void {
  VisitWidget.store.globalEditMode = {
    ...VisitWidget.store.globalEditMode,
    enabled: true,
    entityId,
    entityType,
    entitySlug,
    entityPlanItems,
  };
}
