import { SetMenuItemAction, MenuItemNames } from "./menuItemActionTypes";

export interface MenuItemState {
  menuItemName?: MenuItemNames;
}

const initialState = (): MenuItemState => {
  return {};
};

type MenuItemActions = SetMenuItemAction;

const menuItemReducer = (state = initialState(), action: MenuItemActions) => {
  if (JSON.stringify(state) === "{}") {
    // ReactOnRails is a hack, and on bootstrap it will randomly lose initial
    // state, so reset it until it stick
    state = initialState();
  }

  const { payload } = action;

  // // TODO: refactor when menu items are in React
  // if (state?.menuItemName !== payload?.menuItemName) {
  //   VisitWidget.navigationController.navigateToMenuItemByEntity("SharedPlan");
  // }

  return {
    ...state,
    ...payload,
  };
};

export { menuItemReducer };
