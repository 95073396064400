import { Category, Entity } from "../../lib/types";
import getCategoryFromId from "./getCategoryFromId";

const getFirstCategoryThatMatchesCurrentCategories = async (
  entities: Array<Entity>,
): Promise<Category> => {
  const currentCategoryIds = await VisitWidget.global.currentListCategoryIds();
  let category;
  for (const entity of entities) {
    const firstCategoryId = entity.category_ids.find(
      (categoryId) => currentCategoryIds.indexOf(categoryId) > -1,
    );
    if (firstCategoryId) {
      category = getCategoryFromId(firstCategoryId);
      break;
    }
  }

  return category;
};

export default getFirstCategoryThatMatchesCurrentCategories;
