import React, { useEffect, useRef } from "react";
import "./styles.scss";
import calendarIcon from "../../../../../../../assets/images/calendar_ico_white.png";
import translate from "../../../../../lib/queries/translate";

// Full logic current lives in:
// app/assets/javascripts/controllers/controls/date_range_control.js.coffee
const DateRangeControl = ({
  containerSelector,
  onInitialize,
  shouldAlignRight,
}) => {
  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current) {
      const dateRangeControl = new VisitWidget.DateRangeControl(
        containerSelector,
      );
      dateRangeControl.initialize();
      onInitialize(dateRangeControl);
      isInitialized.current = true;
    }
  }, [containerSelector, onInitialize]);

  return (
    <div
      className={`DateRangeControl time_frame ${
        shouldAlignRight ? "hasExclusiveCategories" : ""
      }`}>
      <div className="DateRangeControl-startDateContainer">
        <label className="client-primary-color">
          {shouldAlignRight
            ? translate("date_range_control.dates")
            : translate("date_range_control.start")}
        </label>

        <img src={calendarIcon} className="DateRangeControl-calendarIcon" />

        <input
          type="text"
          className="time_frame_input time_frame_start_date"
          readOnly={true}
          name="startDate"
        />
      </div>
      {shouldAlignRight && (
        <div className="DateRangeControl-dateSeparator ">-</div>
      )}
      <div className="DateRangeControl-endDateContainer">
        {!shouldAlignRight && (
          <>
            <label className="client-primary-color">
              {translate("date_range_control.end")}
            </label>
            <img src={calendarIcon} className="DateRangeControl-calendarIcon" />
          </>
        )}
        <input
          type="text"
          className="time_frame_input time_frame_end_date"
          readOnly={true}
          name="endDate"
        />
      </div>
    </div>
  );
};

export default DateRangeControl;
