/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button } from "../../../components/Button";
import { NotificationIcon } from "../../../components/icons";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/TextInput";
import { Select } from "../../../components/Select";
import { DateRangePicker } from "../../../components/DateRangePicker";
import { Toggle } from "../../../components/Toggle";
import dayjs from "dayjs";
import { TimeRangePicker } from "../../../components/TimeRangePicker";
import {
  Modal,
  ModalAction,
  ModalContent,
  ModalTitle,
} from "../../../components/Modal";
import {
  updateEntityProximityMessageSetting,
  getEntityProximityMessageSetting,
} from "../../../store/visitWidgetApi";
import { ProximityMessageDto } from "../../../store/apiTypes";
import translate from "../../../lib/queries/translate";
import { mapFormToUpsertRequest } from "./helpers";
import { isDefined } from "../../../legacy/lib/util";
import "./ProximityMessageSetting.scss";

// eslint-disable-next-line no-shadow
enum ProximityMessageSettingTestIds {
  titleField = "proximity-message-setting-title-field",
  bodyField = "proximity-message-setting-body-field",
  triggerActionField = "proximity-message-setting-trigger-action-field",
  actionUrlField = "proximity-message-setting-action-url-field",
  geofenceRadiusField = "proximity-message-setting-geofence-radius-field",
  triggerActivityPeriodField = "proximity-message-setting-trigger-activity-period-field",
  triggerOpeningHoursField = "proximity-message-setting-trigger-opening-hours-field",
  triggerTimespansField = "proximity-message-setting-trigger-timespans-field",
  notificationFrequencyField = "proximity-message-setting-notification-frequency-field",
  campaignDateRangeField = "proximity-message-setting-campaign-date-range-field",
  enabledField = "proximity-message-setting-enabled-field",
}

const weekdayKeys = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day0"];

const actionOptions = [
  {
    label: translate("proximity_message.action_url_select"),
    value: "link_to_custom_url",
  },
  {
    label: translate("proximity_message.action_deeplink_select"),
    value: "deeplink_to_item",
  },
];

const triggerActivityPeriodOptions = [
  {
    label: translate("proximity_message.timespan_anytime"),
    value: "anytime",
  },
  {
    label: translate("proximity_message.timespan_default_hours"),
    value: "default_opening_hours",
  },
  {
    label: translate("proximity_message.timespan_custom_hours"),
    value: "custom_opening_hours",
  },
];

const frequencyOptions = [
  {
    label: translate("proximity_message.frequency_once"),
    value: "once",
  },
  {
    label: translate("proximity_message.frequency_hourly"),
    value: "hourly",
  },
  {
    label: translate("proximity_message.frequency_daily"),
    value: "daily",
  },
  {
    label: translate("proximity_message.frequency_weekly"),
    value: "weekly",
  },
  {
    label: translate("proximity_message.frequency_monthly"),
    value: "monthly",
  },
];

const now = dayjs();

const getInitialValues = (
  existing?: ProximityMessageDto,
): ProximityMessageSettingFormState => {
  const triggerOpeningHours = {} as {
    [key: string]: [dayjs.Dayjs, dayjs.Dayjs];
  };
  if (isDefined(existing.trigger_opening_hours)) {
    const hoursByDay = new Map<string, Array<string>>(
      Object.entries(existing.trigger_opening_hours),
    );
    hoursByDay.forEach((value, key) => {
      const formattedKey = (key[0].toUpperCase() + key.slice(1)).replace(
        "_",
        "",
      );
      triggerOpeningHours[`triggerOpeningHours${formattedKey}`] = [
        dayjs(value[0], "HH:mm"),
        dayjs(value[1], "HH:mm"),
      ];
    });
  }

  let campaignRunTime: undefined | [dayjs.Dayjs, dayjs.Dayjs];
  if (
    isDefined(existing?.campaign_starts_on) &&
    isDefined(existing?.campaign_ends_on)
  ) {
    campaignRunTime = [
      dayjs(existing.campaign_starts_on, "YYYY-MM-DD"),
      dayjs(existing.campaign_ends_on, "YYYY-MM-DD"),
    ];
  }

  const initialValues = {
    id: existing?.id ?? 0,
    clientId: existing?.client_id ?? 0,
    entityId: existing?.entity_id ?? 0,
    entityType: existing?.entity_type ?? "",
    title: existing?.title ?? "",
    body: existing?.body ?? "",
    triggerAction: existing?.trigger_action ?? "deeplink_to_item",
    actionUrl: existing?.action_url ?? "",
    geofenceRadius: existing?.geofence_radius ?? 0,
    triggerActivityPeriod: existing?.trigger_activity_period ?? "anytime",
    notificationFrequency: existing?.notification_frequency ?? "daily",
    enabled: existing?.enabled ?? false,
    campaignRunTime: campaignRunTime ?? [now, now],
    ...triggerOpeningHours,
  } as ProximityMessageSettingFormState;

  return initialValues;
};

// eslint-disable-next-line complexity
const ProximityMessageSetting: FunctionComponent<
  ProximityMessageSettingScreenProps
> = ({ entityId, entityType }) => {
  const [state, setState] = useState<{
    entityStatus: "idle" | "fetching" | "error";
    saveStatus: "idle" | "fetching" | "error";
    isModalOpen: boolean;
    proximityMessage?: ProximityMessageDto;
  }>({ entityStatus: "idle", saveStatus: "idle", isModalOpen: false });

  useEffect(() => {
    (async () => {
      try {
        if (
          state.proximityMessage?.id !== entityId ||
          state.proximityMessage?.entity_type !== entityType
        ) {
          const value = await getEntityProximityMessageSetting({
            entityId,
            entityType,
          });
          setState((current) => ({
            ...current,
            entityStatus: "idle",
            proximityMessage: value,
          }));
        }
      } catch (error) {
        setState((current) => ({ ...current, entityStatus: "error" }));
      }
    })();
  }, [
    entityId,
    entityType,
    state.proximityMessage?.entity_type,
    state.proximityMessage?.id,
  ]);

  const [form] = Form.useForm<ProximityMessageSettingFormState>();

  const handleProximityMessagePress = useCallback(async () => {
    setState((current) => ({ ...current, isModalOpen: !current.isModalOpen }));
  }, []);

  const handleModalClose = useCallback(() => {
    setState((current) => ({ ...current, isModalOpen: false }));
  }, []);

  const handleSubmit = useCallback(
    async (values: ProximityMessageSettingFormState) => {
      setState((current) => ({ ...current, saveStatus: "fetching" }));
      try {
        const upsertValues = mapFormToUpsertRequest(values);
        const updatedProximityMessage =
          await updateEntityProximityMessageSetting({
            id: state.proximityMessage?.id,
            entityId: entityId,
            entityType: entityType,
            updates: upsertValues,
          });
        setState((current) => ({
          ...current,
          saveStatus: "idle",
          proximityMessage: updatedProximityMessage,
        }));
        handleModalClose();
      } catch (e) {
        setState((current) => ({ ...current, saveStatus: "error" }));
      }
    },
    [entityId, entityType, handleModalClose, state.proximityMessage?.id],
  );

  const action = Form.useWatch("triggerAction", form);
  const trigger = Form.useWatch("triggerActivityPeriod", form);

  return (
    <>
      <div className="touch-control fr">
        <div className="btn icon_btn">
          <div className="content" style={{ color: "#fff" }}>
            <Button
              type="icon"
              onPress={handleProximityMessagePress}
              icon={
                <NotificationIcon
                  enabled={state.proximityMessage?.enabled}
                  size={28}
                />
              }
            />
          </div>
        </div>
      </div>
      <Modal open={state.isModalOpen} onClose={handleModalClose} width={560}>
        <ModalTitle fontSize="large">
          {translate("proximity_message.modal_title")}
        </ModalTitle>
        <ModalContent>
          {state.isModalOpen && state.entityStatus === "idle" && (
            <Form
              form={form}
              name="proximityMessageSetting"
              onFinish={handleSubmit}
              disabled={state.saveStatus === "fetching"}
              layout="vertical"
              initialValues={getInitialValues(state.proximityMessage)}>
              <div className="proximity-message-form-title">
                {translate("proximity_message.message_content")}
              </div>

              <div className="proximity-message-form-row">
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "A title is required for the Proximity Message.",
                    },
                  ]}>
                  <TextInput
                    name="title"
                    size="large"
                    form={form}
                    maxLength={50}
                    placeholder={translate("proximity_message.title")}
                    testID={ProximityMessageSettingTestIds.titleField}
                  />
                </Form.Item>
              </div>
              <div className="proximity-message-form-row">
                <Form.Item
                  name="body"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please provide a message body for the Proximity Message.",
                    },
                  ]}>
                  <TextInput
                    name="body"
                    size="large"
                    form={form}
                    multiline={true}
                    numberOfLines={4}
                    placeholder={translate("proximity_message.body")}
                    testID={ProximityMessageSettingTestIds.bodyField}
                  />
                </Form.Item>
              </div>

              <div className="proximity-message-form-title">
                {translate("proximity_message.action")}
              </div>

              <div className="proximity-message-form-row">
                <Form.Item
                  name="triggerAction"
                  label={translate("proximity_message.trigger_action")}>
                  <Select
                    name="triggerAction"
                    form={form}
                    options={actionOptions}
                    placeholder={translate("proximity_message.select_action")}
                    testID={ProximityMessageSettingTestIds.triggerActionField}
                  />
                </Form.Item>
              </div>

              <div className="proximity-message-form-row">
                <Form.Item
                  name="actionUrl"
                  hidden={action !== "link_to_custom_url"}
                  rules={[
                    {
                      required: action === "link_to_custom_url",
                    },
                    { type: "url" },
                    {
                      pattern: RegExp("^http(s)?://"),
                      message:
                        "You must begin the URL with 'http://' or 'https://'.",
                    },
                  ]}>
                  <TextInput
                    name="actionUrl"
                    size="large"
                    form={form}
                    placeholder="https://www.visitwidget.com"
                    testID={ProximityMessageSettingTestIds.actionUrlField}
                  />
                </Form.Item>
              </div>

              <div className="proximity-message-form-title">
                {translate("proximity_message.geofence_radius")}
              </div>

              <div className="proximity-message-form-row">
                <Form.Item name="geofenceRadius">
                  <TextInput
                    name="geofenceRadius"
                    size="large"
                    form={form}
                    placeholder="0"
                    testID={ProximityMessageSettingTestIds.geofenceRadiusField}
                  />
                </Form.Item>
              </div>

              <div className="proximity-message-form-title">
                {translate("proximity_message.notification_trigger")}
              </div>

              <div className="proximity-message-form-row">
                <Form.Item name="triggerActivityPeriod">
                  <Select
                    name="triggerActivityPeriod"
                    form={form}
                    options={triggerActivityPeriodOptions}
                    placeholder={translate("proximity_message.timespan")}
                    testID={
                      ProximityMessageSettingTestIds.triggerActivityPeriodField
                    }
                  />
                </Form.Item>
              </div>
              {trigger === "default_opening_hours" && (
                <div className="proximity-message-form-row proximity-message-form-sub-title">
                  {moment.weekdays(1)} - {moment.weekdays(0)}, 9:00 AM - 5:00 PM
                </div>
              )}
              <div
                style={{
                  display:
                    trigger === "custom_opening_hours" ? "inherit" : "none",
                }}>
                {weekdayKeys.map((weekday) => (
                  <div className="proximity-message-form-row" key={weekday}>
                    <div
                      className="proximity-message-form-column proximity-message-form-sub-title"
                      style={{ flex: 1 }}>
                      {moment.weekdays(parseInt(weekday.slice(-1)))}
                    </div>
                    <div
                      className="proximity-message-form-column"
                      style={{ flex: 3 }}>
                      <Form.Item
                        key={weekday}
                        name={`triggerOpeningHours${weekday}`}>
                        <TimeRangePicker
                          form={form}
                          name={`triggerOpeningHours${weekday}`}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))}
              </div>

              <div className="proximity-message-form-title">
                {translate("proximity_message.notification_frequency")}
              </div>
              <div className="proximity-message-form-row">
                <Form.Item name="notificationFrequency">
                  <Select
                    name="notificationFrequency"
                    form={form}
                    options={frequencyOptions}
                    placeholder={translate(
                      "proximity_message.select_frequency",
                    )}
                    testID={
                      ProximityMessageSettingTestIds.notificationFrequencyField
                    }
                  />
                </Form.Item>
              </div>
              <div className="proximity-message-form-title">
                {translate("proximity_message.campaign_runtime")}
              </div>
              <div className="proximity-message-form-row">
                <Form.Item name="campaignRunTime">
                  <DateRangePicker
                    name="campaignRunTime"
                    form={form}
                    testID={
                      ProximityMessageSettingTestIds.campaignDateRangeField
                    }
                  />
                </Form.Item>
              </div>
              <div className="proximity-message-form-row flex-end">
                <div className="proximity-message-form-inline ">
                  <div className="proximity-message-form-inline-title">
                    {translate("proximity_message.status")}
                  </div>
                  <Form.Item name="enabled">
                    <Toggle
                      name="enabled"
                      form={form}
                      testID={ProximityMessageSettingTestIds.enabledField}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
        </ModalContent>

        <ModalAction position="right">
          <Button
            type="outline"
            onPress={handleModalClose}
            disabled={state.saveStatus === "fetching"}>
            Cancel
          </Button>
        </ModalAction>

        <ModalAction position="right">
          <Button
            type="primary"
            disabled={state.saveStatus === "fetching"}
            onPress={() => {
              form.submit();
            }}>
            Save
          </Button>
        </ModalAction>
      </Modal>
    </>
  );
};

interface ProximityMessageSettingFormState {
  id?: number;
  clientId: number;
  entityId: number;
  entityType: string;
  title: string;
  body: string;
  triggerAction: "link_to_custom_url" | "deeplink_to_item";
  actionUrl?: string;
  geofenceRadius: number;
  triggerActivityPeriod:
    | "anytime"
    | "default_opening_hours"
    | "custom_opening_hours";
  triggerOpeningHoursDay0?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay1?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay2?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay3?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay4?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay5?: [dayjs.Dayjs, dayjs.Dayjs];
  triggerOpeningHoursDay6?: [dayjs.Dayjs, dayjs.Dayjs];
  notificationFrequency: "once" | "hourly" | "daily" | "weekly" | "monthly";
  enabled: boolean;
  campaignRunTime?: [dayjs.Dayjs, dayjs.Dayjs];
}

interface ProximityMessageSettingScreenProps {
  entityId: number;
  entityType: string;
}

export {
  ProximityMessageSetting,
  ProximityMessageSettingFormState,
  ProximityMessageSettingScreenProps,
  ProximityMessageSettingTestIds,
};
