/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const HappinessIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M12,22.7
      c-2.9,0-5.6-1.2-7.5-3.1C3.7,18.8,3,17.9,2.5,17c-0.8-1.5-1.2-3.2-1.2-5C1.3,6.1,6.1,1.3,12,1.3c2.8,0,5.4,1.1,7.3,2.8
      c1,0.9,1.8,2,2.4,3.2c0.7,1.4,1,3,1,4.6C22.7,17.9,17.9,22.7,12,22.7z"
    />
    <path d="M8.1,10.6c0.7,0,1.3-0.6,1.3-1.3C9.4,8.6,8.8,8,8.1,8C7.4,8,6.8,8.6,6.8,9.3C6.8,10,7.4,10.6,8.1,10.6z" />
    <path d="M16.1,10.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3C14.8,10,15.4,10.6,16.1,10.6z" />
    <path
      d="M12,18.6c2.5,0,4.9-1.3,6.3-3.4l-1.1-0.7c-1.3,2-3.6,3-5.9,2.8c-1.8-0.2-3.5-1.2-4.5-2.8l-1.1,0.7
	c1.2,1.9,3.2,3.1,5.4,3.4C11.4,18.6,11.7,18.6,12,18.6z"
    />
  </svg>
);

export { HappinessIcon };
