import { setPlanAction } from "../../store/plan/actions/setPlanAction";
import { PlanItemDto } from "../../store/apiTypes";

VisitWidget.store.addPlanItems = (planItems: PlanItemDto[]) => {
  VisitWidget.store.planItems = VisitWidget.store.planItems.concat(planItems);
  (window as any).globalDispatch(
    setPlanAction({ plan_items: VisitWidget.store.planItems }),
  );
};

VisitWidget.store.removePlanItems = (planItemsToRemove: PlanItemDto[]) => {
  VisitWidget.store.planItems = VisitWidget.store.planItems.filter((x) => {
    return (
      planItemsToRemove.findIndex(
        (y) =>
          x.plannable_id === y.plannable_id &&
          x.plannable_type === y.plannable_type,
      ) < 0
    );
  });
  (window as any).globalDispatch(
    setPlanAction({ plan_items: VisitWidget.store.planItems }),
  );
};

VisitWidget.store.addTourIdToPlan = (tourId: number): void => {
  if (VisitWidget.store.planTourIds.indexOf(tourId) === -1) {
    VisitWidget.store.planTourIds.push(tourId);
  }
};

VisitWidget.store.removeTourIdFromPlan = (tourId: number): void => {
  VisitWidget.store.planTourIds = VisitWidget.store.planTourIds.filter(
    (x) => x !== tourId,
  );
};
