import { Plan, PlanItem } from "../../lib/apiTypes";
import { RootReducerState } from "../rootReducer";

export const selectPlan = () => (x: RootReducerState) => {
  return x.plan.plan;
};

export const selectPlanItemInPlan =
  (planItem: PlanItem) =>
  (x: RootReducerState): { id: number } | undefined => {
    return x.plan.isSharedPlanEdit
      ? x.plan.sharedPlan.planItems.find((y) => y.id === planItem.id)
      : x.plan.plan.plan_items.find(
          (y) =>
            y.plannable_id === planItem.originalDto.plannable_id &&
            y.plannable_type === planItem.originalDto.plannable_type,
        );
  };

export const selectSharedPlans = () => (x: RootReducerState) => {
  return x.plan.sharedPlans;
};

export const selectSharedPlan =
  () =>
  (x: RootReducerState): Plan | undefined => {
    return x.plan.sharedPlan;
  };

export const selectIsEditingSharedPlan = () => (x: RootReducerState) =>
  x.plan.isSharedPlanEdit;
