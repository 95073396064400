/* eslint-disable */
import React, { FunctionComponent, useCallback } from "react";
import "./styles.scss";
import { ConfigProvider } from "../ConfigProvider";
import { message, Upload as AntUpload } from "antd";
import { AudioIcon, ImageIcon, VideoIcon } from "../icons";
import { RcFile, UploadFile, UploadChangeParam } from "antd/es/upload";

const { Dragger } = AntUpload;

function getUploadVariablesForFileType(fileType: UploadProps["fileType"]) {
  let uploadIcon: JSX.Element;
  let uploadText: string;
  let uploadHint: string;
  let mimeTypes: string;

  switch (fileType) {
    case "image":
      uploadIcon = <ImageIcon size={50} />;
      uploadText =
        "Drag and drop an image or <span class='highlight'>Browse</span>";
      uploadHint = "High resolution images (png, jpg). Minimum 960px x 600px.";
      mimeTypes = "image/png, image/jpg, image/jpeg";
      break;
    case "audio":
      uploadIcon = <AudioIcon size={50} />;
      uploadText =
        "Drag and drop an audio file or <span class='highlight'>Browse</span>";
      uploadHint = "Audio files (mp3). Max 20MB.";
      mimeTypes = "audio/mp3";
      break;
    case "video":
      uploadIcon = <VideoIcon size={50} />;
      uploadText =
        "Drag and drop a video file or <span class='highlight'>Browse</span>";
      uploadHint = "Video files (mp4). Max 200MB.";
      mimeTypes = "video/mp4";
      break;
  }

  return { uploadIcon, uploadText, uploadHint, mimeTypes };
}

function validateFile(file: RcFile, fileType: UploadProps["fileType"]) {
  if (fileType === "image") {
    const isCorrectFileType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isCorrectFileType) {
      message.error("File must be a jpg or png.");
    }

    return isCorrectFileType;
  } else if (fileType === "video") {
    const isCorrectFileType = file.type === "video/mp4";
    if (!isCorrectFileType) {
      message.error("File must be an mp4.");
    }

    const isCorrectFileSize = file.size / 1024 / 1024 < 200;
    if (!isCorrectFileSize) {
      message.error("File must be smaller than 200MB.");
    }

    return isCorrectFileType && isCorrectFileSize;
  } else if (fileType === "audio") {
    const isCorrectFileType = file.type === "audio/mp3";
    if (!isCorrectFileType) {
      message.error("File must be an mp3.");
    }

    const isCorrectFileSize = file.size / 1024 / 1024 < 20;
    if (!isCorrectFileSize) {
      message.error("File must be smaller than 20MB.");
    }

    return isCorrectFileType && isCorrectFileSize;
  }
}

const Upload: FunctionComponent<UploadProps> = ({
  disabled = false,
  fileType,
  method,
  multiple = false,
  name,
  onChange,
  testID,
}) => {
  const { uploadIcon, uploadText, uploadHint, mimeTypes } =
    getUploadVariablesForFileType(fileType);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      return validateFile(file, fileType);
    },
    [fileType],
  );

  return (
    <ConfigProvider>
      <Dragger
        accept={mimeTypes}
        beforeUpload={beforeUpload}
        disabled={disabled}
        method={method}
        multiple={multiple}
        name={name}
        onChange={onChange}
        data-testid={testID}>
        <p className="ant-upload-drag-icon">{uploadIcon}</p>
        <p
          className="ant-upload-text"
          dangerouslySetInnerHTML={{ __html: uploadText }}
        />
        <p className="ant-upload-hint">{uploadHint} </p>
      </Dragger>
    </ConfigProvider>
  );
};

export interface UploadProps {
  /**
   * Disabled upload button
   */
  disabled?: boolean;
  /**
   * File type group
   */
  fileType: "image" | "video" | "audio";
  /**
   * HTTP method for sending data
   */
  method?: "POST" | "PUT" | "PATCH";
  /**
   * Whether to support multiple files
   */
  multiple?: boolean;
  /**
   * Name of uploading file
   */
  name?: string;
  /**
   * Function to be executed when uploading state is changed
   */
  onChange?: (info: UploadChangeParam<UploadFile>) => void;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
}

export { Upload };
