import React, { FunctionComponent, useState } from "react";
import { Button, ButtonProps } from "../Button";
import { Popover } from "antd";

/**
 * A button that when pressed will show a set of menu items. Useful for confirmations such as a
 * delete button.
 */
const PopMenuButton: FunctionComponent<PopMenuButtonProps> = ({
  menuItems,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopover = (isOpenUpdate: boolean) => {
    setIsOpen(isOpenUpdate);
  };

  return (
    <Popover
      content={
        <div>
          {menuItems.map((x) => (
            <Button type="text" key={x.id} onPress={x.onPress}>
              {x.title}
            </Button>
          ))}
        </div>
      }
      arrow={false}
      overlayStyle={{ borderRadius: 0 }}
      trigger="click"
      onOpenChange={handlePopover}
      open={isOpen}>
      <Button
        onPress={() => {
          handlePopover(true);
        }}
        {...rest}></Button>
    </Popover>
  );
};

interface PopMenuButtonProps extends ButtonProps {
  /**
   * The menuitems that appear in the popover when a user clicks the button.
   */
  menuItems: { id: string; title: string; onPress: () => void }[];
}

export { PopMenuButton, PopMenuButtonProps };
