VisitWidget.MapItControl = class MapItControl {
  containerSelector: string;
  options: {
    shouldDisableClickEvent?: boolean;
    shouldTrackAnalytics?: boolean;
    entityName?: string;
    hideFlyout?: boolean;
  };

  constructor(containerSelector, options) {
    this.containerSelector = containerSelector;
    if (options == null) {
      options = {};
    }
    this.options = options;
  }

  public initialize(onClickCallback = null): void {
    this.setupMapItEvent(onClickCallback);
  }

  private setupMapItEvent(onClickCallback = null): void {
    if (!this.containerSelector) {
      return;
    }

    $("body").off("click", `${this.containerSelector} .touch-map-it-control`);

    $("body").on(
      "click",
      `${this.containerSelector} .touch-map-it-control`,
      (event) => {
        if (this.options.shouldDisableClickEvent) {
          return false;
        }
        const $target = $(event.target);
        const $touchControl = $target.closest(".touch-map-it-control");
        const $mapIt = $touchControl.find(".map_it");

        if (this.options.shouldTrackAnalytics) {
          VisitWidget.Analytics.createMapItClick(
            $mapIt.data("entity-id"),
            $mapIt.data("entity-type"),
            this.options.entityName,
          );
        }

        this.showMapItLocation($mapIt, this.options.hideFlyout ?? true);
        if (onClickCallback !== null) {
          onClickCallback();
        }

        return false;
      },
    );
  }

  public showMapItLocation($mapIt, hideFlyout): void {
    VisitWidget.Commands.ShowMapItLocation.call($mapIt, hideFlyout);
  }
};
