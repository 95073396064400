/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const CopyIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4.9 3C3.85025 3 3 3.85025 3 4.9V18.2H4.9V4.9H18.2V3H4.9ZM8.7 6.8C7.65025 6.8 6.8 7.65025 6.8 8.7V20.1C6.8 21.1497 7.65025 22 8.7 22H20.1C21.1497 22 22 21.1497 22 20.1V8.7C22 7.65025 21.1497 6.8 20.1 6.8H8.7ZM8.7 8.7H20.1V20.1H8.7V8.7Z" />
  </svg>
);

export { CopyIcon };
