import { Category } from "../../lib/types";

export default function getMarkerColor(
  category: Category,
  shouldUseEventMarkerColor: boolean,
): string {
  if (category) {
    if (shouldUseEventMarkerColor) {
      return category.event_map_pin_color;
    } else {
      return category.color;
    }
  } else {
    return VisitWidget.settings.currentClientPrimaryColor;
  }
}
