import { PlanItemMinimalDto, TourDto } from "../../apiTypes";
import { adaptTourDtoToPlan, getTour } from "../../visitWidgetApi";
import { setSharedPlan, setTourEdit } from "../planActionTypes";

const setAddRemoveToPlanEntityState = (tour: TourDto, isEditing: boolean) => {
  const activePlanItems = isEditing
    ? tour.plan_items
    : (VisitWidget.store.planItems as PlanItemMinimalDto[]);

  const previouslyActivePlanItems = isEditing
    ? (VisitWidget.store.planItems as PlanItemMinimalDto[])
    : tour.plan_items;

  activePlanItems.forEach((x) => {
    VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState(x);
  });

  previouslyActivePlanItems.forEach((x) => {
    VisitWidget.PlanItemControl.updatePlanItemControlsToAddState(x);
  });
};

const setTourEditAction =
  (tourId: number, isEditing: boolean) => async (dispatch) => {
    const tour = await getTour(tourId);

    // assumes only a SharedPlan is being edited which will change in the future
    dispatch(setSharedPlan(adaptTourDtoToPlan(tour)));

    setAddRemoveToPlanEntityState(tour, isEditing);

    if (isEditing) {
      VisitWidget.store.globalEditMode.entityId = tourId;
      VisitWidget.store.globalEditMode.enabled = true;
      VisitWidget.store.globalEditMode.entityType = "Tour";
      VisitWidget.store.globalEditMode.mode = "sharedPlans";
    } else {
      VisitWidget.store.globalEditMode.entityId = null;
      VisitWidget.store.globalEditMode.enabled = false;
      VisitWidget.store.globalEditMode.entityType = null;
      VisitWidget.store.globalEditMode.mode = null;
    }

    dispatch(setTourEdit(isEditing));
  };

export { setTourEditAction };
