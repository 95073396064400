import { Plan } from "../../../lib/apiTypes";
import { RootReducerState } from "../../rootReducer";
import { setSharedPlan } from "../planActionTypes";

/*
 * Set admin shared plan.
 */
const setSharedPlanAction =
  (plan: Plan | undefined) =>
  async (dispatch, getState: () => RootReducerState) => {
    dispatch(setSharedPlan(plan));
  };

export { setSharedPlanAction };
