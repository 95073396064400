import { AppDispatch } from "../../../store/store";
import { updatePassword } from "../../visitWidgetApi";
import {
  updatePasswordError,
  updatePasswordFetch,
  updatePasswordSuccess,
} from "../authActionTypes";
import { buildErrorMessage } from "../helpers/buildErrorMessage";
import { refreshSessionAction } from "./refreshSessionAction";

/*
 * Update the password via reset password user flow.
 */
export const updatePasswordAction =
  ({
    newPassword,
    resetPasswordToken,
  }: {
    newPassword: string;
    resetPasswordToken: string;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(updatePasswordFetch());

      await updatePassword({
        newPassword,
        resetPasswordToken,
      });

      window.history.pushState({}, "", "/");
      if (VisitWidget.settings.isMobile) {
        // temporary hack for SFMT, should be removed no later than end of Sep 2023
        if (VisitWidget.settings.currentClientId !== 953) {
          (window as Window).location = "/";
        }
      } else {
        dispatch(refreshSessionAction());
      }

      dispatch(updatePasswordSuccess());
    } catch (error) {
      const errorMessage = buildErrorMessage(error);
      alert(errorMessage);
      dispatch(updatePasswordError(errorMessage));
    }
  };
