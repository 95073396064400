/* eslint-disable */
/**
 * Do any analytics needed on page load, this should be deprecated when fly-outs are rendered
 * via React on page load and analytics events can be more directly used
 */
const createEntityViewAnalytics = () => {
  const placeId = ($("#place_id")[0] as HTMLInputElement | undefined)?.value;
  const eventId = ($("#event_id")[0] as HTMLInputElement | undefined)?.value;
  const tourId = ($("#tour_id")[0] as HTMLInputElement | undefined)?.value;
  const feedPostId = ($("#feed_post_id")[0] as HTMLInputElement | undefined)
    ?.value;

  if (
    tourId === undefined &&
    placeId === undefined &&
    eventId === undefined &&
    feedPostId === undefined
  ) {
    return;
  }

  let name: string;
  let id: string;
  let url = window.location.href;
  let eventName:
    | "PlaceViewed"
    | "EventViewed"
    | "FeedPostViewed"
    | "TourViewed"
    | undefined;

  if (tourId !== undefined) {
    const showTourNode = $("#show_tour")[0];
    eventName = "TourViewed";
    id = tourId;
    name = showTourNode.getAttribute("data-name") ?? "";
  } else if (placeId !== undefined) {
    const showEventNode = $("#show_place")[0];
    eventName = "PlaceViewed";
    id = placeId;
    name = showEventNode.getAttribute("data-name") ?? "";
  } else if (eventId !== undefined) {
    const showEventNode = $("#show_event")[0];
    id = eventId;
    eventName = "EventViewed";
    name = showEventNode.getAttribute("data-name") ?? "";
  } else if (feedPostId !== undefined) {
    eventName = "FeedPostViewed";
    id = feedPostId;
  }

  VisitWidget.Analytics.createEntityView(eventName, url, id, name);
};

export { createEntityViewAnalytics };
