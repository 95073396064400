/* eslint-disable */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import useScript from "react-script-hook";

const script = `https://www.google.com/recaptcha/api.js?render=explicit&hl=${I18n.locale}`;

const ReCaptcha = forwardRef<{}, any>((props, ref) => {
  const [loading, error] = useScript({ src: script });
  const captchaRef = useRef();
  const captchaInstanceRef = useRef<{
    reset: () => void;
    getValue: () => string;
  }>();

  useImperativeHandle(ref, () => ({
    reset() {
      if (captchaRef.current) {
        const anyWindow = window as any;
        anyWindow.grecaptcha.reset(captchaRef.current);
      }
    },
    getValue() {
      return $(captchaRef.current).find(".g-recaptcha-response").val();
    },
  }));

  useEffect(() => {
    if (!loading && !error) {
      const anyWindow = window as any;
      anyWindow.grecaptcha.ready(() => {
        const instance = anyWindow.grecaptcha.render(captchaRef.current, {
          sitekey: VisitWidget.settings.recaptchaSiteKey,
          hl: I18n.locale,
        });
        captchaInstanceRef.current = instance;
      });
    }
  }, [loading, error, captchaRef]);

  if (error || loading) {
    return <></>;
  }

  return (
    <>
      <div ref={captchaRef} className="g-recaptcha"></div>
    </>
  );
});
export { ReCaptcha };
