/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const CarIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M18.947 5.013C18.74 4.407 18.169 4 17.529 4H6.471C5.831 4 5.26 4.407 5.053 5.013L3 11V19C3 19.552 3.448 20 4 20H5C5.552 20 6 19.552 6 19V18H18V19C18 19.552 18.448 20 19 20H20C20.552 20 21 19.552 21 19V11L18.947 5.013ZM6.829 6H17.172L18.543 10H5.457L6.829 6ZM6.5 15C5.672 15 5 14.328 5 13.5C5 12.672 5.672 12 6.5 12C7.328 12 8 12.672 8 13.5C8 14.328 7.328 15 6.5 15ZM17.5 15C16.672 15 16 14.328 16 13.5C16 12.672 16.672 12 17.5 12C18.328 12 19 12.672 19 13.5C19 14.328 18.328 15 17.5 15Z" />
  </svg>
);

export { CarIcon };
