import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { Plan } from "../../../lib/apiTypes";
import { selectPlan } from "../../../store/plan/planSelectors";
import {
  getTour,
  adaptTourDtoToPlan,
  getPlan,
  adaptPlanDtoToPlan,
} from "../../../store/visitWidgetApi";
import { UserSharePlanScreen } from "./UserSharePlanScreen";
import { selectIsMapReady } from "../../../store/map/mapSelectors";

const UserSharePlanModalLoader: FunctionComponent<UserSharePlanModalProps> = ({
  tourId,
  planId,
  accessToken,
}) => {
  const isMapReady = useSelector(selectIsMapReady());
  const [planData, setPlanData] = useState<{
    plan: Plan;
    type: "tour" | "plan";
  } | null>(null);
  const userPlan = useSelector(selectPlan());

  useEffect(() => {
    (async () => {
      let plan: Plan;
      let type = "tour" as "tour" | "plan";
      if (tourId && accessToken) {
        const tour = await getTour(tourId, accessToken);
        plan = adaptTourDtoToPlan(tour);
      } else if (planId) {
        const planToShare = await getPlan(planId);
        plan = adaptPlanDtoToPlan(planToShare);
        type = "plan";
      }

      setPlanData({ plan: plan, type: type });
    })();
  }, [tourId, accessToken, planId]);

  // wait for shared plan and user's plan to load first
  return (
    <>
      {!!planData && !!userPlan && isMapReady && (
        <UserSharePlanScreen
          plan={planData.plan}
          type={planData.type}
          planToAddTo={userPlan}
        />
      )}
    </>
  );
};

export { UserSharePlanModalLoader };

interface UserSharePlanModalProps {
  tourId?: number;
  accessToken?: string;
  planId?: number;
  planToAddTo?: Plan;
}

const UserSharePlanModal = withReduxProvider(UserSharePlanModalLoader);

export { UserSharePlanModal, UserSharePlanModalProps };
