import assignMarkerAttributesToCompiledLocation from "../commands/assignMarkerAttributesToCompiledLocation";
import getEventDateText from "../../events/queries/getEventDateText";
import {
  CompiledLocation,
  CompiledLocationsHash,
  Entity,
} from "../../lib/types";

function setEntityAttributes(entity: Entity): void {
  entity.path = `/${entity.type.toLowerCase()}s/${entity.slug}`;
  if (entity.type === "Event") {
    entity["dateText"] = getEventDateText(entity);
  }
}

function getCompiledLocationHash(
  entities: Array<Entity>,
): CompiledLocationsHash {
  const compiledLocationsHash = {};

  entities.forEach((entity) => {
    const markerId = entity.location.marker_id;
    setEntityAttributes(entity);
    if (compiledLocationsHash[markerId]) {
      compiledLocationsHash[markerId].entities.push(entity);
    } else {
      compiledLocationsHash[markerId] = {
        entities: [entity],
        id: markerId,
        lat: entity.location.latitude,
        lng: entity.location.longitude,
        markerId,
        marker_title: entity.name,
        street_address: entity.location.street_address,
      };
    }
  });

  return compiledLocationsHash;
}

const getCompiledLocations = async (
  entities: Array<Entity>,
  useMarkerWithLabel: boolean,
  shouldUseSmallMarkers: boolean,
): Promise<Array<CompiledLocation>> => {
  entities = entities.filter((entity) => entity.location);
  const compiledLocationsHash = getCompiledLocationHash(entities);
  const compiledLocations: Array<CompiledLocation> = Object.values(
    compiledLocationsHash,
  );

  const promises = compiledLocations.map((compiledLocation) => {
    return assignMarkerAttributesToCompiledLocation(
      compiledLocation,
      useMarkerWithLabel,
      shouldUseSmallMarkers,
    );
  });

  await Promise.all(promises);

  return compiledLocations;
};

export default getCompiledLocations;
