VisitWidget.Goal = class Goal {
  static query(callback) {
    const parameters = {} as Record<string, any>;
    parameters["cache_version"] = VisitWidget.settings.goalsCacheVersion;
    if (VisitWidget.settings.adminModeOn) {
      parameters["adminMode"] = `${
        VisitWidget.store.currentUserId
      }-${Date.now()}`;
    }

    parameters.locale = I18n.locale;

    return $.ajax({
      url: `/clients/${VisitWidget.settings.currentClientId}/goals`,
      type: "GET",
      data: parameters,
      success(goals) {
        return callback(goals);
      },
    });
  }

  static getPlanItems(goalId, callback) {
    const parameters = {} as Record<string, any>;
    parameters.locale = I18n.locale;
    this.addCacheParameters(parameters);
    return $.ajax({
      url:
        `/clients/${VisitWidget.settings.currentClientId}/goals/` +
        `\
${goalId}/plan_items`,
      type: "GET",
      data: parameters,
      success(planItems) {
        return callback(planItems);
      },
    });
  }

  static updatePositions(parameters, callback) {
    return $.ajax({
      url: "/goals/update_positions",
      type: "PATCH",
      data: parameters,
      success() {
        if (callback != null) {
          return callback();
        }
      },
    });
  }

  static update(goal_id, parameters, callback) {
    return $.ajax({
      url: `/goals/${goal_id}`,
      type: "PATCH",
      data: {
        goal: parameters,
      },
      success(goal) {
        if (callback != null) {
          return callback(goal);
        }
      },
    });
  }

  static addCacheParameters(parameters) {
    parameters["cache_version"] = VisitWidget.settings.toursCacheVersion;
    if (VisitWidget.settings.adminModeOn) {
      return (parameters["adminMode"] = `${
        VisitWidget.store.currentUserId
      }-${Date.now()}`);
    }
  }
};
