/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const ChevronLeftIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6739 12L16.102 6.57196C16.6901 5.98384 16.6901 5.02921 16.102 4.44109C15.5139 3.85297 14.5592 3.85297 13.9711 4.44109L7.41655 10.9957C6.86111 11.5511 6.86111 12.4503 7.41655 13.0044L13.9711 19.5589C14.5592 20.147 15.5139 20.147 16.102 19.5589C16.6901 18.9708 16.6901 18.0162 16.102 17.428L10.6739 12Z" />
  </svg>
);

export { ChevronLeftIcon };
