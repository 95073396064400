import { listToursAction } from "../../../store/plan/actions/listToursAction";
import { setFlyoutAction } from "../../../store/flyout/actions/setFlyoutAction";
import { SharedPlanScreen } from "../../../components/plans/SharedPlanScreen";
import { addSharedPlan } from "src/store/visitWidgetApi";

VisitWidget.CreateModal = class CreateModal {
  category_ids: any[];
  $container: JQuery<HTMLElement>;
  stepByStepControl: any;
  selectionControl: any;
  createAdImageUploadControl: any;
  constructor() {
    this.onChoose = this.onChoose.bind(this);
    this.onCategoryAdded = this.onCategoryAdded.bind(this);
    this.onCategoryRemoved = this.onCategoryRemoved.bind(this);
    this.onReset = this.onReset.bind(this);
    this.category_ids = [];
  }

  initialize() {
    this.$container = $(".modal.create");
    this.stepByStepControl = new VisitWidget.StepByStepControl(
      this.$container,
      this.onChoose,
      this.onReset,
    );
    this.createModal();
    this.selectionControl = new VisitWidget.CategorySelectionControl(
      ".create.modal",
      true,
      this.onCategoryAdded,
      this.onCategoryRemoved,
    );
    this.selectionControl.initialize();
    return this.setupFormAjaxCallback();
  }

  reset() {
    return this.stepByStepControl.reset();
  }

  getActiveContainer() {
    return this.stepByStepControl.getActiveContainer();
  }

  getEntity() {
    return this.stepByStepControl.entity;
  }

  onChoose() {
    if (VisitWidget.settings.adsOn) {
      this.setupImageUploadingForAds();
    }
    return this.setDateTimesToFormInput();
  }

  createModal() {
    this.stepByStepControl.initialize();

    this.$container.on("click", ".entity-date.dropdown", (event) => {
      const $target = $(event.target).closest(".entity-date.dropdown");
      const $datepicker = $target.find(".datepicker");

      if ($datepicker.children().length > 0) {
        return $datepicker.show();
      } else {
        return $datepicker.datepicker({
          onSelect: (e, ui) => {
            const day = ui.selectedDay;
            const month = ui.selectedMonth + 1;
            const year = ui.selectedYear;
            $target.removeClass("open clicked");
            const dateText = month + "/" + day + "/" + year;

            if (this.validateEventDate(dateText)) {
              $target.find(".display-date").text(dateText);
              this.setDateTimesToFormInput();
              $datepicker.hide();
            }
            return true;
          },
        });
      }
    });

    this.$container.find(".category_list").slimScroll({ position: "right" });

    this.$container.find(".eventStartTime.dropdown").timepicker({ step: 15 });
    this.$container.find(".eventEndTime.dropdown").timepicker({ step: 15 });
    this.resetEventTimeControls();
    this.resetDateControls();

    this.$container
      .find(".eventStartTime.dropdown")
      .on("changeTime", (event) => {
        const $target = $(event.target);
        const startTime = $(event.target).data("ui-timepicker-value");
        $target.find("span.value").text(startTime);
        this.getActiveContainer()
          .find(".eventEndTime.dropdown")
          .timepicker("option", {
            minTime: startTime,
            maxTime: "11:30pm",
            step: 15,
          });
        return this.setDateTimesToFormInput();
      });

    this.$container.find(".eventEndTime.dropdown").on("changeTime", (event) => {
      const $target = $(event.target);
      const endTime = $target.data("ui-timepicker-value");
      const startDate = this.getActiveContainer()
        .find(".entity-date.dropdown span")
        .text();
      $target.removeClass("inactive");
      $target.find("span.value").text(endTime);
      return this.setDateTimesToFormInput(true);
    });

    this.$container.on("click", ".select-all", () => {
      return this.selectionControl.selectAll();
    });

    this.$container.on("click", ".save", () => {
      if (this.category_ids.length === 0) {
        alert("You must select at least one category.");
        return;
      }
      this.$container
        .find(`#${this.getEntity()}_category_ids`)
        .val(this.category_ids.join(","));
      this.$container.find(`#new_${this.getEntity()} .entity_address`).val();
      this.$container.find(".save").hide();

      if (this.getActiveContainer()[0].className.indexOf("shared_plan") > 0) {
        this.submitSharedPlan();
        return;
      }

      return this.getActiveContainer().find(".submit_btn").click();
    });
  }

  // a slight hack, but all this will be removed when the create modals are turned to react
  async submitSharedPlan() {
    const categoryIds = this.category_ids.join(",");
    const name = $("#shared_plan_name").val() as string;
    const notes = $("#shared_plan_note_body").val() as string;
    const requestBody = {
      shared_plan: {
        category_ids: categoryIds,
        type: "SharedPlan",
        name: name,
        description: "shared plan",
        notes_attributes: [{ body: notes }],
      },
    } as any;
    const response = await addSharedPlan(requestBody);
    const data = await response.json();

    this.$container.find(".save").show();
    if (!response.ok) {
      alert("Error creating Shared Plan");
      return;
    }

    VisitWidget.modal.close(this.$container);
    (window as any).globalDispatch(
      listToursAction(VisitWidget.store.categoryIds, true) as any,
    );
    (window as any).globalDispatch(
      setFlyoutAction(SharedPlanScreen, {
        tourId: data.id,
      }),
    );
    VisitWidget.navigationController.navigateToMenuItemByEntity("SharedPlan");
  }

  setupImageUploadingForAds() {
    this.createAdImageUploadControl = new VisitWidget.CreateImageUploadControl(
      ".create_entity_container.ad",
      "ad",
      "image",
      true,
    );
    this.createAdImageUploadControl.initialize();
    return this.getActiveContainer().find("#ad_image").val("");
  }

  setupFormAjaxCallback() {
    this.$container.find;

    const self = this;
    return this.$container
      .find(".create_entity_container form")
      .on("ajax:complete", (event, data, status) => {
        this.$container.find(".save").show();
        const json = data.responseJSON;
        if (status === "error") {
          alert(json["errors"].join(", "));
          return;
        }

        VisitWidget.modal.close(this.$container);
        if (self.stepByStepControl.entity === "shared_plan") {
          (window as any).globalDispatch(
            listToursAction(VisitWidget.store.categoryIds, true) as any,
          );
          (window as any).globalDispatch(
            setFlyoutAction(SharedPlanScreen, {
              tourId: data.responseJSON.id,
            }),
          );
          VisitWidget.navigationController.navigateToMenuItemByEntity(
            "SharedPlan",
          );

          return;
        }

        VisitWidget.flyoutLoader.navigateTo(json["path"]);

        if (this.getEntity() === "place") {
          return VisitWidget.EventBus.publish(
            new VisitWidget.Events.PlaceCreated(),
          );
        } else if (this.getEntity() === "event") {
          return VisitWidget.EventBus.publish(
            new VisitWidget.Events.EventCreated(),
          );
        } else if (this.getEntity() === "tour") {
          return VisitWidget.EventBus.publish(
            new VisitWidget.Events.TourCreated(),
          );
        } else if (this.getEntity() === "shared_plan") {
          return VisitWidget.EventBus.publish(
            new VisitWidget.Events.SharedPlanCreated(),
          );
        }
      });
  }

  setDateTimesToFormInput(setEndTime = null) {
    let formattedStartsOn;
    const startTime = this.getActiveContainer()
      .find(".eventStartTime.dropdown")
      .data("ui-timepicker-value");
    const startDate = this.getActiveContainer()
      .find(".entity-date.starts-on-date span")
      .text();

    if (typeof startTime !== "undefined") {
      formattedStartsOn = moment(
        startDate + " " + startTime,
        "M/D/YYYY h:mm a Z",
      ).format("LLL");
    } else {
      formattedStartsOn = moment(startDate, "M/D/YYYY").format("ll");
    }

    this.getActiveContainer().find(".starts-on-input").val(formattedStartsOn);

    let formattedEndsOn = null;

    if (
      setEndTime != null ||
      (this.$container.find("#event_ends_on").val() as any[]).length > 0
    ) {
      const endTime = $(".eventEndTime.dropdown").data("ui-timepicker-value");
      formattedEndsOn = moment(
        startDate + " " + endTime,
        "M/D/YYYY h:mm a Z",
      ).format("LLL");
    } else if (this.getActiveContainer().find(".ends-on-date").length > 0) {
      const endDate = this.getActiveContainer()
        .find(".entity-date.ends-on-date span")
        .text();
      formattedEndsOn = moment(endDate, "M/D/YYYY").format("ll");
    }

    if (formattedEndsOn !== null) {
      return this.getActiveContainer()
        .find(".ends-on-input")
        .val(formattedEndsOn);
    }
  }

  resetEventTimeControls() {
    const coeff = 1000 * 60 * 30;
    const date = new Date();
    const roundedDate = new Date(Math.round(date.getTime() / coeff) * coeff);
    this.$container
      .find(".eventStartTime.dropdown")
      .timepicker("setTime", roundedDate);
    this.$container
      .find(".eventStartTime.dropdown")
      .timepicker("option", { minTime: roundedDate });
    this.$container
      .find("#event_starts_on_time")
      .val($(".eventStartTime.dropdown").data("ui-timepicker-value"));
    this.$container
      .find(".eventStartTime.dropdown span.value")
      .text($(".eventStartTime.dropdown").data("ui-timepicker-value"));
    return this.$container.find(".eventEndTime.dropdown").timepicker("option", {
      minTime: $(".eventStartTime.dropdown").data("ui-timepicker-value"),
      maxTime: "11:30pm",
      className: "eventEndTime",
    });
  }

  validateEventDate(dateText) {
    const coeff = 1000 * 60 * 30;
    const roundedDate = new Date(
      Math.round(new Date().getTime() / coeff) * coeff,
    );
    const date = moment(dateText, "M/D/YYYY");

    if (date.isAfter(roundedDate) || date.isSame(roundedDate, "day")) {
      if (this.getEntity() === "event") {
        this.resetTimePickerAfterDateSelection(date, roundedDate);
      }
      return true;
    } else {
      return false;
    }
  }

  resetTimePickerAfterDateSelection(date, roundedDate) {
    if (date.isSame(roundedDate, "day")) {
      return this.$container
        .find(".eventStartTime.dropdown")
        .timepicker("option", { minTime: roundedDate });
    } else if (date.isAfter(roundedDate)) {
      return this.$container
        .find(".eventStartTime.dropdown")
        .timepicker("option", { minTime: null });
    }
  }

  onCategoryAdded($listItem) {
    const category_id = $listItem.data("id");
    return this.category_ids.push(category_id);
  }

  onCategoryRemoved($listItem) {
    const category_id = $listItem.data("id");
    const index = this.category_ids.indexOf(category_id);
    if (index > -1) {
      return this.category_ids.splice(index, 1);
    }
  }

  resetDateControls() {
    this.$container
      .find(".entity-date:not(.ends-on-date) .display-date")
      .text(moment().format("l"));
    return this.$container.find(".ends-on-date span").text("");
  }

  onReset() {
    this.category_ids = [];
    this.resetEventTimeControls();
    this.resetDateControls();

    this.$container.find(".category_list ul li").addClass("active");
    this.$container.find(".category_list ul li").each(function () {
      return VisitWidget.global.makeCategoryInactive($(this));
    });

    if (this.createAdImageUploadControl != null) {
      return this.createAdImageUploadControl.reset();
    }
  }
};
