const cacheBreak = () => {
  return `cache=${new Date().getTime()}`;
};

VisitWidget.Tour = class Tour {
  static query(parameters, callback) {
    this.addCacheParameters(parameters);
    if (VisitWidget.settings.adminModeOn) {
      parameters["view_unpublished"] = true;
    }

    parameters.locale = I18n.locale;

    return $.ajax({
      url: `/clients/${
        VisitWidget.settings.currentClientId
      }/tours?${cacheBreak()}`,
      type: "GET",
      data: parameters,
      success(toursHtml) {
        return callback(toursHtml);
      },
    });
  }

  static getPlanItems(tourId, callback) {
    const parameters = {} as Record<string, any>;
    this.addCacheParameters(parameters);
    parameters.locale = I18n.locale;
    return $.ajax({
      url: `/clients/${
        VisitWidget.settings.currentClientId
      }/tours/${tourId}/plan_items?${cacheBreak()}`,
      type: "GET",
      data: parameters,
      success(planItems) {
        return callback(planItems);
      },
    });
  }

  static getDirectionsData(tourId, callback) {
    const parameters = {};
    this.addCacheParameters(parameters);
    return $.ajax({
      url: `/clients/${VisitWidget.settings.currentClientId}/tours/${tourId}/directions_data`,
      type: "GET",
      data: parameters,
      success(directionsData) {
        return callback(directionsData);
      },
    });
  }

  static updatePositions(parameters, callback) {
    return $.ajax({
      url: `/tours/update_positions?${cacheBreak()}`,
      type: "PATCH",
      data: parameters,
      success() {
        if (callback != null) {
          return callback();
        }
      },
    });
  }

  static update(tour_id, parameters, callback) {
    return $.ajax({
      url: `/tours/${tour_id}?${cacheBreak()}`,
      type: "PATCH",
      data: {
        tour: parameters,
      },
      success(tour) {
        if (callback != null) {
          return callback(tour);
        }
      },
    });
  }

  static addCacheParameters(parameters) {
    parameters["cache_version"] = VisitWidget.settings.toursCacheVersion;
    if (VisitWidget.settings.adminModeOn) {
      return (parameters["adminMode"] = `${
        VisitWidget.store.currentUserId
      }-${Date.now()}`);
    }
  }
};
