/* eslint-disable */
/* tslint-disable */

import { getCookie, CookieKeys } from "../lib/cookies";
import { getQueryStringValue, getQueryStringValues } from "../lib/url";

interface MenuItem {
  id: number;
  active_icon_1x_url: null | string;
  active_icon_2x_url: null | string;
  active_icon_3x_url: null | string;
  active_icon_big_button_1x_url: null | string;
  active_icon_big_button_2x_url: null | string;
  active_icon_big_button_3x_url: null | string;
  analytics_path: string;
  built_in: boolean;
  category_filter_enabled: boolean;
  category_ids: number[];
  created_at: string;
  entity: string;
  inactive_icon_1x_url: null | string;
  inactive_icon_2x_url: null | string;
  inactive_icon_3x_url: null | string;
  inactive_icon_big_button_1x_url: null | string;
  inactive_icon_big_button_2x_url: null | string;
  inactive_icon_big_button_3x_url: null | string;
  include_all_tour_types_enabled: boolean;
  map_layer_kml_url: null | string;
  name: string;
  position: number;
  updated_at: string;
  active_category_ids: number[];
}

VisitWidget.Global = class Global {
  segmentedViewCategoryIds: any;
  initialize() {
    const self = this;

    self.header();
    self.dropdown();
    self.datePicker();
    self.timePicker();
    self.setFriendlyDateTimes();

    $("textarea").each(function () {
      const maxlength = $(this).attr("maxlength");
      $(this).maxlength({ max: maxlength, showFeedback: false });
    });

    return this;
  }

  header() {
    // do nothing
  }

  datePicker() {
    // This is really just needed to hide the calendar when clicking away
    // from the event flyout start or end date calendar.
    // Not currently sure why this is needed.
    // TODO Find a more elegant solution to this.
    $("body").on("click", (e) => {
      if (this.isTargetWithinDatepicker($(e.target))) {
        return false;
      } else {
        $("#show_event .ui-datepicker")
          .parents(".hasDatepicker")
          .removeClass("hasDatepicker");
        $("#show_event .ui-datepicker").remove();
      }
    });
  }

  isTargetWithinDatepicker($target) {
    return (
      $target.parents(".ui-datepicker").length > 0 ||
      $target.parents(".ui-datepicker-header").length > 0 ||
      $target.hasClass("hasDatepicker") ||
      $target.parents(".hasDatepicker").length > 0
    );
  }

  timePicker() {
    $("body").on("click", function (e) {
      if (
        $(e.target).parents(".ui-timepicker-wrapper").length > 0 ||
        $(e.target).hasClass(".ui-timepicker-wrapper")
      ) {
        return $(".ui-timepicker-wrapper").hide();
      }
    });
  }

  dropdown() {
    VisitWidget.dropdownControl = new VisitWidget.DropdownControl();
    return VisitWidget.dropdownControl.initialize();
  }

  makeCategoryInactive($category) {
    $category.removeClass("active").css("background", "");
    return $category.find(".category_ico").removeClass("white");
  }

  setFriendlyDateTimes() {
    $(".friendly-datetime").each(function () {
      $(this).html(
        moment.parseZone($(this).data("datetime")).format("ddd, M/D/YY h:mma"),
      );
    });

    $(".friendly-date").each(function () {
      $(this).html(
        moment.parseZone($(this).data("datetime")).format("ddd, M/D/YY"),
      );
    });

    return $(".friendly-time").each(function () {
      $(this).html(moment.parseZone($(this).data("datetime")).format("h:mma"));
    });
  }

  stopEventPropagation(event) {
    if (event && event.stopPropagation) {
      return event.stopPropagation();
    } else if (window.event) {
      return (window.event.cancelBubble = true);
    }
  }

  planId() {
    return VisitWidget.store.currentPlanId;
  }

  getPlanItemId(plannableId, plannableType) {
    const planItem = this.getPlanItem(plannableId, plannableType) as any;
    if (planItem !== null && planItem !== undefined) {
      return planItem.id;
    } else {
      return null;
    }
  }

  getPlanItem(plannableId, plannableType) {
    for (let planItem of VisitWidget.store.planItems) {
      if (
        planItem.plannable_id === plannableId &&
        planItem.plannable_type === plannableType
      ) {
        return planItem;
      }
    }
    return null;
  }

  getPlanItemIdFromGlobalEditMode(plannableId, plannableType) {
    for (let planItem of Array.from(
      VisitWidget.store.globalEditMode.entityPlanItems,
    ) as any[]) {
      if (
        planItem.plannable_id === plannableId &&
        planItem.plannable_type === plannableType
      ) {
        return planItem.id;
      }
    }
    return null;
  }

  getPlanItemCount() {
    return VisitWidget.store.planItems.length;
  }

  currentListPage() {
    const controller = this.currentListController();
    if (
      typeof controller !== "undefined" &&
      typeof controller.page !== "undefined"
    ) {
      return controller.page();
    } else {
      return 1;
    }
  }

  currentListType() {
    return VisitWidget.store.currentMenuItem.entity;
  }

  currentListController() {
    let entity = this.currentListType();
    entity = entity.charAt(0).toLowerCase() + entity.slice(1);
    let controllerName = `${entity}ListController`;
    controllerName += this.currentMenuItemId().toString();
    return VisitWidget[controllerName];
  }

  currentMenuItemId() {
    return VisitWidget.store.currentMenuItem.id;
  }

  currentListCategoryIds(includeNullIfEmpty) {
    if (includeNullIfEmpty == null) {
      includeNullIfEmpty = false;
    }
    return this.getCategoryIds(
      VisitWidget.store.currentMenuItem,
      includeNullIfEmpty,
    );
  }

  // eslint-disable-next-line complexity
  getCategoryIds = async (menuItem: MenuItem, includeNullIfEmpty: boolean) => {
    let ids;
    if (includeNullIfEmpty === null || includeNullIfEmpty === undefined) {
      includeNullIfEmpty = false;
    }
    if (
      VisitWidget.settings.shouldUseSegmentedViewCategories &&
      this.isMenuItemTargetOfSegmentedView(menuItem)
    ) {
      ids = this.getSegmentedViewCategoryIds(menuItem);
    } else if (menuItem.built_in === true) {
      const cookieCategoryIds = await getCookie(
        CookieKeys.categoryIds,
        undefined,
        JSON.parse,
      );

      if (
        cookieCategoryIds !== undefined &&
        !VisitWidget.settings.disableCategoryFilter &&
        VisitWidget.store.currentMenuItem.category_filter_enabled
      ) {
        ids = cookieCategoryIds;
      } else {
        ids = VisitWidget.store.categoryIds;
      }
    } else if (menuItem.entity === "FeedPost") {
      ids = VisitWidget.store.categoryIds;
    } else {
      ids = menuItem.active_category_ids;
    }

    ids.sort((a, b) => a - b);

    if (ids.length === 0 && includeNullIfEmpty) {
      // hack to pass in 0 categories via ajax
      ids = [null];
    }

    const result = ids.filter((id) => {
      return VisitWidget.store.exclusiveCategoryIds.indexOf(id) < 0;
    });
    return result;
  };

  getSegmentedViewCategoryIds(menuItem: MenuItem) {
    if (typeof this.segmentedViewCategoryIds !== "undefined") {
      return this.segmentedViewCategoryIds;
    }
    const queryStringCategoryIds = this.getQueryStringCategoryIds(menuItem);
    if (queryStringCategoryIds.length > 0) {
      this.segmentedViewCategoryIds = queryStringCategoryIds;
    } else {
      this.segmentedViewCategoryIds = VisitWidget.store.categoryIds;
    }
    return this.segmentedViewCategoryIds;
  }

  getQueryStringCategoryIds(menuItem: MenuItem): number[] {
    const queryStringCategoryIds = getQueryStringValues("category_ids[]").map(
      (x) => Number(x),
    );
    // keep only the query string values that are valid for the menu item
    let filteredCategoryIds = menuItem.category_ids.filter((x) =>
      queryStringCategoryIds.includes(x),
    );
    if (filteredCategoryIds.length == 0) {
      // handle garbage data, in case the query string values have no overlap with actual menu item
      filteredCategoryIds = menuItem.category_ids;
    }
    return filteredCategoryIds;
  }

  isMenuItemTargetOfSegmentedView(menuItem) {
    return (
      VisitWidget.settings.isSegmentedView &&
      parseInt(getQueryStringValue("menu_item_id")) === menuItem.id
    );
  }

  isACustomListActive() {
    return VisitWidget.store.currentMenuItem.built_in === false;
  }

  isAndroid() {
    const userAgent = VisitWidget.lib.getUserAgent();
    return /android/i.test(userAgent) && !this.isWindows();
  }

  isIOS() {
    const userAgent = VisitWidget.lib.getUserAgent();
    return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
  }

  isWindows() {
    const userAgent = VisitWidget.lib.getUserAgent();
    return /windows phone/i.test(userAgent);
  }

  currentPlanName() {
    let name = $("#sidebar_plan .best_in_place[data-bip-attribute=name]").data(
      "bip-value",
    );
    if (name === "" || typeof name === "undefined") {
      name = null;
    }
    return name;
  }

  browserHistorySupport() {
    return (
      window.history !== undefined && window.history.pushState !== undefined
    );
  }

  isAppBannerOpen() {
    return $(".smartbanner").length > 0;
  }

  shouldDisableMainSidebar(menuItem) {
    return VisitWidget.settings.disableLists && menuItem.entity !== "PlanItem";
  }

  getBeginningOfDayMoment() {
    const m = moment();
    m.hours(0);
    m.minutes(0);
    m.seconds(0);
    return m;
  }
};

VisitWidget.global = new VisitWidget.Global();

$(document).ready(() => VisitWidget.global.initialize());
