/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
import React, { FunctionComponent, useState } from "react";
import {
  CarIcon,
  AddCircleIcon,
  MapItCircleIcon,
  RemoveCircleIcon,
} from "../../icons";
import "./PlanItem.scss";
import { PlanNotesModal } from "../PlanNotesModal";
import { UserPlanAggregate } from "../../../store/apiTypes";
import { useDispatch, useSelector } from "react-redux";
import { PlanItem as PlanItemType } from "../../../lib/apiTypes";
import { addPlanItemAction } from "../../../store/plan/actions/addPlanItemAction";
import { removePlanItemAction } from "../../../store/plan/actions/removePlanItemAction";
import { selectPlanItemInPlan } from "../../../store/plan/planSelectors";
import { formatTime } from "../../../lib/formatTime";
import { showMapItLocation } from "../../../legacy/controllers/commands/showMapItLocation";
import { Button } from "../../../components/Button";
import { clientTheme } from "../../../components/ConfigProvider";

/**
 * Individual plan item for display in a list.
 */
const PlanItem: FunctionComponent<PlanItemProps> = ({
  planItem,
  stopNumber,
  tourId,
  mode = "view",
  selectPlanItemNotes,
}) => {
  const dispatch = useDispatch();
  // user's plan we will be adding and removing from
  const userPlanItem = useSelector(selectPlanItemInPlan(planItem));

  const distanceFormatted =
    planItem.distanceMeters !== undefined
      ? `${(planItem.distanceMeters * 0.000621371).toFixed(1)} mi`
      : "";
  const durationFormatted =
    planItem.durationSeconds !== undefined
      ? formatTime(planItem.durationSeconds / 60)
      : "";

  // state for modal popup
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const handleAddPlanItemPress = () => {
    dispatch(
      addPlanItemAction(
        planItem.originalDto.plannable_id,
        planItem.originalDto.plannable_type,
      ) as any,
    );
  };

  const handleRemovePlanItemPress = () => {
    dispatch(
      removePlanItemAction(
        userPlanItem.id,
        planItem.originalDto.plannable_id,
        planItem.originalDto.plannable_type,
      ) as any,
    );
  };

  const handleMapIt = async (event) => {
    if (event) {
      event.stopPropagation();
    }

    showMapItLocation(
      planItem.originalDto.plannable.location.marker_id,
      planItem.originalDto.plannable_id,
      planItem.originalDto.plannable_type,
    );
  };

  return (
    <>
      <PlanNotesModal
        onClose={() => {
          setIsNotesOpen(false);
        }}
        planItemId={planItem.id}
        tourId={tourId}
        title={planItem.title}
        coverPhotoUrl={planItem.coverPhotoUrl}
        notes={planItem.notes}
        isEditMode={mode === "edit"}
        open={isNotesOpen}
        type="planItem"
      />
      <div className="plan-item-container">
        <div className="plan-item-cover-photo">
          <div>
            <div
              className="plan-item-stop-number"
              style={{ backgroundColor: clientTheme.colorPrimary }}>
              {stopNumber}
            </div>
            <div
              className="plan-item-cover-photo-image"
              style={{
                backgroundImage: `url(${planItem.coverPhotoUrl}`,
              }}></div>
          </div>
        </div>

        <div className="plan-item-details">
          <div className="plan-item-title">{planItem.title}</div>
          <div className="plan-item-category">{planItem.categoryName}</div>
          {!!planItem.address && (
            <div className="plan-item-address">{planItem.address}</div>
          )}
          {(!!planItem.notes || mode === "edit") && (
            <div className="plan-item-notes">
              <Button
                type="pillOutline"
                onPress={() => {
                  if (selectPlanItemNotes) {
                    selectPlanItemNotes(planItem);
                  } else {
                    setIsNotesOpen(true);
                  }
                }}>
                Notes For You
              </Button>
            </div>
          )}
        </div>

        <div className="plan-item-actions">
          <div className="plan-item-action-buttons">
            {mode !== "summary" && (
              <Button
                type="icon"
                icon={<MapItCircleIcon />}
                onPress={(event) => {
                  handleMapIt(event);
                }}
              />
            )}
            {!userPlanItem && (
              <Button
                type="icon"
                icon={<AddCircleIcon />}
                onPress={handleAddPlanItemPress}
              />
            )}
            {!!userPlanItem && (
              <Button
                type="icon"
                icon={<RemoveCircleIcon />}
                onPress={handleRemovePlanItemPress}
              />
            )}
          </div>
          {durationFormatted !== "" && (
            <div className="plan-item-drive-time">
              <span className="plan-item-drive-time-icon">
                <CarIcon size={14} />
              </span>
              {durationFormatted}
            </div>
          )}
          {distanceFormatted !== "" && (
            <div className="plan-item-distance">{distanceFormatted}</div>
          )}
        </div>
      </div>
    </>
  );
};

interface PlanItemProps {
  tourId?: number;
  planItem: PlanItemType & {
    distanceMeters?: number;
    durationSeconds?: number;
  };
  stopNumber: number;
  /**
   * Summary: User modal, can only add remove to own plan.
   * View: Admin viewing plan item.
   * Edit: Admin editing plan item.
   */
  mode?: "summary" | "view" | "edit";
  planToAddTo?: UserPlanAggregate;
  selectPlanItemNotes?: (planItem: PlanItemType) => void;
}

export { PlanItem };
