/* tslint:disable */
/* eslint:disable */

import { getQueryStringValue } from "../../../lib/url";
import { CookieKeys, setCookie } from "../../../lib/cookies";
import { globalDispatch } from "../../../store/store";
import { refreshSessionAction } from "../../../store/auth/actions/refreshSessionAction";

VisitWidget.RegistrationModal = class RegistrationModal {
  oneTimeRegistrationTriggerOccurred: boolean;
  triggerCount: number;
  captchaControl: any;

  constructor() {
    this.onRegistrationTrigger = this.onRegistrationTrigger.bind(this);
    this.oneTimeRegistrationTriggerOccurred = false;
    this.triggerCount = 0;
    this.captchaControl = new VisitWidget.CaptchaControl(".modal.registration");
  }

  initialize() {
    this.wireModalEvents();
    return this.adjustForIframe();
  }

  onRegistrationTrigger(event: any) {
    return;
  }

  preventRegistrationTriggerDefaultEvents() {
    $(document).off("click.registration-trigger");
    $(document).off("keydown.registration-trigger");
    $("form.registration-trigger").off("submit");
    return $(document).off("keydown", "input.registration-trigger");
  }

  wireRegistrationTriggerEvents() {}

  registrationTriggerSelector() {
    return ".registration-trigger:not(form):not(input[type=text])";
  }

  adjustForIframe() {
    if (window.self !== window.top) {
      return $(".modal.registration #login_with_facebook").attr(
        "target",
        "_blank",
      );
    }
  }

  setCookieForSubscribedToNewsLetter() {
    const checkedNewsletters = $(
      '[id*="user_subscribed_to_newsletter"]',
    ).filter(function () {
      return $(this).is(":checked");
    });

    let newsletterIds = [];
    for (let i = 0; i < checkedNewsletters.length; ++i) {
      newsletterIds.push($(checkedNewsletters[i]).data("value"));
    }

    setCookie("newsletters", newsletterIds.join(","), 1);
  }

  wireModalEvents() {
    setTimeout(() => {
      // use slight delay to give react time to render
      this.setCookieForSubscribedToNewsLetter();
      $('[id*="user_subscribed_to_newsletter"]').on("change", () => {
        this.setCookieForSubscribedToNewsLetter();
      });
    }, 3000);

    $(".modal.registration #sign-in-view form.new_user").on(
      "submit",
      (event) => {
        event.preventDefault();

        const $submitButton = $(event.target).find("input[type=submit]");
        $submitButton.addClass("pressed");

        $.ajax({
          method: "POST",
          url: "/users/sign_in",
          data: {
            user: {
              email: $("#sign-in-view #new_user_session_user_email").val(),
              password: $(
                "#sign-in-view #new_user_session_user_password",
              ).val(),
            },
          },
          success: () => {
            return this.login();
          },

          error(jqXHR, textStatus, errorThrown) {
            alert(jqXHR.responseText);
            return $submitButton.removeClass("pressed");
          },
        });

        return false;
      },
    );

    $(".modal.registration #sign-up-view form.new_user").on(
      "submit",
      (event) => {
        event.preventDefault();

        const $submitButton = $(event.target).find("input[type=submit]");
        $submitButton.addClass("pressed");

        const user = {
          email: $(".modal.registration #sign-up-view #user_email").val(),
          password: $(".modal.registration #sign-up-view #user_password").val(),
          name: $(".modal.registration #sign-up-view #user_name").val(),
          locale: I18n.locale,
          subscribed_to_newsletter: $(
            ".modal.registration " + "#user_subscribed_to_newsletter",
          ).is(":checked"),
        } as any;
        if (
          $(".modal.registration #sign-up-view #user_postal_code").length > 0
        ) {
          user.postal_code = $(
            ".modal.registration #sign-up-view #user_postal_code",
          ).val();
        }

        $.ajax({
          method: "POST",
          url: "/users",
          data: {
            user,
            "g-recaptcha-response": $(
              "#sign-up-view .g-recaptcha-response",
            ).val(),
          },
        })
          .done(() => {
            return this.login();
          })
          .fail((jqXHR, textStatus, errorThrown) => {
            this.showError(jqXHR);
            this.captchaControl.reset();

            return $submitButton.removeClass("pressed");
          });

        return false;
      },
    );

    $(
      ".modal.registration #password-reset-request-view form.new_user button",
    ).on("click", (event) => {
      event.preventDefault();

      const email = String($("#reset_user_password_user_email").val() ?? "");
      if (email.length === 0) {
        return;
      }

      $.ajax({
        method: "POST",
        url: "/users/password",
        data: {
          user: {
            email,
          },
          "g-recaptcha-response": $(
            "#password-reset-request-view " + ".g-recaptcha-response",
          ).val(),
        },
        success: (x) => {
          this.captchaControl.reset();
          $(".modal.registration #password-reset-request-view .results").html(
            VisitWidget.lib.translate(
              "authentication.forgot_password.success_message",
            ),
          );

          return $("#reset_user_password_user_email").val("");
        },

        error: (jqXHR, textStatus, errorThrown) => {
          this.captchaControl.reset();
          return this.showError(jqXHR);
        },
      });

      return false;
    });

    $(".modal.registration #password-reset-view form.new_user").on(
      "submit",
      (event) => {
        event.preventDefault();

        const password = String(
          $("#edit_user_password_user_password").val() ?? "",
        );
        const passwordConfirmation = $(
          "#edit_user_password_user_password_confirmation",
        ).val();

        if (password.length === 0) {
          return;
        }

        if (password !== passwordConfirmation) {
          alert(
            VisitWidget.lib.translate(
              "authentication.reset_password.passwords_do_not_match_message",
            ),
          );
          return false;
        }

        const $submitButton = $(event.target).find("input[type=submit]");
        $submitButton.addClass("pressed");

        $.ajax({
          method: "PATCH",
          url: "/users/password",
          data: {
            password,
            password_confirmation: passwordConfirmation,
            reset_password_token: getQueryStringValue("reset_password_token"),
          },
          success: (x: { auth_token: string }) => {
            $(".modal.registration #password-reset-view .results").html(
              VisitWidget.lib.translate(
                "authentication.reset_password.success_message",
              ),
            );
            $(
              ".modal.registration #password-reset-view input[type=password]",
            ).val("");

            setCookie(CookieKeys.authToken, x.auth_token).then(() => {
              window.history.pushState({}, "", "/");
              if (VisitWidget.settings.isMobile) {
                (window as Window).location = "/";
              } else {
                globalDispatch(refreshSessionAction() as any);
              }
            });
          },
          error: (jqXHR, textStatus, errorThrown) => {
            this.showError(jqXHR);
            return $submitButton.removeClass("pressed");
          },
        });

        return false;
      },
    );

    if (VisitWidget.settings.isMobile) {
      // Ensure that the Android keyboard does not cover the textbox
      $(".modal.registration input[type=text]").on("focus", (e) => {
        const $label = $(e.target).closest(".control-group").prev("label");
        return $label[0].scrollIntoView();
      });

      $(".modal.registration .open-sign-up").on("click", (event) => {
        event.preventDefault();
        this.showSignUpView();
        return false;
      });
    }

    $(".modal.registration .open-sign-in").on("click", (event) => {
      event.preventDefault();
      this.showSignInView();
      return false;
    });

    // TODO: below can likely be removed
    $(".modal.registration .forgot-password").on("click", (event) => {
      event.preventDefault();
      this.showPasswordResetRequestView();
      return false;
    });
  }

  showError(jqXHR: any) {
    let errorText = "";
    const errors = $.parseJSON(jqXHR.responseText)["errors"];
    for (let error in errors) {
      if (errors.hasOwnProperty(error)) {
        errorText += error + ": " + errors[error];
        errorText += "\n";
      }
    }

    return alert(errorText);
  }

  login() {
    if (getQueryStringValue("reset_password_token") === undefined) {
      return window.location.reload();
    } else {
      return (window.location.href = "/");
    }
  }

  /**
   * Open registration modal one time if user tries to do something where its
   * preferred they are logged in. After triggered, do not open modal again.
   */
  openModalIfTriggerCountReached(
    messageKey: string,
    messageVariables?: string[],
  ) {
    if (VisitWidget.settings.isKiosk) {
      return false;
    }

    this.triggerCount++;
    if (
      this.oneTimeRegistrationTriggerOccurred ||
      !!VisitWidget.store.currentUserId ||
      this.triggerCount <
        VisitWidget.settings.numberOfPlanAdditionsToPromptSignup
    ) {
      return false;
    }

    this.oneTimeRegistrationTriggerOccurred = true;
    this.openModalAction(messageKey, messageVariables);
    return true;
  }

  openModalAction(messageKey: string, messageVariables?: string[]) {
    this.setRegistrationMessages(messageKey, messageVariables);

    $(
      ".modal.registration form.new_user input[type=text], " +
        ".modal.registration form.new_user input[type=password]",
    ).val("");

    if ($("#password-reset-view").length > 0) {
      this.showPasswordResetView();
    } else {
      this.showSignUpView();
    }

    if (VisitWidget.settings.isMobile) {
      $(".modal.registration #sign-in-view .registration-primary-message").text(
        $(
          ".modal.registration #sign-in-view " +
            ".registration-primary-message",
        )
          .text()
          .replace("SIGNIN_OR_SIGNUP", "Sign in"),
      );
      $(".modal.registration #sign-up-view .registration-primary-message").text(
        $(
          ".modal.registration #sign-up-view " +
            ".registration-primary-message",
        )
          .text()
          .replace("SIGNIN_OR_SIGNUP", "Sign up"),
      );
      VisitWidget.slideOutMenuControl.closeFlyout(null, false);
    } else {
      $(".modal.registration .registration-primary-message").text(
        $(".modal.registration .registration-primary-message")
          .text()
          .replace("SIGNIN_OR_SIGNUP", "Sign up"),
      );
    }

    VisitWidget.modal.open($(".modal.registration"));
    // this.captchaControl.render();
  }

  /**
   * @deprecated Use `openModalAction()` instead.
   */
  openModal($trigger: any) {
    const messageKey = $trigger.data("registration-message-key");
    const messageVariables = $trigger.data(
      "registration-primary-message-variables",
    );
    this.openModalAction(messageKey, messageVariables);
  }

  showSignUpView() {
    $(".modal.registration #sign-up-view").show();
    this.resetSubViews();

    if (VisitWidget.settings.isMobile) {
      VisitWidget.Analytics.createPageview("/sign_up");
      $(".modal.registration #sign-in-view").hide();
      $(".modal.registration .selector .open-sign-up").addClass("active");
      return $(".modal.registration .selector .open-sign-in").removeClass(
        "active",
      );
    } else {
      VisitWidget.Analytics.createPageview("/log_in");
      $(".modal.registration #middle-column").show();
      return $(".modal.registration #sign-in-view").show();
    }
  }

  showSignInView() {
    $(".modal.registration #sign-in-view").show();
    this.resetSubViews();

    if (VisitWidget.settings.isMobile) {
      VisitWidget.Analytics.createPageview("/sign_in");
      $(".modal.registration #sign-up-view").hide();
      $(".modal.registration .selector .open-sign-in").addClass("active");
      return $(".modal.registration .selector .open-sign-up").removeClass(
        "active",
      );
    } else {
      $(".modal.registration #sign-up-view").show();
      return $(".modal.registration #middle-column").show();
    }
  }

  resetSubViews() {
    $(".modal.registration #password-reset-request-view").hide();
    $(".modal.registration #password-reset-view").hide();
    return $("#password-reset-view").remove();
  }

  showPasswordResetRequestView() {
    $(".modal.registration #sign-up-view").hide();
    $(".modal.registration #sign-in-view").hide();
    $(".modal.registration #middle-column").hide();
    $(".modal.registration #password-reset-request-view .results").html("");
    return $(".modal.registration #password-reset-request-view").show();
  }

  showPasswordResetView() {
    if (VisitWidget.settings.isMobile) {
      $(".modal.registration .selector .open-sign-in").addClass("active");
      $(".modal.registration .selector .open-sign-up").removeClass("active");
    } else {
      // focus messes up the modal in mobile so only doing it for desktop
      $(".modal.registration #password-reset-view #user_password").focus();
    }

    $(".modal.registration #sign-up-view").hide();
    $(".modal.registration #sign-in-view").hide();
    $(".modal.registration #middle-column").hide();
    $(".modal.registration #password-reset-view .results").html("");
    return $(".modal.registration #password-reset-view").show();
  }

  setRegistrationMessages(messageKey: string, messageVariables?: string[]) {
    const translationParameters = {} as { [key: string]: any };

    if (!!messageVariables) {
      for (let index = 0; index < messageVariables.length; index++) {
        const value = messageVariables[index];
        translationParameters[`parameter${index + 1}`] = value;
      }
    }
    let primaryMessage = VisitWidget.lib.translate(
      "authentication.registration_messages." + messageKey,
      translationParameters,
    );

    if (primaryMessage.length > 60) {
      primaryMessage = primaryMessage.substring(0, 57) + "...?";
    }

    return $(".modal.registration .registration-primary-message").html(
      primaryMessage,
    );
  }

  openPasswordReset() {
    this.openModalAction("sign_in");
  }
};
