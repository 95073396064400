import { Buffer } from "buffer";

function getBase64EncodedString(text: string): string {
  const buffer = Buffer.from(text, "utf8");
  return buffer.toString("base64");
}

export default function getSvgDataUrl(svg: string): string {
  const base64Svg = getBase64EncodedString(svg);
  return `data:image/svg+xml;charset=UTF-8;base64,${base64Svg}`;
}
