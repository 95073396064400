/* eslint-disable complexity */
import React, { FunctionComponent } from "react";
import { formatTime } from "../../../lib/formatTime";
import {
  getDrivingSummaryFromRoutes,
  RoutesResponse,
} from "../../../domain/navigation/queries/getDirectionsForGeoCoordinates";
import "./PlanStopSummary.scss";
import translate from "../../../lib/queries/translate";

const getLocalizedDistance = (distanceInMeters: number) => {
  const distanceUnit = I18n.locale === "en" ? "mi" : "km";
  const result = {
    unit: distanceUnit,
    distance:
      distanceInMeters === undefined
        ? "-"
        : distanceUnit === "km"
        ? (distanceInMeters * 0.001).toFixed(1)
        : (distanceInMeters * 0.0006213712).toFixed(1),
  };

  return result;
};

const PlanStopSummary: FunctionComponent<PlanStopSummaryProps> = ({
  routes,
}) => {
  const { distance, durationMinutes, stopsCount } =
    getDrivingSummaryFromRoutes(routes);

  const formattedStops = stopsCount === undefined ? "-" : stopsCount;
  const distanceLocalized = getLocalizedDistance(distance);

  const formattedTime =
    durationMinutes === undefined ? "-" : formatTime(durationMinutes);

  return (
    <>
      <div className="plan-stop-summary">
        <div className="plan-stop-summary-cell">
          <div className="plan-stop-summary-cell-amount">{formattedStops}</div>
          <div className="plan-stop-summary-cell-description">
            {translate("plan_stop_summary.stops")}
          </div>
        </div>
        <div className="plan-stop-summary-cell">
          <div className="plan-stop-summary-cell-amount">{formattedTime}</div>
          <div className="plan-stop-summary-cell-description">
            {translate("plan_stop_summary.duration")}
          </div>
        </div>
        <div className="plan-stop-summary-cell">
          <div className="plan-stop-summary-cell-amount">
            {distanceLocalized.distance}
          </div>
          <div className="plan-stop-summary-cell-description">
            {translate(
              `common.distance_units.${
                distanceLocalized.unit === "km" ? "km_label" : "miles_label"
              }`,
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface PlanStopSummaryProps {
  routes: RoutesResponse;
}

export { PlanStopSummary, PlanStopSummaryProps };
