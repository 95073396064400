import { Event } from "../../lib/types";
import moment from "moment";

function getStartsOn(event) {
  const currentTime = moment().utcOffset(VisitWidget.settings.utcOffsetMinutes);
  if (currentTime > moment(event.starts_on)) {
    return currentTime;
  } else {
    return moment(event.starts_on).utcOffset(
      VisitWidget.settings.utcOffsetMinutes,
    );
  }
}

function getEndsOnDateText(event, startsOn) {
  if (event.ends_on) {
    const endsOn = moment(event.ends_on).utcOffset(
      VisitWidget.settings.utcOffsetMinutes,
    );
    if (endsOn.format("l") !== startsOn.format("l")) {
      return ` - ${moment(endsOn).format("l")}`;
    }
  }
  return "";
}

export default function getEventDateText(event: Event): string {
  if (!event.starts_on) {
    return "";
  }
  const startsOn = getStartsOn(event);
  let dateText = moment(startsOn).format("l");
  dateText += getEndsOnDateText(event, startsOn);
  return dateText;
}
