import React, { FunctionComponent, useState } from "react";
import { Button } from "../../Button";
import { PlanNotesModal } from "../PlanNotesModal";

/**
 * Show button and open modal for plan/plan item notes. This is ONLY a bridge for non React
 * consumption and should only be used directly from non React code.
 */
const NotesButton: FunctionComponent<NotesButtonProps> = ({
  notes,
  title,
  coverPhotoUrl,
  type,
}) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  if (!notes) {
    return <></>;
  }

  return (
    <>
      <div
        className="shared-plan-screen-plan-notes"
        style={{ paddingTop: type === "planItem" ? "4px" : "0" }}>
        <Button
          type="pillOutline"
          onPress={(event) => {
            event.stopPropagation();
            setIsNotesOpen(true);
          }}>
          {type === "plan" ? "Plan Notes" : "Notes For You"}
        </Button>
      </div>
      <PlanNotesModal
        type={type}
        notes={notes}
        open={isNotesOpen}
        coverPhotoUrl={coverPhotoUrl}
        title={title}
        onClose={() => {
          setIsNotesOpen(false);
        }}
      />
    </>
  );
};

interface NotesButtonProps {
  notes: string;
  title: string;
  coverPhotoUrl: string;
  tourId: number;
  type: "plan" | "planItem";
}

export { NotesButton, NotesButtonProps };
