/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const VideoIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4.001V5H6V7ZM10 15V9L15 12L10 15ZM20 19H18V17H20V19ZM20 15H18V13H20V15ZM20 11H18V9H20V11ZM20 7H18V5H20V7Z"
      fill="currentColor"
    />
  </svg>
);

export { VideoIcon };
