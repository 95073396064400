/* eslint-disable react-func/max-lines-per-function */
import { ProximityMessageSettingFormState } from "./ProximityMessageSetting";
import dayjs from "dayjs";
import { ProximityMessageDto } from "../../../store/apiTypes";
import { isDefined } from "../../../legacy/lib/util";

const formatOpeningHour = (value?: [dayjs.Dayjs, dayjs.Dayjs]) => {
  if (!isDefined(value)) {
    return undefined;
  }
  return [dayjs(value[0]).format("HH:mm"), dayjs(value[1]).format("HH:mm")];
};

const mapFormToUpsertRequest = (
  values: ProximityMessageSettingFormState,
): ProximityMessageDto => {
  const openingHours = {
    day_0: formatOpeningHour(values.triggerOpeningHoursDay0),
    day_1: formatOpeningHour(values.triggerOpeningHoursDay1),
    day_2: formatOpeningHour(values.triggerOpeningHoursDay2),
    day_3: formatOpeningHour(values.triggerOpeningHoursDay3),
    day_4: formatOpeningHour(values.triggerOpeningHoursDay4),
    day_5: formatOpeningHour(values.triggerOpeningHoursDay5),
    day_6: formatOpeningHour(values.triggerOpeningHoursDay6),
  };

  const result = {
    id: values.id,
    entity_type: values.entityType,
    body: values.body,
    action_url: values.actionUrl,
    client_id: VisitWidget.settings.currentClientId,
    enabled: values.enabled,
    entity_id: values.entityId,
    title: values.title,
    geofence_radius: values.geofenceRadius,
    notification_frequency: values.notificationFrequency,
    trigger_action: values.triggerAction,
    trigger_activity_period: values.triggerActivityPeriod,
    trigger_opening_hours: openingHours,
    campaign_starts_on: dayjs(values.campaignRunTime[0]).format(),
    campaign_ends_on: dayjs(values.campaignRunTime[1]).format(),
  } as ProximityMessageDto;

  return result;
};

export { mapFormToUpsertRequest };
