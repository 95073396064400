import { Category, MenuItem } from "../../../lib/types";

export default function getMenuItemExclusiveCategories(
  menuItem: MenuItem,
): Array<Category> {
  return VisitWidget.store.categories.filter(
    (category) =>
      category.exclusive &&
      category.enabled &&
      menuItem.category_ids.indexOf(category.id) > -1,
  );
}
