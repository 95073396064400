VisitWidget.EventHandlers.OnCurrentMenuItemChange = class OnCurrentMenuItemChange {
  constructor() {
    this.handle = this.handle.bind(this);
    VisitWidget.EventBus.subscribe("CurrentMenuItemChanged", this.handle);
  }

  handle(event) {
    VisitWidget.menuItemsController.populateList(event.newMenuItem);
    if (event.newMenuItem.entity === "PlanItem") {
      return;
    }

    // we don't show map at all for mobile feed unless disable_lists=true
    if (
      VisitWidget.settings.isMobile &&
      event.newMenuItem.entity === "FeedPost" &&
      !VisitWidget.settings.disableLists
    ) {
      VisitWidget.settings.disableOnMapGeoBoxChangedHandler = false;
      return;
    }
    this.populateMap(event.newMenuItem ?? undefined);

    if (!VisitWidget.settings.isMobile) {
      return VisitWidget.categoryFilterControl.setupDesktopCategoryFilterHeightAndScrolling();
    }
  }

  populateMap(menuItem) {
    if (menuItem.entity === "SharedPlan") {
      return;
    }

    VisitWidget.menuItemsController.populateMap({ resetMarkers: true }, () => {
      return (VisitWidget.settings.disableOnMapGeoBoxChangedHandler = false);
    });
    return VisitWidget.map.loadSubPageMapLayer(
      menuItem.map_layer_kml_url,
      "menuItem",
    );
  }
};
