import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUserAction } from "../../../store/auth/actions/registerUserAction";
import { ReCaptcha } from "../../../components/reCaptcha";
import { RootReducerState } from "../../../store/rootReducer";
import translate from "../../../lib/queries/translate";
import { withReduxProvider } from "../../../store/withReduxProvider";

// eslint-disable-next-line no-shadow
const enum RegisterTestIds {
  submitInput = "submit-input",
  newsletterInput = "newsletter-input",
  emailInput = "email-input",
  passwordInput = "password-input",
  nameInput = "name-input",
  postalCodeInput = "postal-code-input",
}

const RegisterBase: FunctionComponent = () => {
  const emailInput = useRef<HTMLInputElement | null>(null);
  const passwordInput = useRef<HTMLInputElement | null>(null);
  const nameInput = useRef<HTMLInputElement | null>(null);
  const newsletterInputs = useRef<HTMLInputElement[] | null>(
    new Array(VisitWidget.settings.newsletters.length),
  );
  const postalCodeInput = useRef<HTMLInputElement | null>(null);
  const captchaRef = useRef<{
    reset: () => void;
    getValue: () => string | undefined;
  } | null>(null);
  const dispatch = useDispatch();

  const registerStatus = useSelector(
    (x: RootReducerState) => x.auth.registerStatus,
  );

  useEffect(() => {
    if (registerStatus === "error") {
      // must reset the captcha after an error
      captchaRef.current.reset();
    }
  }, [registerStatus, captchaRef]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const captchaValue = captchaRef.current.getValue();
      const newsletters = VisitWidget.settings.newsletters
        .filter((x, index) => {
          return newsletterInputs.current[index].checked;
        })
        .map((x) => {
          return {
            id: x.id,
          };
        });

      dispatch(
        registerUserAction({
          email: emailInput.current.value,
          password: passwordInput.current.value,
          name: nameInput.current.value,
          newsletters: newsletters,
          captcha: captchaValue,
          postalCode: postalCodeInput.current?.value,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      );
    },
    [dispatch, emailInput, passwordInput],
  );

  return (
    <form>
      <div className="form-inputs">
        <label htmlFor="user_email">Email</label>
        <input
          id="user_email"
          className="email required"
          data-testid={RegisterTestIds.emailInput}
          type="text"
          name="user_email"
          ref={emailInput}
          tabIndex={0}
        />
        <label htmlFor="user_password">Password</label>
        <input
          id="user_password"
          className="password required"
          data-testid={RegisterTestIds.passwordInput}
          type="password"
          name="user_password"
          ref={passwordInput}
          tabIndex={0}
        />
        <label htmlFor="user_name">Name</label>
        <input
          id="user_name"
          className="name required"
          data-testid={RegisterTestIds.nameInput}
          type="text"
          name="user_name"
          ref={nameInput}
          tabIndex={0}
        />
        {VisitWidget.settings.isRequirePostalCode && (
          <>
            <label htmlFor="user_postal_code">Zip Code</label>
            <input
              id="user_postal_code"
              className="postal_code required"
              data-testid={RegisterTestIds.postalCodeInput}
              type="text"
              name="user_postal_code"
              ref={postalCodeInput}
              tabIndex={0}
            />
          </>
        )}
      </div>

      {VisitWidget.settings.newsletters.map((newsletter, index) => (
        <div className="newsletter_container" key={newsletter.id}>
          <input
            type="checkbox"
            data-value={newsletter.id}
            ref={(element) => (newsletterInputs.current[index] = element)}
            defaultChecked={VisitWidget.settings.isNewsletterCheckedByDefault}
            name={`user[subscribed_to_newsletter_${index}]`}
            id={`user_subscribed_to_newsletter_${index}`}
            data-testid={RegisterTestIds.newsletterInput}
            data-form-type="consent,newsletter"
          />
          <label
            className="subscribed_to_newsletter_label"
            id={`subscribed_to_newsletter_label_${newsletter.id}`}
            htmlFor={`user_subscribed_to_newsletter_${index}`}>
            &nbsp;
            {newsletter.label?.length > 0
              ? newsletter.label
              : translate("authentication.subscribe_to_newsletter_label")}
          </label>
        </div>
      ))}

      <ReCaptcha ref={captchaRef} />

      <div className="form-actions">
        <input
          type="submit"
          name="commit"
          data-testid={RegisterTestIds.submitInput}
          value={translate("authentication.sign_up.button")}
          className="btn"
          tabIndex={0}
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

const Register = withReduxProvider(RegisterBase);
export { Register, RegisterTestIds };
