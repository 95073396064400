import getCategoryForMapMarker from "../queries/getCategoryForMapMarker";
import getMarkerPicture from "../queries/getMarkerPicture";

const assignMarkerAttributesToCompiledLocation = async (
  compiledLocation,
  useMarkerWithLabel: boolean,
  shouldUseSmallMarkers: boolean,
): Promise<void> => {
  compiledLocation.category = await getCategoryForMapMarker(
    compiledLocation.entities,
  );

  compiledLocation.useMarkerWithLabel = useMarkerWithLabel;
  compiledLocation.infowindow = "";

  compiledLocation.picture = getMarkerPicture(
    compiledLocation,
    shouldUseSmallMarkers,
  );
};

export default assignMarkerAttributesToCompiledLocation;
