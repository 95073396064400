/* eslint-disable */
/* tslint-disable */

import translate from "../../lib/queries/translate";

VisitWidget.PlanItemListController = class PlanItemListController {
  public menuItem: any;
  public container: any;
  public sidebarSelector: any;
  public containerSelector: any;
  public $container: any;
  public eventListItemsControl: any;
  public placeListItemsControl: any;
  public placePlanItemControl: any;
  public eventPlanItemControl: any;
  public mapRoutingControl: any;
  public listDragAndDropControl: any;
  public startPointRoutingControl: any;
  public startPlanControl: any;
  public startPlanModeOn: boolean;
  public entityLayout: any;
  public eventTemplateName: string;
  public eventListItemTemplate: any;
  public placeTemplateName: string;
  public placeListItemTemplate: any;
  public getPlaceListItemHtml: any;
  public arePlanItemsPresent: any;
  public shareModal: any;
  public getEventListItemHtml: any;
  public plannable_type: any;

  constructor(menuItem: any) {
    this.createShareModal = this.createShareModal.bind(this);
    this.enablePlanNameEditing = this.enablePlanNameEditing.bind(this);
    this.menuItem = menuItem;
    this.sidebarSelector = `#sidebar_${this.menuItem.entity}${this.menuItem.id}`;
  }

  initialize() {
    this.containerSelector = `${this.sidebarSelector} #plan_list_items`;
    this.$container = $(this.containerSelector);

    this.placeListItemsControl = new VisitWidget.PlaceListItemsControl(
      this.containerSelector,
      { panMapOnHover: false },
    );
    this.eventListItemsControl = new VisitWidget.EventListItemsControl(
      this.containerSelector,
      {
        panMapOnHover: false,
        shouldSeparateDateAndTimeByLine: VisitWidget.settings.isMobile,
      },
    );
    this.placePlanItemControl = new VisitWidget.PlanItemControl(
      $(this.containerSelector),
      ".place",
    );
    this.eventPlanItemControl = new VisitWidget.PlanItemControl(
      $(this.containerSelector),
      ".event",
    );
    this.mapRoutingControl = new VisitWidget.MapRoutingControl(
      `${this.sidebarSelector}`,
      { includeRouteDetails: true, includeMap: true },
      {
        numberOfStopsSelector: "#number_of_stops",
        totalMilesSelector: "#route_total_miles",
        totalDurationSelector: "#route_total_duration",
      },
    );
    this.listDragAndDropControl = new VisitWidget.ListDragAndDropControl(
      this.containerSelector,
      this.getPlanItemId,
      this.onDragAndDrop,
      { handleSelector: ".handle" },
    );
    this.startPointRoutingControl = new VisitWidget.StartPointRoutingControl(
      this.sidebarSelector,
      () => {
        return this.updateRoutes();
      },
    );
    this.startPlanControl = new VisitWidget.StartPlanControl();

    this.registerEvents();
    this.placeListItemsControl.initialize();

    this.eventListItemsControl.initialize();
    const events = this.$container.find(".event.post");
    this.eventListItemsControl.setupEventHtmlForPlanList(events);

    this.placePlanItemControl.initialize();
    this.eventPlanItemControl.initialize();
    this.startPointRoutingControl.initialize();
    this.startPlanControl.initialize();
    this.setupPlanItems();
    this.updatePlanCount();

    this.startPlanModeOn = false;
    this.entityLayout = VisitWidget.settings.entity_list_item_layout;
    const eventTemplateName = "#event_thumbnail_list_item_template";
    this.eventListItemTemplate = Handlebars.compile(
      $(eventTemplateName).html(),
    );
    const placeTemplateName = "#place_thumbnail_list_item_template";
    this.placeListItemTemplate = Handlebars.compile(
      $(placeTemplateName).html(),
    );
    this.getPlaceListItemHtml = new VisitWidget.GetPlaceListItemHtml({
      forceColorCategoryIcons: true,
    });
    this.getEventListItemHtml = new VisitWidget.GetEventListItemHtml({
      forceColorCategoryIcons: true,
    });
  }

  getPlanItemId($post: any) {
    return $post.find(".plan-control").data("plan-item-id");
  }

  socialShareText() {
    let socialShareText = "";
    const sharePrefix = translate("plan.share_modal.share_plan");
    if (VisitWidget.global.currentPlanName() === null) {
      socialShareText = `${sharePrefix} ${VisitWidget.settings.currentClientName}!`;
    } else {
      socialShareText = `${sharePrefix} ${
        VisitWidget.settings.currentClientName
      }${VisitWidget.global.currentPlanName()}:`;
    }

    return socialShareText;
  }

  createShareModal() {
    const shareUrl =
      location.origin + `/plan?plan_id=${VisitWidget.store.currentPlanId}`;

    const shareModal = new VisitWidget.ShareModal(
      VisitWidget.lib.translate("plan.share_modal.title"),
      true,
      shareUrl,
      this.socialShareText(),
      (destinations: any, callback: any) =>
        VisitWidget.Plan.share(destinations, callback),
    );

    shareModal.initialize();

    return shareModal;
  }

  registerEvents() {
    $(`${this.sidebarSelector} .touch-share-plan-control`).click(() => {
      if (this.arePlanItemsPresent) {
        if (this.shareModal == null) {
          this.shareModal = this.createShareModal();
        }
        this.shareModal.show();
      } else {
        alert("There are no items in your plan to share.");
      }
    });

    $(`${this.sidebarSelector} .toggle-start-plan-button`).click(() => {
      if ($(`${this.sidebarSelector} .post`).length < 2) {
        alert("Please add more than one place or event to your plan.");
        return;
      }

      if (!this.mapRoutingControl.areRoutesLoaded()) {
        return;
      }

      if (this.startPlanModeOn === false || VisitWidget.settings.isMobile) {
        return this.turnOnStartPlanMode();
      } else {
        return this.turnOffStartPlanMode();
      }
    });

    ($(`${this.sidebarSelector} .best_in_place`) as any).best_in_place();

    return this.registerPlanNameUpdate();
  }

  registerPlanNameUpdate() {
    return $(`${this.sidebarSelector} .best_in_place`).bind(
      "ajax:success",
      () => {
        if (this.arePlanItemsPresent) {
          if (this.shareModal == null) {
            this.shareModal = this.createShareModal();
          }
          return this.shareModal.initializeSocialShare(this.socialShareText());
        }
      },
    );
  }

  turnOnStartPlanMode() {
    this.startPlanControl.start();
    if (!VisitWidget.settings.isMobile) {
      $(`${this.sidebarSelector} .toggle-start-plan-button a`).text(
        VisitWidget.lib.translate("plan.end_plan_button"),
      );
      $(this.containerSelector)
        .find(".touch-plan-control, .touch-map-it-control")
        .hide();
      this.listDragAndDropControl.disable();
    }
    this.startPlanModeOn = true;
    this.placeListItemsControl.disableMapIt();
    return this.eventListItemsControl.disableMapIt();
  }

  turnOffStartPlanMode() {
    $("#plan_directions .slider ul li").remove();
    $("#plan_directions").hide();
    if (!VisitWidget.settings.isMobile) {
      this.showButtonsForStartPlan();
      $(".create.btn.registration-trigger").show();
      this.listDragAndDropControl.enable();
    }
    this.startPlanModeOn = false;
    this.startPlanControl.end();
    this.placeListItemsControl.enableMapIt();
    return this.eventListItemsControl.enableMapIt();
  }

  showButtonsForStartPlan() {
    $(`${this.sidebarSelector} .toggle-start-plan-button`).show();
    $(`${this.sidebarSelector} .toggle-start-plan-button a`).text(
      VisitWidget.lib.translate("plan.start_plan_button"),
    );
    return $(this.containerSelector)
      .find(".touch-plan-control, .touch-map-it-control")
      .show();
  }

  populate = (callback) => {
    this.$container.empty();
    if (VisitWidget.store.startPointPlanItemHtml) {
      this.$container.prepend(VisitWidget.store.startPointPlanItemHtml);
    }

    const planId = VisitWidget.global.planId();

    if (planId === null) {
      this.showNoPlanItems();
      if (callback != null) {
        callback();
      }
      return;
    }

    this.updatePlanCount();
    VisitWidget.PlanItem.query(planId, async (planItems: any[]) => {
      for (let planItem of planItems) {
        let html;

        if (planItem.plannable_type === "Place") {
          html = this.getPlaceListItemHtml.call(
            [planItem.plannable],
            this.placeListItemTemplate,
          );
          this.placeListItemsControl.addListItemsHtml(html);
        } else if (planItem.plannable_type === "Event") {
          html = this.getEventListItemHtml.call(
            [planItem.plannable],
            this.eventListItemTemplate,
          );
          this.eventListItemsControl.addListItemsHtml(html);
        }

        const notes =
          planItem.notes.length > 0 ? planItem.notes[0].body : undefined;
        if (notes !== undefined) {
          ReactOnRails.render(
            "NotesButton",
            {
              notes: notes,
              coverPhotoUrl: planItem.plannable.cover_photo_url,
              title: planItem.plannable.name,
              type: "planItem",
            },
            `plan-item-notes-${planItem.plannable_type}-${planItem.plannable_id}`,
            false,
          );
        }
      }

      this.setupPlanItems();

      const entities = planItems.map((planItem) => planItem.plannable);
      const compiledLocations =
        await VisitWidget.map.queries.getCompiledLocations(entities, true);

      VisitWidget.settings.disableOnMapGeoBoxChangedHandler = true;
      VisitWidget.menuItemsController.populateMap(
        { resetMarkers: false },
        () => {
          VisitWidget.settings.disableOnMapGeoBoxChangedHandler = false;
          return VisitWidget.map.addPlanMarkers(compiledLocations);
        },
      );

      this.listDragAndDropControl.initialize();
      if (callback != null) {
        return callback();
      }
    });
  };

  updatePlanCount() {
    if ($(".plan-count").data("data-animating")) {
      return;
    }
    const planItemCount = VisitWidget.global.getPlanItemCount();

    $(".plan-count").html(planItemCount);
    return $(".plan-count").attr("data-count", planItemCount);
  }

  updateRoutes() {
    // Starting Point marker is cleared when navigating to a different
    // menu item.
    this.addMarkerForStartingPointIfMissing();

    const mapMarkers = this.getMapMarkersForRouting();

    return this.mapRoutingControl.createRoutesFromMapMarkers(mapMarkers, true);
  }

  addMarkerForStartingPointIfMissing() {
    if (
      this.startPointRoutingControl.hasStartingPoint &&
      Gmaps.store.startPointMarker == null
    ) {
      return this.startPointRoutingControl.addMarker();
    }
  }

  enablePlanNameEditing(plan) {
    const $planName = $(
      `${this.sidebarSelector} .best_in_place[data-bip-attribute=name]`,
    );
    $planName.attr("data-bip-url", `/plans/${plan.id}`);
    $planName.removeAttr("data-bip-extra-payload");
    $planName.removeAttr("data-bip-new-object");
    const $planNameClone = $planName.clone();
    $planName.replaceWith($planNameClone);
    $planNameClone.show();
    return this.registerPlanNameUpdate();
  }

  // Seems like we could just get the marker ids from the markers, but
  // that won't work if the user drags and drops a plan list item.
  getMapMarkersForRouting() {
    const mapMarkers = [];
    const $planItems = $(`${this.sidebarSelector} .post`);
    $planItems.each(function (planItemIndex, planItem) {
      const markerId = $(planItem).data("marker-id");
      let i = 0;

      const result = [];
      while (i < Gmaps.store.markers.length) {
        if (
          Gmaps.store.markers[i].serviceObject instanceof
            (window as any).MarkerWithLabel &&
          markerId === Gmaps.store.markers[i].serviceObject.markerId
        ) {
          mapMarkers.push(Gmaps.store.markers[i]);
        }
        result.push(i++);
      }
      result;
    });

    if (Gmaps.store.startPointMarker != null) {
      mapMarkers.unshift(Gmaps.store.startPointMarker);
    }

    return mapMarkers;
  }

  setupPlanItems() {
    if (this.$container.find(".post").length > 0) {
      this.placePlanItemControl.initialize();
      this.eventPlanItemControl.initialize();

      VisitWidget.global.setFriendlyDateTimes();
      this.arePlanItemsPresent = true;
      return this.arePlanItemsPresent;
    } else {
      this.arePlanItemsPresent = false;
      return this.showNoPlanItems();
    }
  }

  onDragAndDrop(planItemsAttributes: any) {
    const params = {
      plan: {
        plan_items_attributes: planItemsAttributes,
      },
    };
    return VisitWidget.Plan.update(params, () => {
      return VisitWidget.planItemListController.updateRoutes();
    });
  }

  showNoPlanItems() {
    const placesLink =
      '<a class="change_navigation_action strong" ' +
      'data-entity="Place" href="#">' +
      VisitWidget.lib.translate("place.plural") +
      "</a>";

    const eventsLink =
      '<a class="change_navigation_action strong" ' +
      'data-entity="Event" href="#">' +
      VisitWidget.lib.translate("event.plural") +
      "</a>";

    const text = I18n.t("plan.empty", {
      places: placesLink,
      events: eventsLink,
    });

    if (VisitWidget.settings.isMobile) {
      this.$container.append(`<div class='no-items'>${text}</div>`);
    } else {
      this.$container.append(`<div class='post no-items'>${text}</div>`);
    }

    $(".change_navigation_action").on("click", function (e) {
      const entityName = $(e.target).data("entity");
      return VisitWidget.navigationController.navigateToMenuItemByEntity(
        entityName,
      );
    });

    return this.listDragAndDropControl.initialize();
  }
};
