import React, { FunctionComponent } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem: FunctionComponent<SortableItemProps> = ({
  id,
  children,
  isSortEnabled,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
      {isSortEnabled && (
        <div className="drag-handle-container">
          <div className="drag-handle" {...attributes} {...listeners}></div>
        </div>
      )}
    </div>
  );
};

interface SortableItemProps {
  children: React.ReactNode;
  isSortEnabled: boolean;
  id: number | string;
}

export { SortableItem, SortableItemProps };
