VisitWidget.CoverPhotoUploadControl = class CoverPhotoUploadControl {
  $container: any;
  entityClass: string;
  entityId: number | string;
  imageAttribute: string;
  imageAttributes: Array<string>;
  imageUppy: any;
  videoMediaFileUppy: any;
  audioMediaFileUppy: any;

  constructor(
    $container: JQuery,
    entityClass: string,
    entityId: string | number,
    imageAttribute: string,
    imageAttributes: string[],
  ) {
    this.onMediaToggle = this.onMediaToggle.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.$container = $container;
    this.entityClass = entityClass;
    this.entityId = entityId;
    this.imageAttribute = imageAttribute;
    if (imageAttributes == null) {
      imageAttributes = [];
    }
    this.imageAttributes = imageAttributes;
  }

  public initialize() {
    this.setupEvents();
    this.imageUppy = this.fileUpload("image", "JPG or PNG");
    for (let imageAttribute of Array.from(this.imageAttributes)) {
      this[`${VisitWidget.util.toCamel(imageAttribute)}Uppy`] = this.fileUpload(
        imageAttribute,
        "JPG or PNG",
      );
    }

    if (VisitWidget.settings.isAudioAndVideoEnabled) {
      this.videoMediaFileUppy = this.fileUpload("video-media-file", "MP4");
      return (this.audioMediaFileUppy = this.fileUpload(
        "audio-media-file",
        "MP3",
      ));
    }
  }

  private setupEvents() {
    this.$container.find(".flyout-media-toggle").click(this.onMediaToggle);
    this.setupPreviewEvent();
    return this.setupDeleteEvent();
  }

  private setupPreviewEvent() {
    const selector =
      ".file-upload-preview-action[data-media-file-type=AudioMediaFile]";
    return this.$container.find(selector).click(this.onPreview);
  }

  private setupDeleteEvent() {
    return this.$container
      .find(".file-upload-delete-action")
      .click(this.onDelete);
  }

  private onMediaToggle(event: MouseEvent) {
    this.$container.find(".flyout-media-toggle").removeClass("active");
    const $target = $(event.target);
    $target.addClass("active");
    const mediaType = $target.data("type");
    return this.toggleMediaFileListOrUploadDashboard(mediaType);
  }

  private toggleMediaFileListOrUploadDashboard(mediaType: string) {
    const $fileUploadDashboard = this.$container.find(
      `.file_upload_dashboard.${mediaType}`,
    );

    this.$container.find(".file_upload_dashboard").removeClass("active");
    this.$container.find(".flyout-media-files").removeClass("active");

    const $mediaFiles = this.$container.find(
      `.flyout-media-files.${mediaType} .flyout-media-file`,
    );

    if ($mediaFiles.length > 0) {
      return $(`.flyout-media-files.${mediaType}`).addClass("active");
    } else {
      return $fileUploadDashboard.addClass("active");
    }
  }

  private onPreview(event: MouseEvent) {
    event.preventDefault();
    const $target = $(event.target);
    if ($target.parents(".flyout-media-file-actions").hasClass("processing")) {
      return false;
    }

    if ($target.parents(".audio-media-file").length > 0) {
      const mediaFileId = $target.data("media-file-id");
      const mediaFileUrl = $target.data("media-file-url");
      this.$container
        .find(`.edit-audio-player[data-media-file-id=${mediaFileId}]`)
        .show()
        .find("audio")
        .attr("src", mediaFileUrl)[0]
        .play();
    }

    return false;
  }

  private onDelete(event: MouseEvent) {
    event.preventDefault();
    const $target = $(event.target);
    if (!confirm("Are you sure want to delete this file?")) {
      return;
    }
    return this.deleteMediaFile($target);
  }

  private deleteMediaFile($target: JQuery<EventTarget>) {
    const entityId = $target.data("entity-id");
    const entityType = $target.data("entity-type");
    const entityAttribute = $target.data("entity-attribute");
    if (entityAttribute) {
      return VisitWidget[entityType].update(
        entityId,
        { [entityAttribute]: null },
        () => {
          return this.onDeletionComplete(
            $target,
            entityId,
            entityType,
            entityAttribute,
          );
        },
      );
    } else {
      return VisitWidget[entityType].delete(entityId, () => {
        return this.onDeletionComplete(
          $target,
          entityId,
          entityType,
          entityAttribute,
        );
      });
    }
  }

  private onDeletionComplete(
    $target: JQuery<EventTarget>,
    entityId: number | string,
    entityType: string,
    entityAttribute: string,
  ) {
    let typeCssClass, uppyControl;
    if (entityAttribute) {
      typeCssClass = entityAttribute;
      uppyControl = this[`${VisitWidget.util.toCamel(entityAttribute)}Uppy`];
    } else {
      typeCssClass = VisitWidget.util.hyphenate(entityType);
      uppyControl = this[`${VisitWidget.util.toCamel(entityType)}Uppy`];
    }

    const $flyoutMediaFiles = $target.parents(".flyout-media-files");
    // if in list
    $target.parents(".flyout-media-file").remove();
    // if in upload ctrl
    uppyControl.reset();

    this.$container
      .find(`.edit-audio-player[data-media-file-id=${entityId}]`)
      .remove();

    if ($flyoutMediaFiles.find(".flyout-media-file").length === 0) {
      $flyoutMediaFiles.remove();
    }
    return this.toggleMediaFileListOrUploadDashboard(typeCssClass);
  }

  private fileUpload(mediaType: string, fileTypesText: string) {
    const inputSelector = `.media-upload .${mediaType}-input`;
    const dashboardSelector = `.file_upload_dashboard.${mediaType}`;
    const fileInput = $(inputSelector)[0];
    if (!fileInput) {
      return;
    }
    fileInput.style.display = "none";
    const allowedFileTypes =
      VisitWidget.settings[
        VisitWidget.util.toCamel(mediaType) + "AllowedMimeTypes"
      ];
    const fileSizeSetting = VisitWidget.util.toCamel(mediaType) + "MaxSize";
    // Uppy calculates MB differently than Shine
    const maxFileSize =
      (VisitWidget.settings[fileSizeSetting] / 1024 / 1024) * 1000 * 1000;
    // uppy will add its own file input
    const uppy = Uppy.Core({
      id: fileInput.id,
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes,
        maxFileSize,
      },
    }).use(Uppy.Dashboard, {
      target: dashboardSelector,
      inline: true,
      locale: {
        strings: {
          dropPaste: `Drop a ${fileTypesText} here, paste or %{browse}`,
        },
      },
      replaceTargetContent: true,
      trigger: ".touch-edit-control",
      showProgressDetails: true,
      hideProgressAfterFinish: true,
      width: 480,
      height: 300,
      proudlyDisplayPoweredByUppy: false,
    });
    uppy.use(Uppy.AwsS3, {
      getUploadParameters(file) {
        return fetch("/file_uploading/presign?filename=" + file.name, {
          credentials: "same-origin",
        }).then((response) => response.json());
      },
    });
    uppy.run();
    uppy.on("upload-success", (file, _data, _uploadURL) => {
      const uploadedFileData = JSON.stringify({
        id: file.meta.key.match(/attachments_cache\/([^\?]+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      });
      // set hidden field value to the uploaded file data so that
      // it's submitted with the form as the attachment
      const hiddenInput = fileInput.parentNode.querySelector(
        `.${mediaType}-upload-hidden`,
      ) as HTMLInputElement;
      hiddenInput.value = uploadedFileData;
      const $form = $(fileInput).closest("form");
      this.submitForm($form, mediaType);
    });
    return uppy;
  }

  private submitForm($form: JQuery<HTMLElement>, mediaType: string) {
    return $.ajax({
      type: "POST",
      url: $form.attr("action"),
      data: $form.serialize(),
      success: (mediaFile) => {
        return this.showMediaFilePreviewAction(mediaFile, false, mediaType);
      },
      error: (jqXHR) => {
        const errors = JSON.parse(jqXHR.responseText)["errors"];
        alert(`An error uploading the file has occurred: ${errors.join("; ")}`);
        return this[`${VisitWidget.util.toCamel(mediaType)}Uppy`].reset();
      },
    });
  }

  private showMediaFilePreviewAction(
    mediaFile: any,
    isProcessing: boolean,
    mediaType: string,
  ) {
    let processingCssClass, url;
    const cssClass = VisitWidget.util.hyphenate(mediaType);
    if (isProcessing) {
      url = "#";
      processingCssClass = "processing";
    } else {
      ({ url } = mediaFile);
      processingCssClass = "";
    }

    const $container = $(
      `.file_upload_dashboard.${cssClass} .uppy-DashboardItem`,
    );
    const actionsHtml =
      `<div class='flyout-media-file-actions ${processingCssClass}'>` +
      "<span class='file-upload-preview-action " +
      `${
        mediaFile.type === "VideoMediaFile" ? "play_media_file" : undefined
      }' ` +
      `data-media-file-id='${mediaFile.id}' ` +
      `data-media-file-type='${mediaFile.type}' ` +
      `data-media-file-url='${url}' />` +
      "<div class='ajax-loader'></div>" +
      "<a class='file-upload-delete-action' " +
      `href='${url}' data-entity-id='${mediaFile.id}' ` +
      `data-entity-type='${mediaFile.type}'>Delete</a>` +
      "</div>";

    $container.append(actionsHtml);

    if ((mediaFile.type = "AudioMediaFile")) {
      const audioPlayerHtml =
        "<div class='edit-audio-player' " +
        `data-media-file-id='${mediaFile.id}'> ` +
        `<audio controls src='${mediaFile.url}'></audio>` +
        "</div>";
      $container.after(audioPlayerHtml);
    }

    this.setupPreviewEvent();
    return this.setupDeleteEvent();
  }
};
