const buildErrorMessage = (error: { errors: Record<string, string> }) => {
  if (error instanceof SyntaxError) {
    // likely a 302 was returned, there is a lot of middleware that is a bit of
    // a black box and may have intercepted the intended controller action
    return "Unknown error";
  }

  const keys = Object.keys(error.errors);
  const result = keys.reduce((accumulator, key, index) => {
    accumulator += `${error.errors[key]}${index < keys.length - 1 ? "\n" : ""}`;
    return accumulator;
  }, "");

  return result;
};

export { buildErrorMessage };
