import { convert } from "quantity-fns/distance";

interface NamedParameters {
  sourceDistance: number;
  sourceUnit: string;
  targetUnit: string;
}

export default function getDistanceInTargetUnit({
  sourceDistance,
  sourceUnit,
  targetUnit,
}: NamedParameters): number {
  return convert(
    {
      unit: sourceUnit,
      quantity: sourceDistance,
      type: "distance",
    },
    targetUnit,
  ).quantity;
}
