import React, { FunctionComponent } from "react";
import { ConfigProvider } from "../ConfigProvider";
import { FormInstance, Select as AntSelect } from "antd";
import { ChevronDownIcon } from "../icons";
import "./Select.scss";

const Select: FunctionComponent<SelectProps> = ({
  options,
  onChange,
  size = "large",
  disabled,
  form,
  name,
  placeholder,
  testID,
}) => {
  return (
    <ConfigProvider>
      <AntSelect
        disabled={disabled}
        className={`select-${size}`}
        options={options}
        onChange={onChange}
        defaultValue={form?.getFieldValue(name) ?? ""}
        data-testid={testID}
        size={size}
        style={size === "large" ? { height: "46px" } : {}}
        suffixIcon={<ChevronDownIcon />}
        placeholder={placeholder}
      />
    </ConfigProvider>
  );
};

interface SelectOption {
  value: string | number;
  label: string;
}

interface SelectProps {
  /**
   * The height of the component.
   */
  size?: "small" | "middle" | "large";
  /**
   * The options that appear in the Select.
   */
  options: SelectOption[];
  /**
   * DO NOT SET: Automatically passed in via Form.Item being the direct parent element.
   */
  onChange?: (value: string | number, option: SelectOption) => void;
  /**
   * Disable editing of the input.
   */
  disabled?: boolean;
  /**
   * Parent form, needed for default value to work.
   */
  form?: FormInstance<any>;
  /**
   * Parent form name of input, needed for default value to work.
   */
  name?: string;
  /**
   * Placeholder text displayed in input when there is no text.
   */
  placeholder?: string;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
}

export { Select, SelectProps };
