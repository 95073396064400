import {
  SharedPlanScreen,
  SharedPlanScreenProps,
} from "../../../components/plans/SharedPlanScreen";
import { setFlyoutAction } from "../../flyout/actions/setFlyoutAction";
import { duplicateTour } from "../../visitWidgetApi";
import { listToursAction } from "./listToursAction";

const duplicateTourAction = (tourId: number) => async (dispatch) => {
  try {
    const duplicatedTourResponse = await duplicateTour(tourId);
    dispatch(listToursAction(VisitWidget.store.categoryIds, true));
    dispatch(
      setFlyoutAction(SharedPlanScreen, {
        tourId: duplicatedTourResponse.tour.id,
      } as SharedPlanScreenProps) as any,
    );
  } catch (error) {
    alert("Error duplicating.");
  }
};

export { duplicateTourAction };
