VisitWidget.MediaFilePlaybackControl = class MediaFilePlaybackControl {
  $container: any;

  constructor($container) {
    this.onPlay = this.onPlay.bind(this);
    this.onAudioPlaybackCompleted = this.onAudioPlaybackCompleted.bind(this);
    this.onPause = this.onPause.bind(this);
    this.$container = $container;
  }

  initialize() {
    this.setupEvents();
  }

  setupEvents() {
    $(document).on("click", ".play_media_file", this.onPlay);
    $(document).on("click", ".pause_media_file", this.onPause);
    $(document).on("ended", "audio", this.onAudioPlaybackCompleted);
  }

  onPlay(event) {
    event.preventDefault();
    this.pauseAllAudioPlayback();
    const $playButton = $(event.target).closest(".play_media_file");

    if ($playButton.data("media-file-type") === "VideoMediaFile") {
      const videoMediaFileUrl = $playButton.data("media-file-url");
      this.addVideoPlayer(videoMediaFileUrl);
      this.playVideo();
    } else {
      this.playAudio($playButton);
    }

    return false;
  }

  onAudioPlaybackCompleted(event) {
    const $audio = $(event.target);
    $audio.siblings(".play_media_file").show();
    $audio.siblings(".pause_media_file").hide();
  }

  onPause(event) {
    event.preventDefault();
    const $pauseButton = $(event.target).closest(".pause_media_file");
    $pauseButton.siblings("audio")[0].pause();
    $pauseButton.siblings(".play_media_file").show();
    $pauseButton.hide();
    return false;
  }

  playVideo() {
    const $modal = $(".modal.video");
    VisitWidget.store.videoPlayer.play();
    if (VisitWidget.settings.isMobile) {
      $modal.css("opacity", 0);
      VisitWidget.store.videoPlayer.requestFullscreen();
      VisitWidget.store.videoPlayer.on("fullscreenchange", () => {
        if (!VisitWidget.store.videoPlayer.isFullscreen()) {
          VisitWidget.modal.close($modal);
        }
      });
    }

    VisitWidget.modal.open($modal, null, () => {
      this.disposeVideoPlayer();
      if (VisitWidget.settings.isMobile) {
        $modal.css("opacity", 100);
      }
    });
  }

  disposeVideoPlayer() {
    if (VisitWidget.store.videoPlayer) {
      VisitWidget.store.videoPlayer.dispose();
      VisitWidget.store.videoPlayer = null;
    }
  }

  addVideoPlayer(videoMediaFileUrl) {
    if (VisitWidget.store.videoPlayer) {
      return VisitWidget.store.videoPlayer;
    }

    const $videoPlayer = $(`<video id="video_modal_player" class="video-js" \
controls preload="auto"> \
<p class="vjs-no-js"> \
To view this video please enable JavaScript, and consider \
upgrading to a web browser that \
<a href="http://videojs.com/html5-video-support/" target="_blank"> \
supports HTML5 video \
</a> \
</p> \
</video>`);
    $videoPlayer.appendTo("#video_container");

    const player = videojs("video_modal_player", {
      fluid: true,
      preload: "none",
    });
    player.src({ type: "application/x-mpegURL", src: videoMediaFileUrl });

    VisitWidget.store.videoPlayer = player;
    return player;
  }

  playAudio($playButton) {
    $playButton.siblings("audio")[0].play();
    $playButton.hide();
    $playButton.siblings(".pause_media_file").show();
  }

  pauseAllAudioPlayback(): void {
    $("audio").each((_, audio: any) => {
      audio.pause();
      const $audio = $(audio);
      $audio.siblings(".play_media_file").show();
      $audio.siblings(".pause_media_file").hide();
    });
  }
};
