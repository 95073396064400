/* tslint:disable */
/* eslint:disable */

import { globalDispatch } from "../../store/store";
import { setCookie } from "../../lib/cookies";
import { setPlanSuccess } from "../../store/auth/authActionTypes";

VisitWidget.Plan = class Plan {
  static create(callback) {
    const path = `/plans${VisitWidget.settings.isKiosk ? "?kiosk=true" : ""}`;
    return $.ajax({
      url: path,
      type: "POST",
      success: (plan) => {
        VisitWidget.store.currentPlanId = plan.id;
        VisitWidget.planItemListController.enablePlanNameEditing(plan);
        globalDispatch(setPlanSuccess(plan.id));

        if (!!callback) {
          return callback(plan);
        }
      },
    });
  }

  static update(parameters, callback?: Function) {
    return $.ajax({
      url: `/plans/${VisitWidget.store.currentPlanId}`,
      type: "PATCH",
      data: parameters,
      success(plan) {
        if (!!callback) {
          return callback(plan);
        }
      },
    });
  }

  static share(parameters, callback?: Function) {
    return $.ajax({
      url: `/plans/${VisitWidget.store.currentPlanId}/share`,
      type: "POST",
      data: parameters,
      success(plan) {
        if (!!callback) {
          return callback(plan);
        }
      },
    });
  }

  static shareToItineraryPlanner(parameters, callback?: Function) {
    const planId = VisitWidget.store.currentPlanId;
    parameters.locale = I18n.locale;
    return $.ajax({
      url: `/plans/${planId}/share_to_itinerary_planner`,
      type: "POST",
      data: parameters,
      success() {
        if (!!callback) {
          return callback();
        }
      },
    });
  }

  static addTourToPlan(tourId, callback?: Function): JQuery.jqXHR<any> {
    if (VisitWidget.store.currentPlanId === null) {
      return VisitWidget.Plan.create((plan) => {
        VisitWidget.store.currentPlanId = plan.id;
        setCookie("planId", plan.id);
        return VisitWidget.Plan.addTourToPlan(tourId, callback);
      });
    } else {
      return $.ajax({
        url: `/plans/${VisitWidget.store.currentPlanId}/add_tour_to_plan`,
        type: "POST",
        dataType: "json",
        data: {
          tour_id: tourId,
        },
        success(addedPlanItems) {
          VisitWidget.store.addTourIdToPlan(tourId);
          if (!!callback) {
            return callback(addedPlanItems);
          }
        },
      });
    }
  }

  static removeTourFromPlan(tourId, callback?: Function) {
    return $.ajax({
      url: `/plans/${VisitWidget.store.currentPlanId}/remove_tour_from_plan`,
      type: "DELETE",
      dataType: "json",
      data: {
        tour_id: tourId,
      },
      success(removedPlanItems) {
        VisitWidget.store.removeTourIdFromPlan(tourId);
        if (!!callback) {
          return callback(removedPlanItems);
        }
      },
    });
  }
};
