/* tslint-disable */
/* eslint-disable */

import { PlanItemMinimalDto } from "../../../store/apiTypes";
import { SharedPlanScreen } from "../../../components/plans/SharedPlanScreen";
import { setFlyoutAction } from "../../../store/flyout/actions/setFlyoutAction";
import { adaptTourDtoToPlan, getTour } from "src/store/visitWidgetApi";
import { setSharedPlanAction } from "../../../store/plan/actions/setSharedPlanAction";

VisitWidget.PlanItemControl = class PlanItemControl {
  private $container: any;
  private selector: any;
  private excludeFutureTargets: any;
  private planAnimationControl: any;

  constructor(
    $container: any,
    selector: any,
    excludeFutureTargets: boolean | null | undefined,
    allowEventPropagation: boolean | null | undefined,
  ) {
    this.onClick = this.onClick.bind(this);
    this.onListItemClick = this.onListItemClick.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.createPlanItem = this.createPlanItem.bind(this);
    this.removePlanItem = this.removePlanItem.bind(this);
    if (excludeFutureTargets === null || excludeFutureTargets === undefined) {
      excludeFutureTargets = false;
    }
    if (allowEventPropagation === null || allowEventPropagation === undefined) {
      allowEventPropagation = false;
    }
    this.$container = $container;
    this.selector = selector;
    this.excludeFutureTargets = excludeFutureTargets;
    this.planAnimationControl = new VisitWidget.PlanAnimationControl();
  }

  initialize() {
    return this.setupEvents();
  }

  static updatePlanItemControlsToRemoveState(planItem: {
    id: number;
    plannable_id: number;
    plannable_type: string;
  }) {
    const $planItemControls = VisitWidget.PlanItemControl.getPlanItemControls(
      planItem.plannable_id,
      planItem.plannable_type,
    );

    return $planItemControls.each(function () {
      const $planItemControl = $(this);
      $planItemControl.data("plan-item-id", planItem.id);
      $planItemControl.removeClass("registration-trigger");
      const goingVerb = $planItemControl.data("going-verb");
      $planItemControl.find(".content span").text(goingVerb);
      $planItemControl.removeClass("add").addClass("remove");
      if (!$planItemControl.hasClass("disable_drop_down")) {
        return $planItemControl.removeClass("disable");
      }
    });
  }

  static updatePlanItemControlsToAddState(planItem: {
    plannable_id: number;
    plannable_type: string;
  }) {
    const $planItemControls = VisitWidget.PlanItemControl.getPlanItemControls(
      planItem.plannable_id,
      planItem.plannable_type,
    );

    return $planItemControls.each(function () {
      const $planItemControl = $(this);
      $planItemControl.data("plan-item-id", "");
      $planItemControl.addClass("disable");
      const addVerb = $planItemControl.data("add-verb");
      $planItemControl.find(".content span").text(addVerb);
      return $planItemControl.removeClass("remove").addClass("add");
    });
  }

  static getPlanItemControls(plannableId: string, plannableType: string) {
    const planControlSelector =
      ".plan-control[data-plannable-id=" +
      `${plannableId}][data-plannable-type=${plannableType}]`;
    const $planItemControls = $(planControlSelector);

    for (let infoBox of VisitWidget.map.infoBoxes) {
      const planItemControls = infoBox.find(planControlSelector);
      planItemControls.each((_index: any, planItemControl: any) => {
        ($planItemControls as any).push(planItemControl);
      });
    }

    return $planItemControls;
  }

  setupEvents() {
    if (this.excludeFutureTargets) {
      const clickEvent = "click";

      $(`${this.$container.selector} ${this.selector} .touch-plan-control`).on(
        clickEvent,
        this.onClick,
      );
      return $(
        `${this.$container.selector} ${this.selector} .plan-control ul li`,
      ).on(clickEvent, this.onListItemClick);
    } else {
      $(document).off(
        "click",
        `${this.$container.selector} ${this.selector} .touch-plan-control`,
      );
      $(document).on(
        "click",
        `${this.$container.selector} ${this.selector} .touch-plan-control`,
        this.onClick,
      );
      $(document).off(
        "click",
        `${this.$container.selector} ${this.selector} .plan-control ul li`,
      );
      return $(document).on(
        "click",
        `${this.$container.selector} ${this.selector} .plan-control ul li`,
        this.onListItemClick,
      );
    }
  }

  onClick(e: any) {
    const $parentControl = $(e.target).closest(".touch-plan-control");
    const $planItemControl = $parentControl.find(".plan-control");
    const planItemId = $planItemControl.data("plan-item-id");

    if (planItemId === "") {
      this.add($planItemControl);
    } else {
      this.remove($planItemControl);
    }

    e.stopPropagation();
    return false;
  }

  onListItemClick(e: any) {
    const $target = $(e.target);
    const $planItemControl = $target.closest(".plan-control");
    return this.remove($planItemControl);
  }

  add(planItemControl: any): void {
    VisitWidget.registrationModal.openModalIfTriggerCountReached("add_to_plan");

    const plannableId = planItemControl.data("plannable-id");
    const plannableType = planItemControl.data("plannable-type");
    if (this.planAnimationControl.doAnimation()) {
      this.planAnimationControl.animateAdd(planItemControl, 1);
    }
    this.createPlanItem(plannableId, plannableType);
  }

  remove($planItemControl: any) {
    const planItemId = $planItemControl.data("plan-item-id");
    const plannableId = $planItemControl.data("plannable-id");
    const plannableType = $planItemControl.data("plannable-type");
    this.removePlanItem(
      $planItemControl,
      planItemId,
      plannableId,
      plannableType,
    );
  }

  private createPlanItem(plannableId: any, plannableType: any): void {
    VisitWidget.Analytics.createPlanAdd(plannableId, plannableType);
    VisitWidget.PlanItem.create(
      plannableId,
      plannableType,
      (result: { plan_item: PlanItemMinimalDto; plan_item_count: number }) => {
        this.afterAdding(result);
      },
    );
  }

  removePlanItem(
    _planItemControl: any,
    planItemId: any,
    plannableId: any,
    plannableType: any,
  ): void {
    VisitWidget.PlanItem.delete(planItemId, (data: any) => {
      this.afterRemoving(data, plannableId, plannableType);
    });
  }

  private async afterAdding(data: {
    plan_item: PlanItemMinimalDto;
    plan_item_count: number;
  }) {
    if (VisitWidget.store.globalEditMode.enabled) {
      if (VisitWidget.store.globalEditMode.mode === "sharedPlans") {
        const tour = await getTour(VisitWidget.store.globalEditMode.entityId);
        (window as any).globalDispatch(
          setSharedPlanAction(adaptTourDtoToPlan(tour)),
        );
        (window as any).globalDispatch(
          setFlyoutAction(SharedPlanScreen, {
            tourId: VisitWidget.store.globalEditMode.entityId,
            mode: "edit",
          }),
        );
      } else {
        const url = VisitWidget.GetEditModeEntityUrl.call(true);
        VisitWidget.flyoutLoader.navigateTo(url);
      }
    } else {
      VisitWidget.store.addPlanItems([data.plan_item]);
    }

    VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState(
      data.plan_item,
    );

    VisitWidget.EventBus.publish(new VisitWidget.Events.PlanItemAdded());
  }

  async afterRemoving(
    _data: any,
    plannableId: number,
    plannableType: "Event" | "Place",
  ) {
    let planItem;
    if (VisitWidget.store.globalEditMode.enabled) {
      if (VisitWidget.store.globalEditMode.mode === "sharedPlans") {
        const tour = await getTour(VisitWidget.store.globalEditMode.entityId);
        (window as any).globalDispatch(
          setSharedPlanAction(adaptTourDtoToPlan(tour)),
        );
        (window as any).globalDispatch(
          setFlyoutAction(SharedPlanScreen, {
            tourId: VisitWidget.store.globalEditMode.entityId,
            mode: "edit",
          }),
        );
      } else {
        const url = VisitWidget.GetEditModeEntityUrl.call(true);
        VisitWidget.flyoutLoader.navigateTo(url);
      }
    } else {
      planItem = {
        plannable_id: plannableId,
        plannable_type: plannableType,
      };
      VisitWidget.store.removePlanItems([planItem]);
    }

    planItem = {
      plannable_id: plannableId,
      plannable_type: plannableType,
    };

    VisitWidget.PlanItemControl.updatePlanItemControlsToAddState(planItem);

    if (!VisitWidget.store.globalEditMode.enabled) {
      VisitWidget.EventBus.publish(new VisitWidget.Events.PlanItemRemoved());
    }
  }
};
