/* tslint:disable */
/* eslint:disable */

VisitWidget.Util = class Util {
  snakeToCamel(text: string) {
    const camelText = text.replace(/_\w/g, (m) => m[1].toUpperCase());
    return camelText;
  }

  camelToSpaces(text: string) {
    text = text.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
    text = text.toLowerCase(); // add space between camelCase text
    return text;
  }

  hyphenate(text: string) {
    text = this.camelToSpaces(text);
    text.replace(/_/g, "-").toLowerCase();
    return text.replace(/ +/g, "-").toLowerCase();
  }

  toCamel(text: string) {
    return text
      .replace(/\-/g, " ") // convert all hyphens to spaces
      .replace(/\-/g, " ") // convert all undescores  to spaces
      .replace(/\s[a-z]/g, this.upperCase) // capitalize 1st letter of each word
      .replace(/\s+/g, "") // remove spaces
      .replace(/^[A-Z]/g, this.lowerCase); // convert first char to lowercase
  }

  upperCase(text: string) {
    return text.toUpperCase();
  }

  lowerCase(text: string) {
    return text.toLowerCase();
  }

  getQueryString() {
    const parts = location.search.split("?");
    if (parts.length < 2) {
      return null;
    }
    return parts[1];
  }

  addCurrentQueryStringToUrl(url: string) {
    const queryString = VisitWidget.util.getQueryString();
    if (!queryString) {
      return url;
    }

    if (url.indexOf("?") > -1) {
      return url + "&" + queryString;
    } else {
      return url + "?" + queryString;
    }
  }
};

VisitWidget.util = new VisitWidget.Util();

const isDefined = (value: any) => {
  return value !== null && value !== undefined;
};

export { isDefined };
