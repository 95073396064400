VisitWidget.KioskController = class KioskController {
  lastActivityTimestamp: number;
  countdownSeconds: number;
  pageRefreshSeconds: number;
  idleRefreshSeconds: number;
  intervalTimer: number;
  isCountdown: boolean;
  isAnalyticsInitialized: boolean;
  pageInitializedTimestamp: number;
  countdownCurrentSecond: number;

  constructor() {
    this.uninitialize = this.uninitialize.bind(this);
    this.onIntervalTimer = this.onIntervalTimer.bind(this);
    this.onUserActivity = this.onUserActivity.bind(this);
    this.closeCountDownModal = this.closeCountDownModal.bind(this);
    this.reset = this.reset.bind(this);
    this.onCurrentMenuItemChanged = this.onCurrentMenuItemChanged.bind(this);
    this.openKioskMenu = this.openKioskMenu.bind(this);
    this.countdownSeconds = 10;
    this.pageRefreshSeconds = 60 * 60; // 1 hr
    this.idleRefreshSeconds =
      VisitWidget.settings.kioskSecondsBeforeSessionReset;
  }

  initialize(): void {
    this.setupButtonEvents();
    this.setupActivityEvents(true);
    this.intervalTimer = window.setInterval(this.onIntervalTimer, 1000);
    this.isCountdown = false;
    this.isAnalyticsInitialized = false;
    this.pageInitializedTimestamp = Date.now();

    VisitWidget.EventBus.subscribe(
      "CurrentMenuItemChanged",
      this.onCurrentMenuItemChanged,
    );
    if (VisitWidget.settings.isKioskHomeMenuEnabled) {
      this.openKioskMenu();
    }
  }

  uninitialize(): void {
    this.setupActivityEvents(false);
    window.clearInterval(this.intervalTimer);
  }

  onIntervalTimer(): void {
    const now = Date.now();
    if (
      this.lastActivityTimestamp === undefined &&
      (now - this.pageInitializedTimestamp) / 1000 > this.pageRefreshSeconds
    ) {
      // refresh kiosk at least once an hour to bring in new data if no user
      // activity, countdown handles case with user activity
      this.reset();
    }

    if (
      this.lastActivityTimestamp !== undefined &&
      (now - this.lastActivityTimestamp) / 1000 > this.idleRefreshSeconds &&
      !this.isCountdown
    ) {
      // the page has been idle since last user activity, show countdown
      this.countdownCurrentSecond = this.countdownSeconds;
      this.isCountdown = true;
      $(".kiosk-session-countdown").css("visibility", "visible");
    }

    if (this.isCountdown) {
      if (this.countdownCurrentSecond < 0) {
        this.reset();
      } else {
        // update countdown seconds display
        $(".kiosk-session-countdown-number").html(
          String(this.countdownCurrentSecond),
        );
        --this.countdownCurrentSecond;
      }
    }
  }

  setupActivityEvents(add: boolean = true): void {
    const eventFunction = add ? "addEventListener" : "removeEventListener";
    document[eventFunction]("mousemove", this.onUserActivity, false);
    document[eventFunction]("mousedown", this.onUserActivity, false);
    document[eventFunction]("keypress", this.onUserActivity, false);
    document[eventFunction]("touchmove", this.onUserActivity, false);
  }

  setupButtonEvents(): void {
    $(".kiosk-session-countdown-reset").click(this.reset);
    $(".kiosk-session-countdown-continue").click(this.closeCountDownModal);
    $(".open-kiosk-menu").click(this.openKioskMenu);
  }

  onUserActivity(): void {
    this.lastActivityTimestamp = Date.now();
    if (this.isAnalyticsInitialized === false) {
      // kiosk Google Analytics is only initiated once there is a user
      // interacting with the site
      this.isAnalyticsInitialized = true;
      // (window as any).setUpGoogleAnalytics();
    }
  }

  closeCountDownModal(): void {
    $(".kiosk-session-countdown").css("visibility", "hidden");
    this.isCountdown = false;
  }

  reset(): void {
    console.log("VisitWidget.KioskController.reset");
    this.uninitialize();
    console.log("VisitWidget.kiosk.commands.resetKiosk");
    VisitWidget.kiosk.commands.resetKiosk();
  }

  // Clicking item from kiosk overlay triggers this,
  // causes kiosk overlay to hide
  onCurrentMenuItemChanged(): void {
    $("#kiosk_menu").hide();
    $("body").removeClass("has-kiosk-menu");
  }

  // Clicking "Home" at top of sidebar triggers this,
  // causes kiosk overlay to appear
  openKioskMenu(): void {
    $("#kiosk_menu").show();
    $("body").addClass("has-kiosk-menu");
  }
};
