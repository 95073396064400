/* tslint:disable */
/* eslint:disable */

import { setCookie } from "../../lib/cookies";

/* eslint-disable react-func/max-lines-per-function */
VisitWidget.PlanItem = class PlanItem {
  static query(planId: any, callback: (arg0: any) => void) {
    return $.ajax({
      url: `/plans/${planId}/plan_items.json`,
      type: "GET",
      success(planItems) {
        return callback(planItems);
      },
    });
  }

  static create(
    plannableId: any,
    plannableType: any,
    callback: (arg0: any) => void,
  ) {
    let rootUrl;
    if (VisitWidget.store.globalEditMode.enabled) {
      rootUrl = VisitWidget.GetEditModeEntityUrl.call();
    } else if (VisitWidget.store.currentPlanId !== null) {
      rootUrl = `/plans/${VisitWidget.store.currentPlanId}`;
    } else {
      VisitWidget.Plan.create((plan: any) => {
        VisitWidget.store.currentPlanId = plan.id;
        return setCookie("planId", plan.id).then(() => {
          return VisitWidget.PlanItem.create(
            plannableId,
            plannableType,
            callback,
          );
        });
      });
      return;
    }

    return $.ajax({
      url: `${rootUrl}/plan_items`,
      type: "POST",
      data: {
        plan_item: {
          plannable_id: plannableId,
          plannable_type: plannableType,
        },
      },
      success: (data) => {
        if (!!callback) {
          return callback(data);
        }
      },
    });
  }

  static delete(planItemId: any, callback: (arg0: any) => void) {
    let rootUrl;
    if (VisitWidget.store.globalEditMode.enabled) {
      rootUrl = VisitWidget.GetEditModeEntityUrl.call();
    } else {
      rootUrl = `/plans/${VisitWidget.store.currentPlanId}`;
    }

    return $.ajax({
      url: `${rootUrl}/plan_items/${planItemId}`,
      type: "DELETE",
      success: (data) => {
        if (!!callback) {
          return callback(data);
        }
      },
    });
  }
};
