/* eslint-disable @typescript-eslint/no-explicit-any */
import { setFlyout } from "../flyoutActionTypes";

/*
 * Set the Flyout contents.
 */
const setFlyoutAction =
  (component: any = undefined, props: any = undefined) =>
  async (dispatch) => {
    if (component !== undefined) {
      // perform some legacy actions to close a flyout if its open and unset the current path
      VisitWidget.mainFlyoutController.close();
    }

    dispatch(setFlyout(component, props));
  };

export { setFlyoutAction };
