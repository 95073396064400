import { getCookie, setCookie, CookieKeys } from "../../lib/cookies";

VisitWidget.User = class User {
  static addCategory = async (category_id: any, callback: any) => {
    const categoryIds = await getCookie<number[]>(
      CookieKeys.categoryIds,
      [],
      categoryIdCookieToArray,
    );
    if (categoryIds.indexOf(category_id) === -1) {
      categoryIds.push(category_id);
    }
    await this.setCategoryIdsToCookie(categoryIds);

    if (this.shouldModifyAuthenticatedUserCategories()) {
      return $.ajax({
        url: "/user_categories",
        type: "POST",
        data: {
          category_id,
        },
        success(user_category) {
          return callback(user_category);
        },
      });
    } else {
      if (callback != null) {
        return callback();
      }
    }
  };

  static removeCategory = async (category_id: any, callback: any) => {
    const categoryIds = await getCookie<number[]>(
      CookieKeys.categoryIds,
      [],
      categoryIdCookieToArray,
    );

    const categoryIndex = categoryIds.indexOf(category_id);
    categoryIds.splice(categoryIndex, 1);
    await this.setCategoryIdsToCookie(categoryIds);

    if (this.shouldModifyAuthenticatedUserCategories()) {
      return $.ajax({
        url: "/user_categories/remove_by_category",
        type: "DELETE",
        data: {
          category_id,
        },
        success() {
          return callback();
        },
      });
    } else {
      if (callback != null) {
        return callback();
      }
    }
  };

  static addAllCategories = async (callback) => {
    await this.setCategoryIdsToCookie(VisitWidget.store.categoryIds);
    if (this.shouldModifyAuthenticatedUserCategories()) {
      return $.ajax({
        url: "/user_categories/add_all",
        type: "POST",
        success() {
          if (callback != null) {
            return callback();
          }
        },
      });
    } else {
      if (callback != null) {
        return callback();
      }
    }
  };

  static removeAllCategories = async (callback: any) => {
    await this.setCategoryIdsToCookie([]);
    if (this.shouldModifyAuthenticatedUserCategories()) {
      return $.ajax({
        url: "/user_categories/remove_all",
        type: "DELETE",
        success() {
          if (callback != null) {
            return callback();
          }
        },
      });
    } else {
      if (callback != null) {
        return callback();
      }
    }
  };

  static setCategoryIdsToCookie = async (categoryIds: any): Promise<void> => {
    return setCookie("category_ids", JSON.stringify(categoryIds), 365);
  };

  static shouldModifyAuthenticatedUserCategories() {
    return (
      VisitWidget.store.currentUserId &&
      !VisitWidget.settings.resetCategoriesOnNavigation
    );
  }
};

const categoryIdCookieToArray = (value: string) => {
  return JSON.parse(value);
};
