import { setMenuItemAction } from "../../store/menuItem/actions/setMenuItemAction";

VisitWidget.NavigationController = class NavigationController {
  constructor() {
    this.onNavigation = this.onNavigation.bind(this);
    this.openClientLink = this.openClientLink.bind(this);
  }

  initialize() {
    this.removeInitialListLoadStyling();
    this.resize();

    this.wireEvents();
    return this.setupTabOrder();
  }

  removeInitialListLoadStyling() {
    return $("#main_sidebar .initial_list_load").removeClass(
      "initial_list_load",
    );
  }

  wireEvents() {
    $(".list-menu-item").on("click", (event) => {
      event.preventDefault();
      const $target = $(event.target).closest("li");
      this.onNavigation($target);
    });

    $(document).on(
      "click.registration-trigger",
      "#main_sidebar .btn.create, #header nav .create",
      function () {
        $(".show_entity .close").click();
        VisitWidget.modal.open($(".modal.create"), null, () => {
          return VisitWidget.createModal.reset();
        });
      },
    );

    return $(document).on(
      "click.registration-trigger",
      "#header nav .create",
      "click",
      () =>
        // mobile only
        VisitWidget.modal.open($(".modal.create"), null, () => {
          return VisitWidget.createModal.reset();
        }),
    );
  }

  currentEntity() {
    return $(
      "#main_sidebar .nav li.active a, #header .navigation_mobile .active",
    ).data("entity");
  }

  navigateToMenuItem(newMenuItem: {
    // below is only a partial of the data, includes more properties
    id: number;
    entity: "Events" | "Goal" | "Places" | "SharedPlan" | "Tour";
  }) {
    let resetPromise;
    VisitWidget.settings.refreshMapMarkersDisabled = false;
    const $menuItem = $(`.list-menu-item[data-menu-item-id=${newMenuItem.id}]`);
    const originalMenuItem = VisitWidget.store.currentMenuItem;
    VisitWidget.store.currentMenuItem = newMenuItem;
    originalMenuItem.active_category_ids =
      originalMenuItem.category_ids.slice(0);
    VisitWidget.settings.shouldUseSegmentedViewCategories = false;

    if (!VisitWidget.store.globalEditMode.enabled) {
      VisitWidget.mainFlyoutController.close();
    }

    this.toggleMainSideBarVisibility(newMenuItem);
    this.changeActiveStateToMenuItem($menuItem, newMenuItem);
    this.setupDropDownNavigationMenu(newMenuItem.id);
    this.setupMobileFooter(newMenuItem);
    this.toggleDateRangeControl(newMenuItem);
    this.turnOffStartPlanMode(originalMenuItem);
    this.setupFeedPostList(newMenuItem);
    VisitWidget.map.updateTextOverLayPosition();

    if (!VisitWidget.settings.isMobile) {
      this.setupSidebarScrolling();
    }

    VisitWidget.categoryFilterControl.updateCategoryVisibility();

    if (
      VisitWidget.settings.resetCategoriesOnNavigation &&
      !VisitWidget.settings.isSegmentedView
    ) {
      resetPromise =
        VisitWidget.categoryFilterControl.resetCategorySelections();
    } else {
      resetPromise = VisitWidget.categoryFilterControl
        .resetOffByDefaultCategories()
        .then(() => {
          return VisitWidget.categoryFilterControl.updateCheckboxesWithCurrentCategoryIds();
        });
    }

    (window as any).globalDispatch(setMenuItemAction(newMenuItem.entity));

    return resetPromise.then(() => {
      VisitWidget.EventBus.publish(
        new VisitWidget.Events.CurrentMenuItemChanged(
          originalMenuItem,
          newMenuItem,
        ),
      );

      return this.updateGoogleAnalytics();
    });
  }

  onNavigation($menuItem): void {
    const clientLinkId = $menuItem.data("client-link-id");
    if (clientLinkId !== undefined) {
      this.openClientLink($menuItem);
      return;
    }
    let newMenuItemId = $menuItem.data("menu-item-id");
    if (newMenuItemId === undefined) {
      return;
    }
    newMenuItemId = parseInt(newMenuItemId);
    const newMenuItem = VisitWidget.store.menuItems.filter(
      (m) => m.id === newMenuItemId,
    )[0];
    this.navigateToMenuItem(newMenuItem);
  }

  openClientLink($menuItem) {
    return VisitWidget.modal.open(
      $(".modal.web_view"),
      () => {
        const url = $menuItem.find("a").attr("href");
        const title = $menuItem.find("a").text();
        $(".modal.web_view .header h2").text(title);
        return $(".modal.web_view iframe").attr("src", url);
      },
      () => {
        return $(".modal.web_view iframe").removeAttr("src");
      },
    );
  }

  changeActiveStateToMenuItem($menuItem, newMenuItem) {
    $(".list-menu-item").removeClass("active");
    $("#main_sidebar .scrollable .container div").removeClass("active");
    $menuItem.addClass("active");
    return $(`#sidebar_${newMenuItem.entity}${newMenuItem.id}`).addClass(
      "active",
    );
  }

  setupMobileFooter(newMenuItem) {
    if (!VisitWidget.settings.isMobile) {
      return;
    }

    if (
      VisitWidget.settings.disableLists &&
      newMenuItem.entity === "PlanItem"
    ) {
      $(".footer-nav").show();
      $(".footer-nav-map").addClass("active");
      $(".footer-nav-list").removeClass("active");
    } else if (
      VisitWidget.settings.disableLists ||
      newMenuItem.entity === "FeedPost"
    ) {
      $(".footer-nav").hide();
      $(".footer-nav-map").addClass("active");
      $(".footer-nav-list").removeClass("active");
    } else {
      $(".footer-nav").show();
    }

    return this.setMobileFooterMapText(newMenuItem);
  }

  toggleMainSideBarVisibility(newMenuItem) {
    if (
      VisitWidget.global.shouldDisableMainSidebar(newMenuItem) ||
      (VisitWidget.settings.isMobile && VisitWidget.settings.disableLists)
    ) {
      return $("#main_sidebar").hide();
    } else if (!VisitWidget.settings.isMobile) {
      return $("#main_sidebar, #main_sidebar_content_container").show();
    }
  }

  toggleDateRangeControl(newMenuItem) {
    if (newMenuItem.entity === "Event") {
      return $("#main_sidebar .time_frame").show();
    } else {
      return $("#main_sidebar .time_frame").hide();
    }
  }

  setupDropDownNavigationMenu(newMenuItemId) {
    if (VisitWidget.settings.isMobile || VisitWidget.settings.isSegmentedView) {
      const listName = $(
        `.list-menu-item[data-menu-item-id=${newMenuItemId}]`,
      ).html();
      return $(`#menu_item_dropdown .content, \
.navigation_mobile .content`).html(listName);
    }
  }

  setupFeedPostList(newMenuItem) {
    if (newMenuItem.entity === "FeedPost") {
      $("#main_sidebar #new-post-wrapper").show();
      return this.resize();
    } else {
      $("#main_sidebar #new-post-wrapper").hide();
      return $(window).resize();
    }
  }

  turnOffStartPlanMode(originalMenuItem) {
    if (originalMenuItem.entity === "PlanItem") {
      VisitWidget.planItemListController.startPlanModeOn = false;
      return VisitWidget.planItemListController.turnOffStartPlanMode();
    }
  }

  updateGoogleAnalytics() {
    const pathName = VisitWidget.store.currentMenuItem.analytics_path;
    if (
      pathName === null ||
      typeof pathName === "undefined" ||
      pathName.length < 0
    ) {
      throw "List does not have a pathName to be tracked by analytics";
    }
    return VisitWidget.Analytics.createPageview(`${pathName}`);
  }

  resize() {
    $(window).resize(() => {
      const menuItem = VisitWidget.store.currentMenuItem;
      if (VisitWidget.global.shouldDisableMainSidebar(menuItem)) {
        return;
      }
      if (menuItem.entity === "SharedPlan") {
        const container = $(".shared-plan-screen-container");
        const windowHeight = $(window).outerHeight();
        const mainScrollableDivTop = container.offset().top;
        container.css({ height: windowHeight - mainScrollableDivTop });
        return;
      }
      // second selector is for the plan list b/c it has that frozen header
      const $mainScrollableDiv = $(
        "#main_sidebar .container .nano.active, #main_sidebar .container .nano.active, #main_sidebar .container .active .nano",
      );
      const windowHeight = $(window).outerHeight();
      const mainScrollableDivTop = $mainScrollableDiv.offset().top;
      $mainScrollableDiv.css({ height: windowHeight - mainScrollableDivTop });
    });
    $(window).resize();
  }

  setupSidebarScrolling() {
    if (!VisitWidget.settings.isMobile) {
      // second selector is for the plan list
      return (
        $("#main_sidebar .container").find(".nano.active, .active .nano") as any
      ).nanoScroller({ tabIndex: -1 });
    }
  }

  setMobileFooterMapText(menuItem) {
    // this logic also exists on the server side in _footer_nav.html.erb
    if (menuItem.entity === "PlanItem") {
      return $(".footer-nav-map .text").text("Plan Map");
    } else {
      return $(".footer-nav-map .text").text("Map");
    }
  }

  navigateToMenuItemByEntity(entityName): void {
    const menuItem = VisitWidget.store.menuItems.filter(
      (m) => m.entity === entityName,
    )[0];
    this.navigateToMenuItem(menuItem);
  }

  setupTabOrder() {
    $("#search_name, .nav .list-menu-item a, #map_link_container a").attr(
      "tabindex",
      0,
    );
    return $(".modal").find("[tabindex],a,input,button").attr("tabindex", -1);
  }
};
