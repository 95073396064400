import { getQueryStringValue } from "../../../lib/url";

VisitWidget.TourMapItControl = class TourMapItControl extends (
  VisitWidget.MapItControl
) {
  constructor(containerSelector, tourId, options) {
    super(containerSelector, options);
    this.tourId = tourId;
  }

  handleMapLoad() {
    this.showMapItLocation($(this.containerSelector));
  }

  initialize(markerIds): void {
    this.markerIds = markerIds;
    super.initialize(null);
    this.mapRoutingControl = new VisitWidget.MapRoutingControl(
      this.containerSelector,
      { includeMap: true },
    );
    this.directionsData = null;

    // if there is an explicit bounds defined in active admin, center to that
    // extent rather than the map makers
    const bounds = $(this.containerSelector).data("bounds");
    if (!!bounds) {
      this.bounds = new google.maps.LatLngBounds();
      const extent = bounds.split(",");
      const southWest = extent[0].split("|").map((x) => x.replace('"', ""));
      const northEast = extent[1].split("|").map((x) => x.replace('"', ""));
      this.bounds.extend(new google.maps.LatLng(southWest[0], southWest[1]));
      this.bounds.extend(new google.maps.LatLng(northEast[0], northEast[1]));
    }

    if (
      VisitWidget.settings.isMobile &&
      Boolean(getQueryStringValue("show_map_it"))
    ) {
      // show map it view by default when query string present
      const self = this;
      if (VisitWidget.map.mapLoaded) {
        setTimeout(() => {
          self.handleMapLoad();
        }, 500);
      } else {
        VisitWidget.EventBus.subscribe("MapLoaded", () => {
          setTimeout(() => {
            self.handleMapLoad();
          }, 500);
        });
      }
    }
  }

  addDirectionsData(directionsData): void {
    this.directionsData = directionsData;
  }

  showMapItLocation($mapIt, options = null): void {
    if (!VisitWidget.settings.tourShouldRenderOtherToursOnMapMove) {
      VisitWidget.settings.refreshMapMarkersDisabled = true;
    }

    if (!options) {
      options = { keepFlyoutOpen: false };
    }

    VisitWidget.settings.disableOnMapGeoBoxChangedHandler = true;
    let mapLayerUrl = null;
    if ($mapIt) {
      mapLayerUrl = $mapIt.data("entity-map-layer-url");
    }
    $(".marker-label").remove();
    VisitWidget.map.clearMarkers();

    if (
      !VisitWidget.settings.disableLists &&
      !VisitWidget.settings.isMobile &&
      !options.keepFlyoutOpen
    ) {
      VisitWidget.mainFlyoutController.close();
    }

    if (VisitWidget.settings.isMobile) {
      this.showMobileMapModal($mapIt, () => {
        this.showTourMarkersAndRoutesAndMapLayer(mapLayerUrl);
      });
    } else {
      this.showTourMarkersAndRoutesAndMapLayer(mapLayerUrl);
    }
  }

  showTourMarkersAndRoutesAndMapLayer(mapLayerUrl: string): void {
    this.showTourMarkersAndRoutes({
      shouldCenter: !mapLayerUrl || !!this.bounds,
    });
    VisitWidget.map.loadSubPageMapLayer(mapLayerUrl, "listItem", !this.bounds);
  }

  showTourMarkersAndRoutes(options: { shouldCenter: boolean }): void {
    VisitWidget.map.markerRouteNumber = 1;
    VisitWidget.map.useMarkerWithLabel = true;

    this.isDirectionsDataRequestComplete = false;
    this.isMapMarkerRequestComplete = false;

    if (
      this.directionsData === null &&
      !VisitWidget.settings.adminModeOn &&
      this.options.routingEnabled
    ) {
      VisitWidget.Tour.getDirectionsData(this.tourId, (directionsData) => {
        this.directionsData = directionsData;
        this.isDirectionsDataRequestComplete = true;
        this.createTourRoutes(options);
      });
    } else {
      this.isDirectionsDataRequestComplete = true;
    }

    VisitWidget.map.addEntityLocations("Tour", this.tourId, () => {
      VisitWidget.map.useMarkerWithLabel = false;
      if (options.shouldCenter) {
        if (!!this.bounds) {
          VisitWidget.map.centerAndFitToBounds(this.bounds);
        } else {
          VisitWidget.map.centerMapOnMarkers();
        }
      }
      this.mapMarkers = this.getMapMarkersForRouting();
      this.isMapMarkerRequestComplete = true;
      this.createTourRoutes(options);
    });
  }

  createTourRoutes(options): void {
    if (
      !this.isMapMarkerRequestComplete ||
      !this.isDirectionsDataRequestComplete ||
      !this.options.routingEnabled
    ) {
      return;
    }

    // when a tour is selected the map gets two different commands to pan/zoom.
    // before `@bounds` this was duplicative and didn't affect anything, but with
    // `@bounds` ensure it isn't immediately overridden by mapMarkers extent
    const shouldCenter = options.shouldCenter && !this.bounds;

    if (this.directionsData === null) {
      this.mapRoutingControl.createRoutesFromMapMarkers(
        this.mapMarkers,
        shouldCenter,
      );
    } else {
      this.mapRoutingControl.createRoutesFromDirectionsData(
        this.directionsData,
        this.mapMarkers.length,
        this.mapMarkers,
        shouldCenter,
      );
    }
  }

  getMapMarkersForRouting() {
    const mapMarkers = [];
    for (let markerId of this.markerIds) {
      let i = 0;
      while (i < Gmaps.store.markers.length) {
        if (markerId === Gmaps.store.markers[i].serviceObject.markerId) {
          mapMarkers.push(Gmaps.store.markers[i]);
        }
        i++;
      }
    }

    return mapMarkers;
  }

  showMobileMapModal($mapIt, onOpen: () => void): void {
    const $mapItData = $mapIt.parents(".map-it-data");
    $(".modal.map .actions").remove();
    $(".modal.map .header h2").text($mapItData.data("title"));
    VisitWidget.mainFlyoutController.moveToBackground();
    VisitWidget.Mobile.map.show(
      () => {
        VisitWidget.Analytics.createPageview("/map_it");
        onOpen();
      },
      () => {
        VisitWidget.mainFlyoutController.moveToForeground();
      },
    );
  }
};
