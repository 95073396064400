import { PlanItemCreateDto } from "../../apiTypes";
import { RootReducerState } from "../../rootReducer";
import { listPlanItems } from "../../visitWidgetApi";
import { setPlan } from "../planActionTypes";

/*
 * Add item to user plan.
 */
const addPlanItemAction =
  (plannableId: number, type: "Place" | "Event") =>
  async (dispatch, getState) => {
    const state = getState() as RootReducerState;
    // TODO: don't use legacy code to call endpoint
    const planItemResponse = await new Promise<PlanItemCreateDto>((resolve) => {
      VisitWidget.PlanItem.create(plannableId, type, (response) => {
        resolve(response);
      });
    });

    const updatedPlan = { ...state.plan.plan };
    updatedPlan.id = planItemResponse.plan_item.parent_id;

    // the plan items returned by add to tour are a minimal version, refetch plan items to get
    // the full list data
    const planItems = await listPlanItems(updatedPlan.id);
    updatedPlan.plan_items = planItems;

    VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState(
      planItemResponse.plan_item,
    );

    VisitWidget.EventBus.publish(new VisitWidget.Events.PlanItemAdded());

    dispatch(setPlan(updatedPlan));
  };

export { addPlanItemAction };
