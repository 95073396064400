import * as types from "../../lib/types";

export default function shouldUseEventMarkerColor(
  category: types.Category | undefined,
  entities: Array<types.Entity>,
): boolean {
  return (
    !!category &&
    category.event_map_pin_color !== category.color &&
    entities.some((entity) => !!entity["type"] && entity["type"] === "Event")
  );
}
