import { Plan, PlanItem } from "../../../lib/apiTypes";

/* eslint-disable  */
interface LatitudeLongitude {
  latitude: number;
  longitude: number;
}

interface RoutesResponse {
  legs: {
    coordinate: LatitudeLongitude;
    durationSeconds: number;
    distanceMeters: number;
  }[];
  totalDistanceMeters: number;
  totalDurationSeconds: number;
}

const routesCache = new Map<string, RoutesResponse>();

const createRoutes = async (
  latLngs: LatitudeLongitude[],
): Promise<RoutesResponse | undefined> => {
  if (latLngs.length === 0) {
    return undefined;
  }

  const coordinates = [] as LatitudeLongitude[];
  latLngs.forEach((x) => {
    const existingIndex = coordinates.findIndex(
      (y) => y.latitude === x.latitude && y.longitude === x.longitude,
    );
    if (existingIndex < 0) {
      coordinates.push(x);
    }
  });

  const cacheKey = coordinates.reduce((accumulator, current) => {
    accumulator += `${current.latitude},${current.longitude}|`;
    return accumulator;
  }, "");

  if (routesCache.has(cacheKey)) {
    return routesCache.get(cacheKey);
  }

  const directionsResult = await makeDirectionRequests(coordinates);
  // TODO: support longer requests, eg directions[1]
  const directions = directionsResult[0].routes[0].legs.map((x, index) => {
    return {
      durationSeconds: x.duration.value,
      distanceMeters: x.distance.value,
      coordinate: coordinates[index],
    };
  });

  const result = {
    totalDistanceMeters: directions.reduce(
      (sum, x) => sum + x.distanceMeters,
      0,
    ),
    totalDurationSeconds: directions.reduce(
      (sum, x) => sum + x.durationSeconds,
      0,
    ),
    legs: directions,
  };

  routesCache.set(cacheKey, result);
  return result;
};

const makeDirectionRequests = async (
  coordinates: LatitudeLongitude[],
): Promise<google.maps.DirectionsResult[]> => {
  const batches = createBatches(coordinates);
  const directionResults = await Promise.all(
    batches.map(async (batch) => {
      let waypoints = [] as LatitudeLongitude[];
      const origin = batch[0];
      if (batch.length > 2) {
        waypoints = batch.slice(1, batch.length - 1);
      }

      const destination = batch[batch.length - 1];

      const directionsRequest = createDirectionRequest(
        origin,
        waypoints,
        destination,
      );

      const directionsService = new google.maps.DirectionsService();
      return directionsService.route(directionsRequest);
    }),
  );

  return directionResults;
};

const createBatches = (coordinates: LatitudeLongitude[]) => {
  const batchLimit = VisitWidget.settings.maximumNumberOfRouteLocations;

  const batches = [] as Array<Array<LatitudeLongitude>>;
  batches[0] = [];
  let batchIndex = 0;
  for (let i = 0; i < coordinates.length; i++) {
    const coordinate = coordinates[i];
    batches[batchIndex].push(coordinate);
    if (
      batches[batchIndex].length === batchLimit &&
      i + 1 < coordinates.length
    ) {
      batchIndex += 1;
      batches[batchIndex] = [coordinate];
    }
  }

  return batches;
};

const createDirectionRequest = (
  origin: LatitudeLongitude,
  waypoints: LatitudeLongitude[],
  destination: LatitudeLongitude,
): google.maps.DirectionsRequest => {
  const directionsRequest = {
    origin: new google.maps.LatLng(origin.latitude, origin.longitude),
    destination: new google.maps.LatLng(
      destination.latitude,
      destination.longitude,
    ),
    provideRouteAlternatives: false,
    travelMode: google.maps.TravelMode[VisitWidget.settings.mapTravelMode],
    waypoints: [] as { location: google.maps.LatLng; stopover: boolean }[],
  };

  for (let wayPoint of Array.from(waypoints)) {
    const directionsWaypoint = {
      location: new google.maps.LatLng(wayPoint.latitude, wayPoint.longitude),
      stopover: true, // no stopovers would create routes avoiding u-turns
    };

    directionsRequest["waypoints"].push(directionsWaypoint);
  }

  return directionsRequest;
};

const createRoutesForSharedPlan = async (plan: Plan) => {
  const routes = await createRoutesForPlanItems(plan.planItems);
  return routes;
};

const createRoutesForPlanItems = async (planItems: PlanItem[]) => {
  const routes = await createRoutes(
    planItems
      .filter((x) => !!x.originalDto.plannable.location)
      .map((x) => {
        return {
          latitude: x.originalDto.plannable.location.latitude,
          longitude: x.originalDto.plannable.location.longitude,
        };
      }),
  );
  return routes;
};

const getDrivingSummaryFromRoutes = (routes?: RoutesResponse) => {
  if (!routes) {
    return {
      distance: undefined,
      durationMinutes: undefined,
      stopsCount: undefined,
    };
  }
  return {
    distance: routes.totalDistanceMeters,
    durationMinutes: routes.totalDurationSeconds / 60,
    stopsCount: routes.totalDistanceMeters === 0 ? 1 : routes.legs.length + 1,
  };
};

type PlanItemWithRoute = PlanItem & {
  durationSeconds?: number;
  distanceMeters?: number;
};

const mergePlanItemsAndRoutes = (
  planItems: PlanItemWithRoute[],
  routes: RoutesResponse,
): Array<PlanItemWithRoute> => {
  if (planItems.length === 0) {
    return [];
  }

  const result = [planItems[0]] as PlanItemWithRoute[];
  for (let i = 1, j = 0; i < planItems.length; ++i) {
    // clear any possible previous values
    planItems[i].durationSeconds = undefined;
    planItems[i].distanceMeters = undefined;

    if (
      (result[i - 1].latitude === planItems[i].latitude &&
        result[i - 1].longitude === planItems[i].longitude) ||
      (!planItems[i].latitude && !planItems[i].longitude)
    ) {
      // same location (same lat/lng) as previous plan item or virtual (without lat/lng), thus
      // there is no leg data between it and the last.
      result.push(planItems[i]);
    } else {
      result.push({
        ...planItems[i],
        durationSeconds: routes.legs[j]?.durationSeconds ?? 0,
        distanceMeters: routes.legs[j]?.distanceMeters ?? 0,
      });
      ++j;
    }
  }

  return result;
};

export {
  createRoutes,
  getDrivingSummaryFromRoutes,
  createRoutesForSharedPlan,
  createRoutesForPlanItems,
  RoutesResponse,
  mergePlanItemsAndRoutes,
  PlanItemWithRoute,
};
