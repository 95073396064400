/* eslint-disable max-lines */

// eslint-disable-next-line no-shadow
export enum MenuItemActionTypes {
  SET_MENU_ITEM = "SET_MENU_ITEM",
}

export interface SetMenuItemAction {
  type: MenuItemActionTypes.SET_MENU_ITEM;
  payload: {
    menuItemName: MenuItemNames;
  };
}

export type MenuItemNames =
  | "Events"
  | "Goal"
  | "Places"
  | "SharedPlan"
  | "Tour";

export const setMenuItem = (menuItemName: MenuItemNames): SetMenuItemAction => {
  return {
    type: MenuItemActionTypes.SET_MENU_ITEM,
    payload: {
      menuItemName: menuItemName,
    },
  };
};
