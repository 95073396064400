import React, { useCallback, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../../store/auth/actions/logoutAction";
import translate from "../../../../../lib/queries/translate";
import { selectUser } from "../../../../../store/auth/authSelectors";
import { withReduxProvider } from "../../../../../store/withReduxProvider";

// eslint-disable-next-line no-shadow
enum SignOutTestIds {
  signOutLink = "sign-out-link",
}

// only used for mobile view, should be deprecated when desktop and mobile share views
const SignOutBase: FunctionComponent<{ signOutImage: string }> = ({
  signOutImage,
}) => {
  const dispatch = useDispatch();
  const onSignOutClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(logoutAction() as any);
  }, [dispatch]);

  const user = useSelector(selectUser());
  if (!user) {
    // user is not logged in, display nothing
    return <></>;
  }
  return (
    <ul className="bottom relative">
      <li>
        <a data-testid={SignOutTestIds.signOutLink} onClick={onSignOutClick}>
          <img alt="" src={signOutImage} width="30" height="30"></img>
          <span>{translate("profile_menu.sign_out")}</span>
        </a>
      </li>
    </ul>
  );
};

const SignOut = withReduxProvider(SignOutBase);
export { SignOut };
