/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const AddCircleIcon = ({ size = 24, testID }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    data-testid={testID}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12.6875 11.3125H17.5C17.8795 11.3125 18.1875 11.6205 18.1875 12C18.1875 12.3795 17.8795 12.6875 17.5 12.6875H12.6875V17.5C12.6875 17.8795 12.3795 18.1875 12 18.1875C11.6205 18.1875 11.3125 17.8795 11.3125 17.5V12.6875H6.5C6.1205 12.6875 5.8125 12.3795 5.8125 12C5.8125 11.6205 6.1205 11.3125 6.5 11.3125H11.3125V6.5C11.3125 6.1205 11.6205 5.8125 12 5.8125C12.3795 5.8125 12.6875 6.1205 12.6875 6.5V11.3125Z"
      fill="currentColor"
    />
  </svg>
);

export { AddCircleIcon };
