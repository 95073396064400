VisitWidget.Mobile = VisitWidget.Mobile ?? {};
VisitWidget.Mobile.MapItControl = class MapItControl extends (
  VisitWidget.MapItControl
) {
  constructor(containerSelector, options) {
    super(containerSelector, options);
  }

  showMapItLocation($mapIt) {
    return VisitWidget.Commands.ShowMapItLocation.call($mapIt, false, () => {
      return this.resetMap($mapIt.data("marker-id"));
    });
  }

  resetMap(markerId) {
    let marker, markerServiceObject;
    for (marker of Array.from(Gmaps.store.markers)) {
      marker.infowindow.close();
    }

    /* Hide all visible markers except marker with markerId */
    if (markerId === "start_point") {
      markerServiceObject = Gmaps.store.startPointMarker.serviceObject;
    } else {
      for (marker of Array.from(Gmaps.store.markers)) {
        if (markerId === marker.serviceObject.markerId) {
          markerServiceObject = marker.serviceObject;
        } else {
          marker.serviceObject.setVisible(false);
        }
      }
    }

    return VisitWidget.map.centerMapOnMarker(markerServiceObject);
  }
};
