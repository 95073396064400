/* eslint-disable react-func/max-lines-per-function */
import { ChallengeSubmissionConfigFormState } from "./ChallengeSubmissionConfig";
import {
  ChallengeSubmissionConfigDto,
  CustomInputDto,
} from "../../../store/apiTypes";

const mapFormToUpsertRequest = (
  formValues: ChallengeSubmissionConfigFormState,
): ChallengeSubmissionConfigDto => {
  const mappedUpsertRequestObject = {
    completion_submission_form_enabled: formValues.formEnabled,
    completion_message: formValues.completionMessage,
    submission_custom_inputs: formValues.customInputs.map(
      (customInput, index) => {
        let updatedInput = {
          id: customInput.id,
          label: customInput.label,
          position: index + 1,
          required: customInput.required,
          input_type: customInput.input_type,
          values:
            typeof customInput.values === "string"
              ? new String(customInput.values).split(",")
              : customInput.values,
        } as CustomInputDto;
        return updatedInput;
      },
    ),
  } as ChallengeSubmissionConfigDto;

  return mappedUpsertRequestObject;
};

const mapDtoToForm = (
  existing?: ChallengeSubmissionConfigDto,
): ChallengeSubmissionConfigFormState => {
  const formValues = {
    formEnabled: existing?.completion_submission_form_enabled ?? true,
    completionMessage: existing?.completion_message ?? "",
    customInputs:
      existing?.submission_custom_inputs?.sort((x) => x.position) ?? [],
  } as ChallengeSubmissionConfigFormState;

  return formValues;
};

export { mapFormToUpsertRequest, mapDtoToForm };
