/* eslint-disable max-len */
import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "../../../store/auth/actions/loginAction";
import { withReduxProvider } from "../../../store/withReduxProvider";

// eslint-disable-next-line no-shadow
enum LoginTestIds {
  emailInput = "email-input",
  passwordInput = "password-input",
  submitInput = "submit-input",
  forgotPasswordLink = "forgot-password-link",
}

const LoginBase = () => {
  const emailInput = useRef<HTMLInputElement | null>(null);
  const passwordInput = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();

  const onForgotPasswordClick = useCallback((e) => {
    e.preventDefault();
    VisitWidget.registrationModal.showPasswordResetRequestView();
  }, []);

  const handleLogin = useCallback(() => {
    dispatch(
      loginAction({
        email: emailInput.current?.value,
        password: passwordInput.current?.value,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any,
    );
  }, [dispatch, emailInput, passwordInput]);

  const onKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13 || event.which === 13) {
        handleLogin();
      }
    },
    [handleLogin],
  );

  return (
    <>
      <div className="form-inputs">
        <label htmlFor="new_user_session_user_email">Email</label>
        <input
          id="new_user_session_user_email"
          data-testid={LoginTestIds.emailInput}
          className="email optional"
          type="text"
          name="new_user_session_user_email"
          ref={emailInput}
          onKeyPress={onKeyPress}
          tabIndex={0}
        />
        <label htmlFor="new_user_session_user_password">Password</label>
        <input
          id="new_user_session_user_password"
          data-testid={LoginTestIds.passwordInput}
          className="password optional"
          type="password"
          name="new_user_session_user_password"
          ref={passwordInput}
          onKeyPress={onKeyPress}
          tabIndex={0}
        />
      </div>

      <div className="form-actions">
        <input
          type="submit"
          data-testid={LoginTestIds.submitInput}
          name="commit"
          value="Sign In"
          className="btn email-sign-in"
          data-disable-with="Sign In"
          tabIndex={0}
          onClick={handleLogin}
        />
      </div>

      <p className="forgot-password-container">
        <a
          href="#"
          onClick={onForgotPasswordClick}
          className="forgot-password"
          data-testid={LoginTestIds.forgotPasswordLink}>
          Forgot Password
        </a>
      </p>

      <p className="login_small_note_text">
        By clicking &quot;Let&apos;s Go&quot; or &quot;Sign Up with
        Facebook&quot; you accept the
        <a
          target="_blank"
          href="https://www.visitwidget.com?terms_of_service"
          tabIndex={0}
          rel="noreferrer">
          Terms of Service
        </a>
        &nbsp;and&nbsp;
        <a
          target="_blank"
          href="https://www.visitwidget.com?privacy_policy"
          tabIndex={0}
          rel="noreferrer">
          Privacy Policy
        </a>
      </p>
    </>
  );
};

const Login = withReduxProvider(LoginBase);
export { Login, LoginTestIds };
