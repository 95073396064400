import { createEntityViewAnalytics } from "../lib/helpers/createEntityViewAnalytics";

VisitWidget.FlyoutLoader = class FlyoutLoader {
  previousFlyoutPaths: Array<string>;

  public initialize() {
    this.previousFlyoutPaths = [];
    if (this.isFlyoutOpen()) {
      this.addPreviousFlyoutPath(window.location.pathname);
    }
    return this.setupEvents();
  }

  private setupEvents() {
    this.setupFlyoutLinkClick();
    this.setupBrowserBackNavigation();
    return this.setupCloseEvents();
  }

  private setupFlyoutLinkClick() {
    return $(document).on("click", "a[data-push=true]", (e) => {
      e.preventDefault();
      const $target = $(e.target);

      if ($target.parents("#main_sidebar_flyout.open").length === 0) {
        this.resetPreviousFlyoutPaths();
      }

      const url = $target.closest("a").attr("href");
      this.navigateTo(url, false, true);

      return false;
    });
  }

  private setupBrowserBackNavigation() {
    // This only gets called with the browser back button is clicked.
    return window.addEventListener("popstate", (event) => {
      return this.goBack();
    });
  }

  private setupCloseEvents() {
    const $el = $("#main_sidebar_flyout");
    return $el.on("click", ".close", (event) => {
      event.preventDefault();
      this.goBack();
      return false;
    });
  }

  public close() {
    const url = VisitWidget.util.addCurrentQueryStringToUrl("/");
    if (VisitWidget.global.browserHistorySupport()) {
      window.history.pushState(null, null, url);
    }
    VisitWidget.mainFlyoutController.close();
    return this.resetPreviousFlyoutPaths();
  }

  private goBack() {
    $("#main_sidebar_flyout").removeClass("hideMapIt");
    if (this.previousFlyoutPaths.length > 1) {
      this.previousFlyoutPaths.pop();
      const url = this.previousFlyoutPaths[this.previousFlyoutPaths.length - 1];
      this.loadPage(url, () => {
        return $(".details_whos_going .selector a#tours_link").click();
      });
      VisitWidget.mainFlyoutController.moveToBackground();
      if (VisitWidget.global.browserHistorySupport()) {
        return window.history.pushState(null, null, url);
      }
    } else {
      return this.close();
    }
  }

  public reload() {
    return this.loadPage(window.location.href);
  }

  public loadPage(url: string, callback?: () => void) {
    VisitWidget.mainFlyoutController.showFlyout();

    return $.ajax({
      url,
      type: "GET",
      beforeSend(xhr) {
        return xhr.setRequestHeader("X-FlyoutLoader", "true");
      },
      success: (html) => {
        // Needed so keyboard closes on the kiosk. For some reason, would not
        // work in search_controller.js
        if (VisitWidget.settings.isKiosk) {
          $("input[type=text]").blur();
        }
        const $parent = $("#main_sidebar_flyout .container:first");
        $parent.html(html);
        VisitWidget.mainFlyoutController.setupScrolling();
        const resource = $("#main_sidebar_flyout .resource-identifier").data(
          "resource",
        );
        VisitWidget.initializeController(resource, true);
        this.sendToAnalytics(url);
        VisitWidget.EventBus.publish(new VisitWidget.Events.FlyoutLoaded());

        // render react on rails components, any react on rails components
        // not rendered on page load need to be explicitly rendered
        $parent.find(".js-react-on-rails-component").each(function () {
          return ReactOnRails.render(
            this.getAttribute("data-component-name"),
            JSON.parse(this.innerHTML),
            this.getAttribute("data-dom-id"),
            false,
          );
        });
        createEntityViewAnalytics();
        if (callback != null) {
          return callback();
        }
      },
    });
  }

  public isFlyoutOpen() {
    return (
      ($("#main_sidebar_flyout").hasClass("open") &&
        !$("#main_sidebar_flyout").hasClass("closing")) ||
      $(".drawer-open").length > 0
    );
  }

  private sendToAnalytics(url) {
    let path = url.replace("https://", "").replace("http://", "");
    path = path.replace(window.location.host, "");
    return VisitWidget.Analytics.createPageview(path);
  }

  private resetPreviousFlyoutPaths() {
    return (this.previousFlyoutPaths.length = 0);
  }

  private addPreviousFlyoutPath(path: string) {
    if (
      this.previousFlyoutPaths[this.previousFlyoutPaths.length - 1] !== path
    ) {
      return this.previousFlyoutPaths.push(path);
    }
  }

  public navigateTo(
    url: string,
    doNotAppendCurrentQueryString: boolean,
    keepHistory: boolean,
  ) {
    if (doNotAppendCurrentQueryString == null) {
      doNotAppendCurrentQueryString = false;
    }
    if (keepHistory == null) {
      keepHistory = false;
    }
    if (!doNotAppendCurrentQueryString) {
      url = VisitWidget.util.addCurrentQueryStringToUrl(url);
    }
    if (VisitWidget.global.browserHistorySupport()) {
      window.history.pushState(null, null, url);
    }
    this.loadPage(url);
    if (keepHistory) {
      this.addPreviousFlyoutPath(url);
    }
    return url;
  }
};
