/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const RemoveCircleIcon = ({ size = 24, testID }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    data-testid={testID}
    fill="none">
    <g id="Remove">
      <path
        id="Exclude"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM5.33333 11.5C4.87333 11.5 4.5 11.836 4.5 12.25C4.5 12.664 4.87333 13 5.33333 13H18.6667C19.1267 13 19.5 12.664 19.5 12.25C19.5 11.836 19.1267 11.5 18.6667 11.5H5.33333Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export { RemoveCircleIcon };
