import translate from "../../../../../lib/queries/translate";
import { Category } from "../../../../../lib/types";

export default function createOptions(
  categories: Array<Category>,
): Array<Record<string, unknown>> {
  const allOption = {
    label: translate("exclusive_category_filter.all"),
    value: null,
  };
  const options = [allOption].concat(
    categories.map((category) => ({
      label: category.name,
      value: category.id,
    })),
  );

  return options;
}
