import { MarkerData } from "../map/mapTypes";

export class Location {
  static mapLocations(
    baseParams: LocationParams,
    callback: (data: MarkerData[]) => void,
  ) {
    const { params, shouldUseMarkerWithLabel, shouldUseSmallMarkers } =
      getParams(baseParams);

    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          `/clients/${VisitWidget.settings.currentClientId}/cities/` +
          `${VisitWidget.settings.currentCityId}/locations/map_locations`,
        type: "GET",
        data: params,
        success(entities) {
          return VisitWidget.map.queries
            .getCompiledLocations(
              entities,
              shouldUseMarkerWithLabel,
              shouldUseSmallMarkers,
            )
            .then((compiledLocations: MarkerData[]) => {
              if (callback) {
                callback(compiledLocations);
              }
              resolve(compiledLocations);
            });
        },
      });
    });
  }
}

VisitWidget.Location = Location;

const getParams = (
  baseParams: LocationParams,
): {
  params: LocationExpandedParams;
  shouldUseMarkerWithLabel: boolean;
  shouldUseSmallMarkers: boolean;
} => {
  let params = { ...baseParams } as LocationExpandedParams;
  params.locale = I18n.locale;
  const type = params["type"];
  let shouldUseMarkerWithLabel = false;
  let shouldUseSmallMarkers = false;
  if (type === "Place") {
    params["cache_version"] = VisitWidget.settings.placesCacheVersion;
  } else if (type === "Event") {
    params["cache_version"] = VisitWidget.settings.eventsCacheVersion;
  } else if (type === "PlanItem") {
    params["cache_version"] = Date.now();
    shouldUseMarkerWithLabel = true;
    shouldUseSmallMarkers = !params["plan_id"];
  } else if (type === "Tour" && params["entity_id"] !== undefined) {
    // single tour for tour map it
    params["cache_version"] = VisitWidget.settings.toursCacheVersion;
    shouldUseMarkerWithLabel = true;
  } else {
    // Tours and FeedPosts for lists
    params["cache_version"] =
      VisitWidget.settings.eventsCacheVersion.toString() +
      `-${VisitWidget.settings.placesCacheVersion}`;
  }

  if (VisitWidget.settings.adminModeOn) {
    params["adminMode"] = `${VisitWidget.store.currentUserId}-${Date.now()}`;
  }

  if (VisitWidget.lib.isIe11()) {
    params["ie11"] = "true";
  }

  if (VisitWidget.settings.latitude !== null) {
    params["latitude"] = VisitWidget.settings.latitude;
    params["longitude"] = VisitWidget.settings.longitude;
    params["radius_miles"] = VisitWidget.settings.radiusMiles;
  }
  return { params, shouldUseMarkerWithLabel, shouldUseSmallMarkers };
};

interface LocationParams {
  type?: "Place" | "Event" | "Tour" | "PlanItem";
  page?: number;
  category_ids?: number[];
  locale?: "en" | "es";
  cache_version?: number | string;
  adminMode?: string;
  entity_id?: number | string;
}

interface LocationExpandedParams extends LocationParams {
  ie11: "true" | undefined;
}
