import React, { FunctionComponent } from "react";
import { PlanItem as PlanItemType } from "../../../lib/apiTypes";
import { PlanItem } from "../PlanItem";
import "./PlanItemList.scss";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { UserPlanAggregate } from "../../../store/apiTypes";
import { SortableItem } from "./SortableItem";
import { RoutesResponse } from "../../../domain/navigation/queries/getDirectionsForGeoCoordinates";

const PlanItemList: FunctionComponent<PlanItemListProps> = ({
  planItems,
  planToAddTo,
  tourId,
  mode,
  updatePlanItems,
}) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = planItems.findIndex((x) => x.id === active.id);
      const newIndex = planItems.findIndex((x) => x.id === over.id);
      const planItemsReordered = arrayMove(planItems, oldIndex, newIndex);
      updatePlanItems(planItemsReordered);
    }
  };

  return (
    <div className="plan-item-list-container">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext
          items={planItems}
          strategy={verticalListSortingStrategy}>
          {planItems.map((planItem, index) => (
            <SortableItem
              key={planItem.id}
              id={planItem.id}
              isSortEnabled={mode === "edit"}>
              <PlanItem
                planItem={planItem}
                stopNumber={index + 1}
                key={planItem.id}
                planToAddTo={planToAddTo}
                tourId={tourId}
                mode={mode}
              />
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface PlanItemListProps {
  planItems: Array<
    PlanItemType & { distanceMeters?: number; durationSeconds?: number }
  >;
  planToAddTo?: UserPlanAggregate;
  mode?: "summary" | "view" | "edit";
  tourId: number;
  updatePlanItems: (planItems: PlanItemType[]) => void;
  routes: RoutesResponse;
}

export { PlanItemList, PlanItemListProps };
