import { MenuItemNames, setMenuItem } from "../menuItemActionTypes";

const setMenuItemAction = (menuItem: MenuItemNames) => (dispatch, getState) => {
  dispatch(setMenuItem(menuItem));
  dispatch({
    type: "SET_FLYOUT",
    payload: { component: undefined, props: undefined },
  });
};

export { setMenuItemAction };
