let isControllerInitialized = false;

const viewAds = () => {
  $(".list-menu-item").removeClass("active");
  $("#main_sidebar .scrollable .container div").removeClass("active");
  $(".sidebar").removeClass("active");
  $("#sidebar_ad").addClass("active");
  VisitWidget.navigationController.resize();
  if (!isControllerInitialized) {
    VisitWidget.adListController = new VisitWidget.AdListController();
    VisitWidget.adListController.initialize();
    isControllerInitialized = true;
  }
  VisitWidget.adListController.populate();
};

export { viewAds };
