import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { SharedPlanList } from "../SharedPlanList";
import "./SharedPlanMenuScreen.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentMenuItem } from "../../../store/menuItem/menuItemSelectors";
import { selectSharedPlans } from "../../../store/plan/planSelectors";
import { listToursAction } from "../../../store/plan/actions/listToursAction";
import { AppDispatch } from "../../../store/store";

/**
 * Root of the Shared Plan menu item, rendered via React on Rails from erb view. Implicitly, must
 * be an admin to see.
 */
const SharedPlanMenuScreenBase: FunctionComponent<
  SharedPlanMenuScreenProps
> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentMenuItem = useSelector(selectCurrentMenuItem());
  const plans = useSelector(selectSharedPlans());

  useEffect(() => {
    if (currentMenuItem !== "SharedPlan") {
      return;
    }

    dispatch(listToursAction(VisitWidget.store.categoryIds));
  }, [currentMenuItem, dispatch]);

  const elementRef = useRef(null);
  const handleScroll = useCallback(() => {
    const element = elementRef.current;
    if (
      Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight,
      ) < 1
    ) {
      // LOAD MORE
      dispatch(listToursAction(VisitWidget.store.categoryIds));
    }
  }, [dispatch]);

  useEffect(() => {
    const element = elementRef.current;
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [elementRef, handleScroll]);

  return (
    // must always render the container for legacy menu item mechanics
    <div className="shared-plan-screen-container" ref={elementRef}>
      {currentMenuItem === "SharedPlan" && plans !== undefined && (
        <SharedPlanList sharedPlans={plans} />
      )}
    </div>
  );
};

interface SharedPlanMenuScreenProps {
  /**
   * Populated by erb view. Keeping as place holder, but not sure there is a need.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItem: any;
}

const SharedPlanMenuScreen = withReduxProvider(SharedPlanMenuScreenBase);
export { SharedPlanMenuScreen, SharedPlanMenuScreenProps };
