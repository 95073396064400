/* eslint-disable max-lines */
import { Plan } from "../../lib/apiTypes";
import { TourListItemDto, UserPlanAggregate } from "../apiTypes";

// eslint-disable-next-line no-shadow
export enum PlanActionTypes {
  SET_PLAN = "SET_PLAN",
  SET_SHARED_PLANS = "SET_SHARED_PLANS",
  SET_TOUR_EDIT = "SET_TOUR_EDIT",
  SET_SHARED_PLAN = "SET_SHARED_PLAN",
}

export interface SetPlanAction {
  type: PlanActionTypes.SET_PLAN;
  payload: {
    plan: UserPlanAggregate;
  };
}

export interface SetSharedPlansAction {
  type: PlanActionTypes.SET_SHARED_PLANS;
  payload: {
    sharedPlans: TourListItemDto[];
    sharedPlansCategoryIds: number[];
    sharedPlansCurrentPage: number;
    sharedPlansEnd: boolean;
  };
}

export interface SetTourEditAction {
  type: PlanActionTypes.SET_TOUR_EDIT;
  payload: {
    isSharedPlanEdit: boolean;
  };
}

export interface SetSharedPlanAction {
  type: PlanActionTypes.SET_SHARED_PLAN;
  payload: {
    sharedPlan?: Plan;
  };
}

export type PlanNames = "sharedPlan";

export const setPlan = (plan: UserPlanAggregate): SetPlanAction => {
  return {
    type: PlanActionTypes.SET_PLAN,
    payload: {
      plan: plan,
    },
  };
};

export const setSharedPlan = (plan: Plan): SetSharedPlanAction => {
  return {
    type: PlanActionTypes.SET_SHARED_PLAN,
    payload: {
      sharedPlan: plan,
    },
  };
};

export const setSharedPlans = (
  tours: TourListItemDto[],
  isEndOfPages: boolean,
  categoryIds: number[],
  currentPage: number,
): SetSharedPlansAction => {
  return {
    type: PlanActionTypes.SET_SHARED_PLANS,
    payload: {
      sharedPlans: tours,
      sharedPlansEnd: isEndOfPages,
      sharedPlansCategoryIds: categoryIds,
      sharedPlansCurrentPage: currentPage,
    },
  };
};

export const setTourEdit = (isSharedPlanEdit: boolean): SetTourEditAction => {
  return {
    type: PlanActionTypes.SET_TOUR_EDIT,
    payload: {
      isSharedPlanEdit: isSharedPlanEdit,
    },
  };
};
