import { RootReducerState } from "../../rootReducer";
import { AppDispatch } from "../../store";
import { adaptTourDtoToPlan, getTour, updateTour } from "../../visitWidgetApi";
import { listToursAction } from "./listToursAction";
import { setTourEdit } from "../planActionTypes";
import { setSharedPlanAction } from "./setSharedPlanAction";

/**
 * Updates shared plan, will use the globalSharedPlan since user can click save from global bar.
 */
const updateSharedPlanAction =
  () => async (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const sharedPlan = getState().plan.sharedPlan;
    await updateTour(sharedPlan.id, {
      name: sharedPlan.title,
      plan_items_attributes: sharedPlan.planItems.map((planItem, index) => {
        return {
          position: index + 1,
          id: planItem.id,
        };
      }),
    });
    const tour = await getTour(sharedPlan.id);
    const sharedPlanUpdated = adaptTourDtoToPlan(tour);
    dispatch(setSharedPlanAction(sharedPlanUpdated));
    dispatch(listToursAction(VisitWidget.store.categoryIds, true));
    dispatch(setTourEdit(false));
  };

export { updateSharedPlanAction };
