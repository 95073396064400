import React, { FunctionComponent, useState, useCallback } from "react";
import "./styles.scss";

const Priority: FunctionComponent<PriorityProps> = ({
  defaultValue,
  placeId,
  priorityLevels = 3,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const onSetPriority = useCallback(
    (e) => {
      const newValue = e.target.value;
      setIsUpdating(true);
      VisitWidget.Place.update(placeId, { priority: newValue }, () => {
        setIsUpdating(false);
      });
    },
    [setIsUpdating, placeId],
  );

  return (
    <select
      disabled={isUpdating}
      defaultValue={defaultValue}
      className="select"
      onChange={onSetPriority}>
      <option value="100">No Priority</option>
      {Array(priorityLevels)
        .fill(0)
        .map((_, index) => (
          <option key={index} value={index + 1}>
            Priority {index + 1}
          </option>
        ))}
    </select>
  );
};

export interface PriorityProps {
  defaultValue: string;
  placeId: string;
  priorityLevels?: number;
}

export default Priority;
