import React from "react";
import { IconProps } from "./IconProps";

const PlusIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5v14M5 12h14" />
  </svg>
);

export { PlusIcon };
