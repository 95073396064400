/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const ChevronDownIcon = ({ size = 24, testID }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      data-testid={testID}
      viewBox="0 0 24 24"
      fill="none">
      <g>
        <path
          d="M12 14.071L8.17903 10.25C7.76503 9.836 7.09303 9.836 6.67903 10.25C6.26503 10.664 6.26503 11.336 6.67903 11.75L11.293 16.364C11.684 16.755 12.317 16.755 12.707 16.364L17.321 11.75C17.735 11.336 17.735 10.664 17.321 10.25C16.907 9.836 16.235 9.836 15.821 10.25L12 14.071Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export { ChevronDownIcon };
