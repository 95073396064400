/* eslint-disable */
import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import { Input, Form, InputNumber, FormInstance } from "antd";
import "./styles.scss";
import { ConfigProvider } from "../ConfigProvider";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { HappinessIcon } from "../icons";
import { Button } from "../Button";
import { Rule } from "antd/lib/form";

const { TextArea, Password } = Input;

const TextInput: FunctionComponent<TextInputProps> = ({
  type = "text",
  form,
  disabled,
  numberOfLines = 2,
  multiline,
  testID,
  accessibilityLabel,
  placeholder,
  size = "regular",
  onChange,
  name,
  maxLength = 6000,
  minNumberValue = 0,
  maxNumberValue,
  rules,
  hint,
  prefix,
  label,
  emojisEnabled = false,
}) => {
  let inputType: string;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ref = useRef(null);
  const emojiPickerRef = useRef(null);

  if (type === "telephoneNumber") {
    inputType = "tel";
  } else if (type === "emailAddress") {
    inputType = "email";
  } else {
    inputType = type;
  }

  const handleShowEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  useEffect(() => {
    const formItemElement = multiline
      ? emojiPickerRef.current?.closest(".ant-form-item")
      : emojiPickerRef.current?.previousSibling;

    if (formItemElement) {
      const formItemWidth = formItemElement.offsetWidth;
      emojiPickerRef.current.style.width = formItemWidth + "px";

      if (multiline) formItemElement.append(emojiPickerRef.current);
    }
  }, []);

  const handleEmojiSelection = (emoji: Emoji) => {
    const newInputValue = addEmoji(emoji);
    // form.setFieldValue(name, newInputValue);
    setShowEmojiPicker(false);
  };

  const addEmoji = (emoji: Emoji) => {
    const symbols = emoji.unified.split("-");
    const codesArray = [];
    symbols.forEach((element) => codesArray.push("0x" + element));
    const returnEmoji = String.fromCodePoint(...codesArray);
    const currentValue = ""; // form.getFieldValue(name);

    const cursorPosition = multiline
      ? ref.current.resizableTextArea.textArea.selectionStart
      : ref.current.input.selectionStart;

    let textBeforeCursorPosition = currentValue.slice(0, cursorPosition);
    let textAfterCursorPosition = currentValue.slice(
      cursorPosition,
      currentValue.length,
    );

    const newString =
      textBeforeCursorPosition + returnEmoji + textAfterCursorPosition;

    return newString;
  };

  const emojiPickerButton = (
    <Button
      type="graySquare"
      onPress={handleShowEmojiPicker}
      icon={<HappinessIcon />}
    />
  );

  const inputFieldSuffix = emojisEnabled && (
    <span className="input-field-suffix">{emojiPickerButton}</span>
  );

  const textAreaSuffix = emojisEnabled && (
    <span className="text-area-suffix">{emojiPickerButton}</span>
  );

  return (
    <ConfigProvider type="regular">
      {multiline ? (
        <div style={{ position: "relative" }}>
          <Form.Item name={name} noStyle={true} rules={rules}>
            <TextArea
              rows={numberOfLines}
              disabled={disabled}
              placeholder={placeholder}
              aria-label={accessibilityLabel}
              data-testid={testID}
              onChange={onChange}
              maxLength={maxLength}
              ref={ref}
            />
          </Form.Item>
          {textAreaSuffix}
        </div>
      ) : inputType === "password" ? (
        <Form.Item name={name} noStyle={true} rules={rules}>
          <Password
            type={inputType}
            disabled={disabled}
            placeholder={placeholder}
            aria-label={accessibilityLabel}
            data-testid={testID}
            style={size === "large" ? { height: "46px" } : {}}
            onChange={onChange}
            maxLength={maxLength}
            autoComplete="current-password"
          />
        </Form.Item>
      ) : inputType === "number" ? (
        <Form.Item name={name} noStyle={true} rules={rules}>
          <InputNumber
            disabled={disabled}
            placeholder={placeholder}
            aria-label={accessibilityLabel}
            data-testid={testID}
            min={minNumberValue}
            max={maxNumberValue}
            onChange={onChange}
            style={size === "large" ? { height: "46px" } : {}}
            ref={ref}
          />
        </Form.Item>
      ) : (
        <Form.Item name={name} label={label} noStyle={true} rules={rules}>
          <Input
            prefix={prefix}
            disabled={disabled}
            data-form-type="other"
            placeholder={placeholder}
            aria-label={accessibilityLabel}
            data-testid={testID}
            style={size === "large" ? { height: "46px" } : {}}
            onChange={onChange}
            maxLength={maxLength}
            suffix={inputFieldSuffix}
            ref={ref}
          />
        </Form.Item>
      )}
      {hint !== undefined && <span className="form-item-hint">{hint}</span>}

      <div className="emoji-picker" ref={emojiPickerRef}>
        {showEmojiPicker && (
          <Picker
            data={data}
            onClickOutside={handleShowEmojiPicker}
            onEmojiSelect={handleEmojiSelection}
            theme="light"
          />
        )}
      </div>
    </ConfigProvider>
  );
};

export interface Emoji {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
}

export interface TextInputProps {
  /**
   * The initial value of the input
   */
  defaultValue?: string;
  /**
   * The label that appears above the input
   */
  label?: string;
  /**
   * Type of input.
   */
  type?: "text" | "password" | "telephoneNumber" | "emailAddress" | "number";
  /**
   * (Not Implemented) Hint message to assist user of intent.
   */
  hintMessage?: string;
  /**
   * Disable editing of the input
   */
  disabled?: boolean;
  /**
   * Display as a multi line text input
   */
  multiline?: boolean;
  /**
   * Number of lines, only applicable if `multiline` is true
   */
  numberOfLines?: number;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
  /**
   * Sets `aria-label` for accessability
   */
  accessibilityLabel?: string;
  /**
   * Placeholder text displayed in input when there is no text
   */
  placeholder?: string;
  /**
   * Height of text input, large: 46px (not applicable to multiline)
   */
  size?: "small" | "regular" | "large";
  /**
   * Automatically passed in via Form.Item being the direct parent element.
   */
  onChange?: (event: any) => {};
  /**
   * Form input name.
   */
  name?: string | number | (string | number)[];
  /**
   * Maximum number of characters allowed in input.
   */
  maxLength?: number;
  /**
   * Minimum number value for number inputs.
   */
  minNumberValue?: number;
  /**
   * Maximum number value for number inputs.
   */
  maxNumberValue?: number;
  /*
   * Help text to display below the input.
   */
  hint?: string;
  /**
   *
   */
  prefix?: React.ReactNode;
  /*
   * Validation rules
   */
  rules?: Rule[];
  /*
   * Add emoji picker for this field.
   */
  emojisEnabled?: boolean;
  form?: FormInstance;
}

export { TextInput };
