import { setMapReady } from "../mapActionTypes";

/*
 * Set map is ready and `VisitWidget.map.googleMap()` will return map.
 */
const setMapReadyAction = () => async (dispatch) => {
  dispatch(setMapReady());
};

export { setMapReadyAction };
