/* eslint no-console:0, @typescript-eslint/no-require-imports: 0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual
// application logic in
// a relevant structure within app/javascript and only use these pack files to
// reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to
// the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder
// and reference
// them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Needed for browser polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./src/react-bundle";
import "./src/typescript";
import "./src/airbrake";
import areAnyCategoryIdsExclusive from "./src/categories/queries/areAnyCategoryIdsExclusive";
import getCompiledLocations from "./src/map/queries/getCompiledLocations";
import shouldDoGeographicPagination from "./src/map/queries/shouldDoGeographicPagination";
import getCategoryFromId from "./src/categories/queries/getCategoryFromId";
import getDefaultFromDate from "./src/events/queries/getDefaultFromDate";
import getDefaultToDate from "./src/events/queries/getDefaultToDate";
import isPlanMenuItemSelected from "./src/domain/navigation/queries/isPlanMenuItemSelected";
import resetKiosk from "./src/domain/kiosk/commands/resetKiosk";
import enableEditMode from "./src/domain/edit_mode/commands/enableEditMode";
import videojs from "video.js";
import "./src/legacy/config/store";
import "./src/legacy/map/map";
import "./src/legacy/flyoutLoader";
import "./src/legacy/analytics";
import "./src/legacy/events/flyoutLoaded";
import "./src/legacy/events/userChanged";
import "./src/legacy/controllers/commands/showMapItLocation";
import "./src/legacy/controllers/navigationController";
import "./src/legacy/controllers/menuItemsController";
import "./src/legacy/controllers/controls/startPlanControl";
import "./src/legacy/controllers/controls/mapItControl";
import "./src/legacy/controllers/controls/mobile/mapItControl";
import "./src/legacy/controllers/controls/tourMapItControl";
import "./src/legacy/controllers/controls/planItemControl";
import "./src/legacy/controllers/controls/feedPostListItemsControl";
import "./src/legacy/controllers/controls/socialShareControl";
import "./src/legacy/controllers/controls/coverPhotoUploadControl";
import "./src/legacy/controllers/controls/mapRoutingControl";
import "./src/legacy/controllers/controls/mediaFilePlaybackControl";
import "./src/legacy/controllers/controls/adInterpolatorControl";
import "./src/legacy/controllers/planItemListController";
import "./src/legacy/controllers/kioskController";
import "./src/legacy/controllers/modals/registrationModal";
import "./src/legacy/controllers/modals/createModal";
import "./src/legacy/controllers/modals/tutorialModal";
import "./src/legacy/controllers/modals/shareModal";
import "./src/legacy/models/planItem";
import "./src/legacy/models/plan";
import "./src/legacy/models/user";
import "./src/legacy/models/tours";
import "./src/legacy/models/goal";
import "./src/legacy/models/location";
import "./src/legacy/lib/util";
import "./src/legacy/global";
import "./src/legacy/eventHandlers/onCurrentMenuItemChanged";

import "./src/legacy/controllers/tourListController";
import "./src/legacy/controllers/challengeListController";
import "./src/legacy/controllers/goalListController";
import "./src/legacy/controllers/sharedPlanListController";

import lib from "./src/lib/index";

VisitWidget.map.queries = {
  getCompiledLocations,
  shouldDoGeographicPagination,
};
VisitWidget.categories = {
  queries: {
    areAnyCategoryIdsExclusive,
    getCategoryFromId,
  },
};
VisitWidget.events = {
  queries: {
    areAnyCategoryIdsExclusive,
    getDefaultFromDate,
    getDefaultToDate,
  },
};
VisitWidget.editMode = {
  commands: {
    enableEditMode,
  },
};
VisitWidget.kiosk = {
  commands: {
    resetKiosk,
  },
};
VisitWidget.navigation = {
  queries: {
    isPlanMenuItemSelected,
  },
};
VisitWidget.lib = lib;

window.videojs = videojs;
