import { UserPlanAggregate } from "../../apiTypes";
import { RootReducerState } from "../../rootReducer";
import { setPlan } from "../planActionTypes";

/*
 * Set user plan.
 */
const setPlanAction =
  (plan: Partial<UserPlanAggregate>) => async (dispatch, getState) => {
    const state = getState() as RootReducerState;
    const updatedPlan = state.plan.plan;
    if (plan.id) {
      updatedPlan.id = plan.id;
    }
    if (plan.plan_items) {
      updatedPlan.plan_items = plan.plan_items;
    }

    dispatch(setPlan(updatedPlan));
  };

export { setPlanAction };
