/**
 * IMPORTANT: Google Universal Analytics is deprecating on July 1st, 2023. All usages of `ga` will
 * stop processing events.
 *
 * New analytics is solely through calls to gtag and the event names are chosen to match those in
 * the app repo.
 */

VisitWidget.Analytics = class Analytics {
  static createPageview(path: string) {
    for (let tag_id of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", "page_view", {
        send_to: tag_id,
        page_location: path.startsWith("/")
          ? window.location.origin + path
          : path,
      });
    }
  }

  static createEntityView(
    eventName: "PlaceViewed" | "EventViewed" | "FeedPostViewed" | "TourViewed",
    url: string,
    id: string,
    name: string,
  ) {
    for (let tag_id of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", eventName, {
        send_to: tag_id,
        page_location: url,
        name: name,
        id: id,
      });
    }
  }

  static createEventGA4(action: string, options: Record<string, string>) {
    for (let tag_id of Array.from(VisitWidget.settings.googleTagTrackingIds)) {
      gtag("event", action, {
        ...options,
        send_to: tag_id,
      });
    }
  }

  static createEventUA(action: string, label: string, category: string) {}

  public static createAdImpression(adId: string, adName: string) {
    for (let tagId of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", "AdImpression", {
        send_to: tagId,
        id: adId,
        name: adName,
        label: this.createEntityLabel(adId, adName),
      });
    }
  }

  public static createAdClick(adId: string, adName: string) {
    for (let tagId of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", "AdClickThrough", {
        send_to: tagId,
        id: adId,
        name: adName,
        label: this.createEntityLabel(adId, adName),
      });
    }
  }

  static createPlanAdd(entityId: string | number, entityType: string) {
    for (let tagId of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", "AddPlanItem", {
        send_to: tagId,
        type: entityType.toLowerCase(),
        id: entityId.toString(),
      });
    }
  }

  public static createLikeClick(
    entityId: string | number,
    entityType: string,
    entityName: string,
  ) {
    this.createEntityEventUA("like", entityId, entityType, entityName);
    this.createEntityEventGA4("LikeItem", entityId, entityType, entityName);
  }

  public static createWebsiteClick(
    entityId: string | number,
    entityType: string,
    entityName: string,
  ) {
    this.createEntityEventUA("openWebsite", entityId, entityType, entityName);
    this.createEntityEventGA4(
      "WebsitePressed",
      entityId,
      entityType,
      entityName,
    );
  }

  public static createMapItClick(
    entityId: string | number,
    entityType: string,
    entityName: string,
  ) {
    this.createEntityEventUA("mapIt", entityId, entityType, entityName);
    this.createEntityEventGA4("MapItPressed", entityId, entityType, entityName);
  }

  public static createPhoneClick(
    entityId: string | number,
    entityType: string,
    entityName: string,
  ) {
    this.createEntityEventUA("phoneCall", entityId, entityType, entityName);
    this.createEntityEventGA4(
      "PhoneCallPressed",
      entityId,
      entityType,
      entityName,
    );
  }

  public static createShareClick(entityId, entityType, entityName) {
    this.createEntityEventUA("share", entityId, entityType, entityName);
    this.createEntityEventGA4("ShareItem", entityId, entityType, entityName);
  }

  public static createSharePlanClick(
    type: "email" | "twitter" | "facebook" | "sms",
  ) {
    for (let tag_id of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", "SharePlan", {
        send_to: tag_id,
        type: type,
      });
    }
  }

  private static createEntityLabel(entityId, entityName) {
    return `${entityId} | ${entityName}`;
  }

  // deprecate after July 1st, 2023
  private static createEntityEventUA(
    eventName,
    entityId,
    entityType,
    entityName,
  ) {}

  private static createEntityEventGA4(
    eventName,
    entityId,
    entityType,
    entityName,
  ) {
    for (let tag_id of VisitWidget.settings.googleTagTrackingIds) {
      gtag("event", eventName, {
        send_to: tag_id,
        id: entityId,
        type: entityType,
        name: entityName,
      });
    }
  }
};
