import { resetPassword } from "../../visitWidgetApi";
import {
  resetPasswordError,
  resetPasswordFetch,
  resetPasswordSuccess,
} from "../authActionTypes";
import { buildErrorMessage } from "../helpers/buildErrorMessage";

/*
 * Reset password for user.
 */
export const resetPasswordAction =
  ({ email, captcha }: { email: string; captcha: string }) =>
  async (dispatch) => {
    try {
      dispatch(resetPasswordFetch());

      await resetPassword({
        email,
        captcha,
      });

      dispatch(resetPasswordSuccess());
    } catch (error) {
      const errorMessage = buildErrorMessage(error);
      alert(errorMessage);
      dispatch(resetPasswordError(errorMessage));
    }
  };
