/* eslint-disable */
/* tslint-disable */

VisitWidget.FeedPostListItemsControl = class FeedPostListItemsControl extends (
  VisitWidget.ListItemsControl
) {
  constructor(containerSelector) {
    super(containerSelector, "feed_post");
    this.setupLikeEventHandling = this.setupLikeEventHandling.bind(this);
    this.containerSelector = containerSelector;
    VisitWidget.EventBus.subscribe("UserChanged", () => {
      this.handleResetState();
    });
  }

  addListItemsHtml(html) {
    const $listItemsHtml = $(html);
    for (let post of Array.from($listItemsHtml)) {
      const $post = $(post);
      const feedPostId = $post.data("id");
      if (VisitWidget.store.userLikedFeedPostIds.indexOf(feedPostId) > -1) {
        $post.find(".motivate").addClass("current_user_liked");
      }

      if (VisitWidget.settings.adminModeOn) {
        html =
          '<a confirm="Are you sure you want to delete? ' +
          ' rel="nofollow" data-method="delete" href="/feed_posts/' +
          feedPostId +
          '">Delete</a>';

        const $deleteContainer = $post.find(".delete-container");
        $deleteContainer.addClass("btn transparent");
        $deleteContainer.html(html);
      }
    }

    this.resetLikes();
    return super.addListItemsHtml($listItemsHtml);
  }

  setupEvents() {
    $(document).on("click", `${this.containerSelector} .post`, (e) => {
      const $target = $(e.target);
      if (!this.isTargetAnotherLink($target)) {
        return $target.closest(".post").find("a.feed_post_link").click();
      }
    });

    if (VisitWidget.settings.isMobile) {
      $(document).on(
        "click",
        `${this.containerSelector} .feed_post_link`,
        (e) => {
          const $target = $(e.target);
          return $target.closest(".post").addClass("item_pressed");
        },
      );
    }
    return this.setupLikeEventHandling();
  }

  /*
   * BRIDGE: this function is to bridge react and straight javascript
   * Currently:
   *  - updates whether feed items are liked or not
   */
  handleResetState() {
    this.resetLikes();
  }

  resetLikes() {
    const $posts = $(this.containerSelector).find(".post");

    // WARNING: do not use fat arrow, need `this` to be the element and not
    // this class
    $posts.each(function () {
      const $post = $(this);
      const feedPostId = Number($post.data()?.id ?? 0);
      if (
        !!VisitWidget.store.userLikedFeedPostIds.find(
          (x) => x.id === feedPostId,
        )
      ) {
        $post.find(".motivate").addClass("current_user_liked");
      } else {
        $post.find(".motivate").removeClass("current_user_liked");
      }
    });
  }

  setupLikeEventHandling() {
    return $(document).on(
      "click.like-post",
      `${this.containerSelector} .motivate`,
      (event) => {
        if (!VisitWidget.store.currentUserId) {
          // user must be logged in to like
          VisitWidget.registrationModal.openModalAction("hifive");
          return;
        }

        const $motivated = $(event.target).closest(".motivate");
        if ($motivated.hasClass("current_user_liked")) {
          return;
        }

        const postId = $(event.target).parents(".post").attr("data-id");

        return $.ajax({
          url: `/feed_posts/${postId}/like`,
          type: "POST",
          success(data) {
            $motivated.find(".count").text(data.like_count);
            // Update the like count in the flyout
            $(`.like-count[data-post-id=${postId}]`).text(data.like_count);
            // TODO: do we need to update the store?
            return $motivated.addClass("current_user_liked");
          },
        });
      },
    );
  }
};
