VisitWidget.MapRoutingControl = class MapRoutingControl {
  containerSelector: any;
  options: any;
  routeDetailsSelectors: any;
  numberOfStopsSelector: any;
  totalMilesSelector: any;
  totalDurationSelector: any;
  mapMarkers: any;
  directionsService: any;

  constructor(containerSelector, options, routeDetailsSelectors) {
    this.clearRouteDetails = this.clearRouteDetails.bind(this);
    this.updateRouteDetails = this.updateRouteDetails.bind(this);
    this.updateNumberOfStops = this.updateNumberOfStops.bind(this);
    this.updateRouteSummary = this.updateRouteSummary.bind(this);
    this.containerSelector = containerSelector;
    if (options == null) {
      options = {};
    }
    this.options = options;
    if (routeDetailsSelectors == null) {
      routeDetailsSelectors = {};
    }
    this.routeDetailsSelectors = routeDetailsSelectors;
    if (typeof this.options.includeRouteDetails === "undefined") {
      this.options.includeRouteDetails = false;
    }
    if (typeof this.options.includeMap === "undefined") {
      this.options.includeMap = false;
    }

    if (this.options.includeRouteDetails) {
      this.numberOfStopsSelector =
        this.routeDetailsSelectors.numberOfStopsSelector;
      this.totalMilesSelector = this.routeDetailsSelectors.totalMilesSelector;
      this.totalDurationSelector =
        this.routeDetailsSelectors.totalDurationSelector;
    }
  }

  createRoutesFromMapMarkers(mapMarkers = null, shouldCenter): void {
    if (mapMarkers.length < 2) {
      return;
    }

    const latLngs = this.createLatLngsFromMarkers(mapMarkers);
    if (this.options.includeMap) {
      this.enableMapMarkerRouteLabel(mapMarkers);
    }

    this.createRoutes(latLngs, shouldCenter);
    this.mapMarkers = mapMarkers;
  }

  createRoutesFromDirectionsData(
    directionsData,
    numberOfStops,
    mapMarkers = null,
    shouldCenter,
  ) {
    if (this.options.includeMap) {
      if (mapMarkers.length < 2) {
        return;
      }
      this.enableMapMarkerRouteLabel(mapMarkers);
    }

    if (this.options.includeRouteDetails) {
      this.updateNumberOfStops(numberOfStops);
    }

    if (directionsData.convertedToObject === undefined) {
      directionsData.routes[0].bounds = new google.maps.LatLngBounds(
        {
          lat: directionsData.routes[0].bounds.south,
          lng: directionsData.routes[0].bounds.west,
        },
        {
          lat: directionsData.routes[0].bounds.north,
          lng: directionsData.routes[0].bounds.east,
        },
      );
      directionsData.routes[0].overview_path =
        this.getLatLngsFromEncodedPolyline(
          directionsData.routes[0].overview_polyline,
        );

      for (let i = 0; i < directionsData.routes[0].legs.length; ++i) {
        const leg = directionsData.routes[0].legs[i];
        for (let j = 0; j < leg.steps.length; ++j) {
          const step = leg.steps[j];
          step.path = this.getLatLngsFromEncodedPolyline(step.polyline.points);
        }
      }

      directionsData.convertedToObject = true;
    }

    if (this.options.includeMap) {
      this.setDirectionsDisplay();
    }
    return this.setCombinedResultToMap(directionsData, shouldCenter);
  }

  getLatLngsFromEncodedPolyline(encodedPolyline) {
    const points = google.maps.geometry.encoding.decodePath(encodedPolyline);
    const latLngs = [];
    for (let point of Array.from(points)) {
      latLngs.push({ lat: point.lat(), lng: point.lng() });
    }
    return latLngs;
  }

  createRoutesFromLocations(locations) {
    if (locations.length < 2) {
      return;
    }
    const latLngs = this.createLatLngsFromLocations(locations);
    return this.createRoutes(latLngs);
  }

  getMapMarkers() {
    return this.mapMarkers;
  }

  createRoutes(latLngs, shouldCenter = false): void {
    latLngs = this.getLatLngsWithoutConsecutiveDuplicates(latLngs);

    if (this.options.includeMap) {
      this.setDirectionsDisplay();
    }

    if (this.options.includeRouteDetails) {
      this.updateNumberOfStops(latLngs.length);
    }

    this.makeDirectionRequests(latLngs, shouldCenter);
  }

  makeDirectionRequests(latLngs, shouldCenter): void {
    const batches = this.createBatches(latLngs);
    const directionRequestResults = [];

    batches.map((batch, index) =>
      ((batch, index) => {
        let waypoints;
        latLngs = batch;
        const origin = latLngs[0];
        if (latLngs.length <= 2) {
          waypoints = [];
        } else {
          waypoints = latLngs.slice(1, latLngs.length - 1);
        }

        const destination = latLngs[latLngs.length - 1];

        const directionsRequest = this.createDirectionRequest(
          origin,
          waypoints,
          destination,
        );

        if (this.directionsService == null) {
          this.directionsService = new google.maps.DirectionsService();
        }
        this.directionsService.route(directionsRequest, (result, status) => {
          return this.onDirectionRequestComplete(
            result,
            status,
            batches,
            directionRequestResults,
            index,
            shouldCenter,
          );
        });
      })(batch, index),
    );
  }

  onDirectionRequestComplete(
    result,
    status,
    batches,
    directionRequestResults,
    order,
    shouldCenter,
  ) {
    if (status === google.maps.DirectionsStatus.OK) {
      result.order = order;
      directionRequestResults.push(result);
      if (directionRequestResults.length === batches.length) {
        directionRequestResults = this.sortDirectionRequestResults(
          directionRequestResults,
        );
        this.addRoutes(directionRequestResults, shouldCenter);
      }
    } else {
      return console.warn(
        `Google Directions failed with status of ${status} and ` +
          ` result of ${result}`,
      );
    }
  }

  sortDirectionRequestResults(directionRequestResults) {
    return _.sortBy(directionRequestResults, (result) => {
      return result.order;
    });
  }

  addRoutes(directionRequestResults, shouldCenter) {
    const combinedResult = directionRequestResults[0];
    if (directionRequestResults.length > 1) {
      for (
        let i = 1, end = directionRequestResults.length, asc = 1 <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        const result = directionRequestResults[i];
        const { legs } = result.routes[0];
        const { bounds } = result.routes[0];
        const northEastBound = bounds.getNorthEast();
        const southWestBound = bounds.getSouthWest();

        const { overview_path } = result.routes[0];
        combinedResult.routes[0].legs =
          combinedResult.routes[0].legs.concat(legs);
        combinedResult.routes[0].overview_path =
          combinedResult.routes[0].overview_path.concat(overview_path);
        combinedResult.routes[0].bounds =
          combinedResult.routes[0].bounds.extend(northEastBound);
        combinedResult.routes[0].bounds =
          combinedResult.routes[0].bounds.extend(southWestBound);
      }
    }
    return this.setCombinedResultToMap(combinedResult, shouldCenter);
  }

  setCombinedResultToMap(combinedResult, shouldCenter) {
    if (this.options.includeRouteDetails) {
      this.clearRouteDetails();
      this.updateRouteDetails(combinedResult);
      this.updateRouteSummary(combinedResult);
    }

    if (this.options.includeMap) {
      Gmaps.store.directionsDisplay.setDirections(combinedResult);

      if (
        VisitWidget.planItemListController &&
        VisitWidget.planItemListController.startPlanModeOn
      ) {
        VisitWidget.planItemListController.turnOffStartPlanMode();
        return VisitWidget.planItemListController.turnOnStartPlanMode();
      } else {
        if (shouldCenter) {
          return VisitWidget.map.centerAndFitToBounds(
            combinedResult.routes[0].bounds,
            0,
          );
        }
      }
    }
  }

  createBatches(latLngs) {
    const batchLimit = VisitWidget.settings.maximumNumberOfRouteLocations;

    const batches = [];
    batches[0] = [];
    let batchIndex = 0;
    for (let latLngIndex = 0; latLngIndex < latLngs.length; latLngIndex++) {
      const latLng = latLngs[latLngIndex];
      batches[batchIndex].push(latLng);
      if (
        batches[batchIndex].length === batchLimit &&
        latLngIndex + 1 < latLngs.length
      ) {
        batchIndex += 1;
        batches[batchIndex] = [latLng];
      }
    }

    return batches;
  }

  setDirectionsDisplay() {
    if (typeof Gmaps.store["directionsDisplay"] === "undefined") {
      Gmaps.store["directionsDisplay"] = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: {
          strokeColor: VisitWidget.settings.currentClientRouteColor,
          strokeWeight: 4,
        },
      });
    }
    return Gmaps.store.directionsDisplay.setMap(VisitWidget.map.googleMap());
  }

  enableMapMarkerRouteLabel(mapMarkers) {
    const result = [];
    let labelContent = 0;
    for (let i = 0, end = mapMarkers.length; i < end; i++) {
      if (
        i === 0 ||
        mapMarkers[i - 1].serviceObject.markerId !=
          mapMarkers[i].serviceObject.markerId
      ) {
        labelContent++;
      }

      mapMarkers[i].serviceObject.labelVisible = true;
      mapMarkers[i].serviceObject.label.setVisible(true);
      result.push((mapMarkers[i].serviceObject.labelContent = labelContent));
    }
    return result;
  }

  clearRouteDetails() {
    $(`${this.containerSelector} .post .duration`).empty();
    $(`${this.containerSelector} .post .distance`).empty();
    $(`${this.containerSelector} .post .route-order`).empty();
    return $(`${this.containerSelector} .post .route-order`).hide();
  }

  updateRouteDetails(directionResult) {
    const route = directionResult.routes[0];

    const $firstPlanItem = $(
      `${this.containerSelector} .post:not([data-marker-id='']):eq(0)`,
    );
    $firstPlanItem.find(".route-order").html("1");
    $firstPlanItem.find(".route-order").show();
    $firstPlanItem
      .find(".duration")
      .html(VisitWidget.lib.translate("plan.first_item.duration"));
    $firstPlanItem
      .find(".distance")
      .html(VisitWidget.lib.translate("plan.first_item.distance"));

    let previousMarkerId = $firstPlanItem.data("marker-id");

    let legIndex = 0;
    let routeNumber = 1;
    const $planItems = $(
      `${this.containerSelector} .post:not([data-marker-id='']):gt(0)`,
    );

    $planItems.each((_, planItem) => {
      const $planItem = $(planItem);
      const leg = route.legs[legIndex];

      if (previousMarkerId !== $planItem.data("marker-id")) {
        const distance = leg.distance.text;
        const duration = this.formatLegDuration(leg);

        $planItem.find(".duration").html(duration);
        $planItem.find(".distance").html(distance);
        routeNumber++;
        legIndex++;
      }
      previousMarkerId = $planItem.data("marker-id");

      $planItem.find(".route-order").html(String(routeNumber));
      $planItem.find(".route-order").show();
    });
  }

  formatLegDuration(leg) {
    return leg.duration.text
      .replace(" mins", "m")
      .replace(" hours", "h")
      .replace(" min", "m")
      .replace(" hour", "h");
  }

  updateNumberOfStops(numberOfStops) {
    return $(`${this.containerSelector} ${this.numberOfStopsSelector}`).html(
      numberOfStops,
    );
  }

  updateRouteSummary(directionResult) {
    const route = directionResult.routes[0];
    let totalDistance = 0;
    let totalSeconds = 0;
    const targetDistanceUnit = VisitWidget.lib.getRoutingTargetDistanceUnit({
      route,
    });

    for (let i = 0; i < route.legs.length; ++i) {
      const leg = route.legs[i];
      const distanceParts = leg.distance.text.split(" ");
      totalDistance += VisitWidget.lib.getDistanceInTargetUnit({
        sourceDistance: distanceParts[0],
        sourceUnit: distanceParts[1],
        targetUnit: targetDistanceUnit,
      });
      totalSeconds += leg.duration.value;
    }

    // convert to distance with one decimal place
    totalDistance = Math.round(totalDistance * 10) / 10;
    $(`${this.containerSelector} ${this.totalMilesSelector}`).html(
      totalDistance.toString() + " " + targetDistanceUnit,
    );

    const totalMinutes = Math.round(totalSeconds / 60);
    const totalTimeText = moment
      .duration(totalMinutes, "minutes")
      .format(
        "h [" +
          VisitWidget.lib.translate("time.units.one_character_hour") +
          "] m " +
          `[${VisitWidget.lib.translate("time.units.one_character_minute")}]`,
      );
    $(`${this.containerSelector} ${this.totalDurationSelector}`).html(
      totalTimeText,
    );

    // the route summary can expland the mobiles site's plan list header,
    // so need to resize the so the last item in the plan list does not get cut
    // off
    if (VisitWidget.settings.isMobile) {
      return VisitWidget.navigationController.resize();
    }
  }

  createDirectionRequest(origin, waypoints, destination) {
    const directionsRequest = {
      origin,
      destination,
      provideRouteAlternatives: false,
      travelMode: google.maps.TravelMode[VisitWidget.settings.mapTravelMode],
      waypoints: [],
    };

    for (let wayPoint of Array.from(waypoints)) {
      const directionsWaypoint = {
        location: wayPoint,
        stopover: true, // no stopovers would create routes avoiding u-turns
      };

      directionsRequest["waypoints"].push(directionsWaypoint);
    }

    return directionsRequest;
  }

  createLatLngsFromMarkers(mapMarkers) {
    const latLngs = [];

    for (let mapMarker of mapMarkers) {
      const lat = mapMarker.serviceObject.position.lat();
      const lng = mapMarker.serviceObject.position.lng();
      latLngs.push(new google.maps.LatLng(lat, lng));
    }

    return latLngs;
  }

  createLatLngsFromLocations(locations) {
    const latLngs = [];

    for (let location of locations) {
      const { lat } = location;
      const { lng } = location;
      latLngs.push(new google.maps.LatLng(lat, lng));
    }

    return latLngs;
  }

  getLatLngsWithoutConsecutiveDuplicates(latLngs) {
    const filteredLatLngs = [];

    let i = 0;
    while (i < latLngs.length) {
      if (i === 0 || !this.areLatLngsEqual(latLngs[i - 1], latLngs[i])) {
        filteredLatLngs.push(latLngs[i]);
      }
      i++;
    }

    return filteredLatLngs;
  }

  areLatLngsEqual(latLng1, latLng2) {
    return latLng1.lat() === latLng2.lat() && latLng1.lng() === latLng2.lng();
  }

  areRoutesLoaded() {
    if (
      typeof Gmaps.store === "undefined" ||
      typeof Gmaps.store.directionsDisplay === "undefined" ||
      typeof Gmaps.store.directionsDisplay.directions === "undefined"
    ) {
      return false;
    } else {
      return true;
    }
  }
};
