import { PlanItemDto, User } from "../../../store/apiTypes";

/*
 * Set necessary variables for legacy controllers to work correctly.
 */
// ignore complexity, its almost entirely from defaulting with `??`
// eslint-disable-next-line complexity, react-func/max-lines-per-function
const setLegacyState = ({
  user,
  planId,
  planItems,
  planTourIds,
  accessToken,
}: LegacyStateUpdate) => {
  VisitWidget.store.userLikedFeedPostIds = user?.liked_feed_posts ?? [];
  VisitWidget.store.currentUserId = user?.id ?? null;
  VisitWidget.store.accessToken = accessToken;
  VisitWidget.store.planItems = planItems ?? [];
  VisitWidget.store.planTourIds = planTourIds ?? [];
  VisitWidget.store.currentPlanId = planId ?? null;

  $.ajaxSetup({
    beforeSend: function (xhr, options) {
      // invoked before each jQuery ajax call, include token if logged in
      if (accessToken) {
        xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      }
      xhr.setRequestHeader(
        "X-CSRF-Token",
        $("meta[name=csrf-token]").prop("content"),
      );
      options.url = `${VisitWidget.settings.currentClientApiUrl}${options.url}`;
    },
  });

  if (!!VisitWidget.planItemListController) {
    VisitWidget.planItemListController.updatePlanCount();
  }

  if (!!planItems) {
    planItems.forEach((planItem) => {
      VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState(planItem);
    });
  }

  VisitWidget.EventBus.publish(new VisitWidget.Events.UserChanged());
};

interface LegacyStateUpdate {
  user?: User;
  accessToken?: string;
  planItems?: PlanItemDto[];
  planTourIds?: number[];
  planId?: number;
}

export { setLegacyState };
