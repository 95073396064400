/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const PencilIcon = ({ size = 24, color, testID }: IconProps) => (
  <svg
    width={size}
    height={size}
    color={color}
    data-testid={testID}
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4141 2C18.1582 2 17.902 2.09747 17.707 2.29297L16 4L20 8L21.707 6.29297C22.098 5.90197 22.098 5.26891 21.707 4.87891L19.1211 2.29297C18.9256 2.09747 18.6699 2 18.4141 2ZM14.5 5.5L5 15C5 15 6.005 15.005 6.5 15.5C6.995 15.995 6.98438 16.9844 6.98438 16.9844C6.98438 16.9844 8.004 17.004 8.5 17.5C8.996 17.996 9 19 9 19L18.5 9.5L14.5 5.5ZM3.66992 17L3 21L7 20.3301L3.66992 17Z" />
  </svg>
);

export { PencilIcon };
