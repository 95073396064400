/* eslint-disable max-len */
import React from "react";
import { IconProps } from "./IconProps";

const ArrowRightIcon = ({ size = 24, testID }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      data-testid={testID}
      viewBox="0 0 24 24"
      fill="none">
      <g>
        <path
          d="M23.4495 11.8721C23.4492 11.8718 23.449 11.8715 23.4487 11.8712L18.8134 7.25832C18.4662 6.91275 17.9045 6.91404 17.5589 7.26134C17.2133 7.60859 17.2146 8.17026 17.5618 8.51587L20.674 11.6129H1.8871C1.39715 11.6129 1 12.0101 1 12.5C1 12.9899 1.39715 13.3871 1.8871 13.3871H20.6739L17.5619 16.4841C17.2146 16.8297 17.2133 17.3914 17.5589 17.7387C17.9046 18.086 18.4663 18.0872 18.8135 17.7417L23.4488 13.1288C23.449 13.1285 23.4492 13.1282 23.4495 13.1279C23.797 12.7812 23.7959 12.2177 23.4495 11.8721Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export { ArrowRightIcon };
