/* eslint-disable */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Modal, ModalAction, ModalContent, ModalTitle } from "../../Modal";
import { TextInput } from "../../TextInput";
import { Button } from "../../Button";
import "./PlanNotesModal.scss";
import { setPlanItemNotes, setPlanNotes } from "../../../store/visitWidgetApi";
import { Form } from "../../form/Form/Form";

const PlanNotesModal: FunctionComponent<PlanNotesModalProps> = ({
  tourId,
  planItemId,
  type,
  open,
  coverPhotoUrl,
  notes,
  title,
  onClose,
  isEditMode = false,
}) => {
  const modalTitle = type === "plan" ? "Plan Notes" : "Notes For You";
  const [state, setState] = useState<PlanNotesModalState>({ status: "idle" });
  const [notesInternal, setNotesInternal] = useState(notes);

  useEffect(() => {
    setNotesInternal(notes);
  }, [notes]);

  const [form] = Form.useForm();

  const handleClose = (updatedNotes?: string) => {
    if (updatedNotes === undefined) {
      form.resetFields();
    } else {
      setNotesInternal(updatedNotes);
    }
    setState({ status: "idle" });
    onClose();
  };

  const handleSubmit = useCallback(
    async (values: { notes: string }) => {
      if (notes !== values.notes) {
        setState({ status: "fetching" });
        try {
          if (planItemId) {
            await setPlanItemNotes(tourId, planItemId, values.notes);
          } else {
            await setPlanNotes(tourId, values.notes);
          }
          setState({ status: "idle" });
          handleClose(values.notes);
        } catch (e) {
          setState({ status: "error" });
        }
      } else {
        handleClose();
      }
    },
    [tourId],
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      width={560}>
      <ModalTitle fontSize="large">{modalTitle}</ModalTitle>
      <ModalContent>
        <div className="plan-notes-modal-container">
          {!isEditMode && (
            <>
              <div
                className="plan-notes-modal-cover-photo"
                style={{ backgroundImage: `url(${coverPhotoUrl})` }}></div>

              <div className="plan-notes-modal-details">
                <div className="plan-notes-modal-title">{title}</div>
                <div className="plan-notes-modal-notes">{notesInternal}</div>
              </div>
            </>
          )}
          {isEditMode && (
            <>
              <div className="plan-notes-modal-details">
                <div className="plan-notes-modal-title">{title}</div>
                <div className="plan-notes-notes-form">
                  <Form
                    form={form}
                    name="register"
                    onFinish={handleSubmit}
                    disabled={state.status === "fetching"}
                    initialValues={{ notes: notesInternal }}>
                    <Form.Item name="notes">
                      <TextInput
                        name="notes"
                        form={form}
                        multiline={true}
                        numberOfLines={3}
                        placeholder="Add notes"
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </>
          )}
          {state.status === "error" && (
            <div className="plan-notes-modal-error">
              Error setting plan notes.
            </div>
          )}
        </div>
      </ModalContent>
      {isEditMode && (
        <ModalAction position="right">
          <Button
            type="link"
            onPress={() => {
              handleClose();
            }}
            disabled={state.status === "fetching"}>
            Cancel
          </Button>
        </ModalAction>
      )}
      {isEditMode && (
        <ModalAction position="right">
          <Button
            type="primary"
            disabled={state.status === "fetching"}
            onPress={() => {
              form.submit();
            }}>
            Save
          </Button>
        </ModalAction>
      )}
    </Modal>
  );
};

interface PlanNotesModalProps {
  tourId?: number;
  type: "plan" | "planItem";
  planItemId?: number;
  open: boolean;
  onClose: (updatedNotes?: string) => void;
  coverPhotoUrl: string;
  title: string;
  notes: string;
  isEditMode?: boolean;
}

interface PlanNotesModalState {
  status: "idle" | "fetching" | "error";
}

export { PlanNotesModal, PlanNotesModalProps };
