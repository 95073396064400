/* tslint-disable */
/* eslint-disable */

VisitWidget.StartPlanControl = class StartPlanControl {
  startPlanModeOn: boolean;
  slider: any;
  unslider: any;
  legDirections: any;
  map: any;
  constructor() {
    this.startPlanModeOn = false;
  }

  initialize() {
    this.slider = null;
    this.unslider = null;
    return this.setupEvents();
  }

  setupEvents() {
    $("#plan_directions .previous").on("click", () => {
      return this.unslider.prev();
    });

    $("#plan_directions .next").on("click", () => {
      return this.unslider.next();
    });

    if (VisitWidget.settings.isMobile) {
      const selector = `#plan_directions #plan_directions_text_container, \
#plan_directions .handle`;
      return $(selector).on("click", () => {
        return this.togglePlanDirections();
      });
    }
  }

  setupNavigation() {
    const alreadyInitialized = this.unslider !== null;
    if (alreadyInitialized) {
      // re-initialize slider but leaveout swipe since events
      // have already be set up for that. Not sure why two inits are needed.
      this.unslider.setIndex(0);
      this.unslider.$container.off();
      this.unslider.init({ infinite: false, nav: false, arrows: false });
      this.unslider.$container.off();
      this.unslider.init({ infinite: false, nav: false, arrows: false });
    } else {
      this.slider = ($("#plan_directions .slider") as any).unslider({
        infinite: false,
        arrows: false,
        nav: VisitWidget.settings.isMobile,
      });
      this.unslider = this.slider.data("unslider");
    }

    this.slider.off("unslider.change");
    return this.slider.on("unslider.change", (event, index, slide) => {
      if (index > -1) {
        this.setLegDirectionToActive(this.legDirections[index]);
      }

      return this.toggleNavigationButtons(index);
    });
  }

  toggleNavigationButtons(sliderIndex) {
    if (sliderIndex >= this.legDirections.length - 1) {
      $("#plan_directions .next").hide();
    } else {
      $("#plan_directions .next").show();
    }

    if (sliderIndex <= 0) {
      $("#plan_directions .previous").hide();
    } else {
      $("#plan_directions .previous").show();
    }
  }

  start() {
    this.map = VisitWidget.map.googleMap();
    this.legDirections = this.createLegDirections();
    VisitWidget.map.hideRoutes();
    VisitWidget.map.hideInfoWindows();
    $("#plan_directions .previous").hide();
    $("#plan_directions .next").show();
    this.addDirections();
    this.showPlanDirections();
    if (VisitWidget.settings.isMobile) {
      VisitWidget.Mobile.map.show();
    }
    this.setupNavigation();
    this.setLegDirectionToActive(this.legDirections[0]);

    $(".create.btn.registration-trigger").hide();

    return (this.startPlanModeOn = true);
  }

  showPlanDirections() {
    if (!VisitWidget.settings.isMobile) {
      let planDirectionsTop = 9;
      if ($("#header").length > 0) {
        planDirectionsTop +=
          $("#header").height() + $("#orange-header").height() - 1;
      }
      $("#plan_directions").css("top", planDirectionsTop);
    }

    return $("#plan_directions").show();
  }

  end() {
    if (!this.startPlanModeOn) {
      return;
    }
    if (this.unslider !== null) {
      this.unslider.$container.off();
    }
    if (this.slider !== null) {
      this.slider.off("unslider.change");
    }
    VisitWidget.map.showMarkers();
    VisitWidget.map.showRoutes();
    VisitWidget.map.clearPolyLines();
    if (Gmaps.store.directionsDisplay.directions !== null) {
      this.resetMarkerLabels();
      const { bounds } = Gmaps.store.directionsDisplay.directions.routes[0];
      return VisitWidget.map.centerAndFitToBounds(bounds, 0);
    }
  }

  resetMarkerLabels() {
    const markerIds = this.getMarkerIdsMatchingLegs();
    const updatedMarkerIds = [];
    let i = 0;
    return (() => {
      const result = [];
      while (i < markerIds.length) {
        const markerId = markerIds[i];
        const label = (i + 1).toString();
        if (!Array.from(updatedMarkerIds).includes(markerId)) {
          VisitWidget.map.getMarkerById(markerId).serviceObject.labelContent =
            label;
          updatedMarkerIds.push(markerId);
        }
        result.push(i++);
      }
      return result;
    })();
  }

  setLegDirectionToActive(legDirectionToActivate) {
    for (let legDirection of Array.from(this.legDirections) as any[]) {
      legDirection.active = false;
    }
    legDirectionToActivate.active = true;
    return this.showActiveLegDirection();
  }

  showActiveLegDirection() {
    const activeLegDirection = this.getActiveLegDirection();
    VisitWidget.map.clearPolyLines();

    if (VisitWidget.settings.isMobile) {
      this.showLegDirectionDetails(activeLegDirection);
    }

    $("#plan_directions").show();
    this.contractPlanDirections();
    if (!VisitWidget.settings.isMobile) {
      this.expandActivePlanDirection();
    }
    this.showRoute(activeLegDirection);
    return this.hideInactiveLegMarkers();
  }

  hideInactiveLegMarkers() {
    const markerIdsToShow = this.getMarkerIdsToShow();
    const markerIdsToHide = this.getMarkerIdsToHide(markerIdsToShow);
    this.updateMarkerLabels();

    VisitWidget.map.hideMarkers(markerIdsToHide);
    return VisitWidget.map.showMarkers(markerIdsToShow);
  }

  getMarkerIdsToShow() {
    const markerIdsToShow = [];
    const markerIds = this.getMarkerIdsMatchingLegs();
    let i = 0;
    while (i < this.legDirections.length) {
      const markerId1 = markerIds[i];
      const markerId2 = markerIds[i + 1];
      if (this.legDirections[i].active) {
        markerIdsToShow.push(markerId1);
        markerIdsToShow.push(markerId2);
      }
      i++;
    }
    return markerIdsToShow;
  }

  getMarkerIdsToHide(markerIdsToShow) {
    const markerIdsToHide = [];
    const markerIds = this.getMarkerIdsMatchingLegs();

    for (let markerId of Array.from(markerIds)) {
      if (
        !Array.from(markerIdsToHide).includes(markerId) &&
        !Array.from(markerIdsToShow).includes(markerId)
      ) {
        markerIdsToHide.push(markerId);
      }
    }

    return markerIds;
  }

  updateMarkerLabels() {
    const markerIds = this.getMarkerIdsMatchingLegs();
    let i = 0;
    return (() => {
      const result = [];
      while (i < this.legDirections.length) {
        if (this.legDirections[i].active) {
          const markerId1 = markerIds[i];
          const markerId2 = markerIds[i + 1];
          const label1 = (i + 1).toString();
          const label2 = (i + 2).toString();
          VisitWidget.map.getMarkerById(markerId1).serviceObject.labelContent =
            label1;
          VisitWidget.map.getMarkerById(markerId2).serviceObject.labelContent =
            label2;
        }
        result.push(i++);
      }
      return result;
    })();
  }

  getMarkerIdsMatchingLegs() {
    return this.getPlanItems().map((planItem) => {
      return $(planItem).data("marker-id");
    });
  }

  showRoute(legDirection) {
    VisitWidget.map.addPolyLine(legDirection.polyline);
    return this.zoomMap(legDirection);
  }

  addDirections() {
    return (() => {
      const result = [];
      for (let legDirection of Array.from(this.legDirections) as any[]) {
        const $instructions = this.getInstructions(legDirection);

        const $li = $("<li></li>");
        const $steps = $("<div class='plan-direction-steps'></div>");
        $steps.append($instructions);
        const $time = $("<div class='plan-direction-time'></div>");
        $time.html(
          VisitWidget.lib.translate("plan.start_plan.estimated_time") +
            " " +
            legDirection.duration,
        );
        $li.append($steps);
        $li.append($time);
        $("#plan_directions .slider ul").append($li);
        result.push(this.contractPlanDirections());
      }
      return result;
    })();
  }

  getInstructions(legDirection) {
    const instructions = legDirection.instructions.join(". ");
    return this.formatInstructions(instructions);
  }

  formatInstructions(instructions) {
    const $instructions = $("<div>" + instructions + "</div>");

    if (
      $instructions.length >= 1 &&
      $instructions.find("*:last").text().indexOf("Destination") === 0
    ) {
      const $lastDiv = $instructions.find("div").last();
      const lastDivHtml = `. ${$lastDiv.html()}.`;
      $lastDiv.html(lastDivHtml);
      $lastDiv.contents().unwrap();
    }

    $instructions.find("div").remove();
    return $instructions;
  }

  getActiveLegDirection() {
    for (let legDirection of Array.from(this.legDirections) as any[]) {
      if (legDirection.active) {
        return legDirection;
      }
    }
  }

  zoomMap(legDirection) {
    let yOffset;
    const bounds = new google.maps.LatLngBounds();
    legDirection.polyline.getPath().forEach((segment) => {
      return bounds.extend(segment);
    });

    if (VisitWidget.settings.isMobile) {
      VisitWidget.Mobile.map.resetMapHeight();
      yOffset = 0;
    } else {
      yOffset = $("#plan_directions").height() + 30;
    }
    return VisitWidget.map.centerAndFitToBounds(bounds, yOffset);
  }

  createLegDirections() {
    const legDirections = [];

    for (let route of Array.from(
      Gmaps.store.directionsDisplay.directions.routes,
    ) as any[]) {
      for (let leg of Array.from(route.legs) as any[]) {
        const legDirection = { active: false } as { [key: string]: any };
        legDirection.polyline = this.createPolyline();
        legDirection.instructions = [];
        legDirection.duration = leg.duration.text;

        for (let step of Array.from(leg.steps) as any[]) {
          legDirection.instructions.push(step.instructions);
          for (let segment of Array.from(step.path)) {
            legDirection.polyline.getPath().push(segment);
          }
        }

        legDirections.push(legDirection);
      }
    }

    this.setPlanItemDetailsToLegDirections(legDirections);
    legDirections[0].active = true;

    return legDirections;
  }

  createPolyline() {
    return new google.maps.Polyline({
      path: [],
      strokeColor: VisitWidget.settings.currentClientRouteColor,
      strokeWeight: 4,
    });
  }

  showLegDirectionDetails(legDirection) {
    $(".location_info").remove();

    $(".modal.map .header h2").text(legDirection.title);

    const source = $("#location_info_template").html();
    const template = Handlebars.compile(source);

    const context = {
      thumbnail: legDirection.thumbnail_path,
      address: legDirection.address,
      phone: legDirection.phone,
    };

    const postHtml = template(context);

    return $(".modal.map .modal-map-wrapper").before(postHtml);
  }

  getPlanItems() {
    const list_items = [];
    let previousMarkerId = null;

    $("#plan_list_items .post").each((index, planListItem) => {
      const $planListItem = $(planListItem);
      const markerId = $planListItem.data("marker-id");
      if (markerId !== previousMarkerId) {
        list_items.push($planListItem);
        return (previousMarkerId = markerId);
      }
    });

    return list_items;
  }

  setPlanItemDetailsToLegDirections(legDirections) {
    const list_items = this.getPlanItems();

    return (() => {
      const result = [];
      for (let index = 0; index < legDirections.length; index++) {
        const leg = legDirections[index];
        if (index < legDirections.length) {
          const item = list_items[index + 1];
          leg.title = item.data("title");
          leg.thumbnail_path = item.data("thumbnail-path");
          leg.address = item.data("address").toString().replace(",", ",<br />");
          result.push((leg.phone = item.data("phone")));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  togglePlanDirections() {
    const $activePlanDirectionItem = $(
      "#plan_directions #plan_directions_text_container li.unslider-active",
    );
    if ($activePlanDirectionItem.hasClass("line-clamp")) {
      return this.expandActivePlanDirection();
    } else {
      return this.contractPlanDirections();
    }
  }

  expandActivePlanDirection() {
    const $activePlanDirectionItem = $(
      "#plan_directions #plan_directions_text_container li.unslider-active",
    );
    $activePlanDirectionItem.removeClass("line-clamp");
    return $activePlanDirectionItem.removeClass("line-clamp-5");
  }

  contractPlanDirections() {
    const $planDirectionItems = $(
      "#plan_directions #plan_directions_text_container li",
    );
    $planDirectionItems.addClass("line-clamp");
    return $planDirectionItems.addClass("line-clamp-5");
  }
};
