/* eslint-disable */
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Drawer as AntDrawer } from "antd";
import { useSelector } from "react-redux";
import { selectFlyoutChild } from "../../../store/flyout/flyoutSelectors";

/**
 * A drawer that opens from the left side of the page. Currently only supports a Visit Widget
 * flyout.
 */
const Drawer: FunctionComponent<DrawerProps> = ({ width = 480 }) => {
  const [state, setState] = useState({
    component: undefined,
    props: undefined,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { component, props } = useSelector(selectFlyoutChild());

  useEffect(() => {
    // open the drawer when component is defined and closed when not
    const shouldOpen = !!component;
    if (shouldOpen !== isOpen) {
      setIsOpen(shouldOpen);
    }
  }, [component, isOpen]);

  useEffect(() => {
    // ensure the component is mounted the moment the drawer comes into view and stays mounted until
    // it leaves view
    if (isOpen && !isDrawerOpen) {
      setState({ component: component, props: props });
    }
    if (!isOpen && !isDrawerOpen) {
      setState({ component: undefined, props: undefined });
    }
    if (component !== state.component || props !== state.props) {
      // handle when the drawer is open and new component/props come in
      setState({ component: component, props: props });
    }
  }, [isOpen, component, props, isDrawerOpen]);

  const children = useMemo(() => {
    if (!state.component) {
      return <></>;
    }
    return React.createElement(state.component, state.props);
  }, [state.component, state.props]);

  return (
    <>
      <AntDrawer
        afterOpenChange={setIsDrawerOpen}
        open={isOpen}
        width={982}
        placement="left"
        mask={false}
        closable={false}
        style={{
          background: "none",
          pointerEvents: "none",
        }}
        rootStyle={{
          zIndex: 1,
          top: "138px",
          pointerEvents: "none",
        }}
        bodyStyle={{
          overflow: "hidden",
          padding: 0,
          display: "flex",
          justifyContent: "flex-end",
        }}
        contentWrapperStyle={{
          boxShadow: "none",
        }}>
        <div
          style={{
            width: `${width}px`,
            height: "100%",
            pointerEvents: "none",
            fontFamily: "'open sans', helvetica, arial, sans-serif",
          }}>
          <div
            className={isDrawerOpen ? "drawer-open" : ""}
            style={{
              pointerEvents: "auto",
            }}>
            {children}
          </div>
        </div>
      </AntDrawer>
    </>
  );
};

interface DrawerProps {
  width?: number;
  open?: boolean;
}

// const Drawer = withReduxProvider(DrawerBase);
export { Drawer, DrawerProps };
