import isUnitMetric from "./isUnitMetric";

interface NamedParameters {
  route: google.maps.DirectionsRoute;
}

export default function getRoutingTargetDistanceUnit({
  route,
}: NamedParameters): string {
  const isMetric = route.legs.some((leg) => {
    if (leg === undefined || leg.distance === undefined) {
      return false;
    }
    return isUnitMetric(leg.distance.text.split(" ")[1]);
  });
  return isMetric ? "km" : "mi";
}
