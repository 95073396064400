import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "../../Button";
import { Modal } from "../../Modal";
import { ModalContent, ModalTitle } from "../../Modal/Modal";
import { PlanItem as PlanItemComponent } from "../PlanItem";
import "./UserSharePlanModal.scss";
import { PlanNotes, PlanNotesProps } from "../PlanNotes";
import { Plan, PlanItem } from "../../../lib/apiTypes";
import { PlanStopSummary } from "../PlanStopSummary";
import { useDispatch } from "react-redux";
import { clientTheme } from "../../ConfigProvider";
import { addTourToPlanAction } from "../../../store/plan/actions/addTourToPlanAction";
import {
  createRoutesForPlanItems,
  mergePlanItemsAndRoutes,
  RoutesResponse,
} from "../../../domain/navigation/queries/getDirectionsForGeoCoordinates";
import { AppDispatch } from "../../../store/store";
import { UserPlanAggregate } from "../../../store/apiTypes";
import { addPlanItemsAction } from "../../../store/plan/actions/addPlanItemsAction";

const UserSharePlanScreenTestIDs = {
  addAllPlanItems: "add-all-plan-items",
};

/**
 * Modal that pops up on page load if query string params exist. Allows a user to see and add plan
 * items from a Shared Plan an admin created.
 */
const UserSharePlanScreen: FunctionComponent<UserSharePlanScreenProps> = ({
  plan,
  type,
  planToAddTo,
}) => {
  const [routes, setRoutes] = useState<RoutesResponse>(null);
  const [planItems, setPlanItems] = useState<
    Array<PlanItem & { distanceMeters?: number; durationMinutes?: number }>
  >([]);

  const dispatch = useDispatch<AppDispatch>();
  const [selectedPlanNotes, setSelectedPlanNotes] =
    useState<PlanNotesProps | null>(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    (async () => {
      const routesResult = await createRoutesForPlanItems(plan.planItems);
      setPlanItems(mergePlanItemsAndRoutes(plan.planItems, routesResult));
      setRoutes(routesResult);
    })();
  }, [plan]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddAll = () => {
    if (type === "tour") {
      dispatch(addTourToPlanAction(plan.id));
    } else if (type === "plan") {
      dispatch(addPlanItemsAction(plan.planItems.map((x) => x.id)));
    }
    handleClose();
  };

  const handleSelectPlanItemNotes = (planItem: PlanItem) => {
    setSelectedPlanNotes({
      title: planItem.title,
      notes: planItem.notes,
      coverPhotoUrl: planItem.coverPhotoUrl,
    });
  };

  return (
    <Modal open={isOpen} onClose={handleClose} width={560}>
      <ModalTitle fontSize="large">Shared with you: {plan.title}</ModalTitle>
      <ModalContent>
        {!selectedPlanNotes && (
          <div className="share-plan-modal-content">
            <div className="share-plan-modal-content-scroll">
              {!!plan.notes && (
                <div
                  className="share-plan-modal-plan-notes"
                  style={{ border: `1px solid ${clientTheme.colorPrimary}` }}>
                  <div className="share-plan-modal-plan-notes-title">
                    Plan Notes
                  </div>
                  <div>{plan.notes}</div>
                </div>
              )}

              <PlanStopSummary routes={routes} />

              {planItems.map((planItem, index) => (
                <PlanItemComponent
                  key={planItem.id}
                  planItem={planItem}
                  mode="summary"
                  stopNumber={index + 1}
                  planToAddTo={planToAddTo}
                  selectPlanItemNotes={handleSelectPlanItemNotes}
                />
              ))}
            </div>
            <div className="share-plan-modal-add-all-container">
              <Button
                type="primary"
                onPress={handleAddAll}
                testID={UserSharePlanScreenTestIDs.addAllPlanItems}>
                Add To My Plan
              </Button>
            </div>
          </div>
        )}
        {selectedPlanNotes && (
          <>
            <PlanNotes {...selectedPlanNotes} />
            <div className="share-plan-modal-back-to-plan">
              <Button
                type="primary"
                onPress={() => {
                  setSelectedPlanNotes(null);
                }}>
                Back To Plan
              </Button>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

interface UserSharePlanScreenProps {
  plan: Plan;
  type: "tour" | "plan";
  planToAddTo: UserPlanAggregate;
}

export { UserSharePlanScreen };
