import React, { FunctionComponent } from "react";
import "./PlanNotes.scss";

const PlanNotes: FunctionComponent<PlanNotesProps> = ({
  coverPhotoUrl,
  notes,
  title,
}) => {
  return (
    <>
      <div className="plan-notes-container">
        <div
          className="plan-notes-cover-photo"
          style={{ backgroundImage: `url(${coverPhotoUrl})` }}></div>

        <div className="plan-notes-details">
          <div className="plan-notes-title">{title}</div>
          <div className="plan-notes-notes">{notes}</div>
        </div>
      </div>
    </>
  );
};

interface PlanNotesProps {
  title: string;
  notes: string;
  coverPhotoUrl: string;
}

export { PlanNotes, PlanNotesProps };
