import React, { FunctionComponent } from "react";
import { FormInstance, Switch } from "antd";
import { ConfigProvider } from "../ConfigProvider";

const Toggle: FunctionComponent<ToggleProps> = ({
  name,
  testID,
  form,
  onChange,
  disabled,
}) => {
  return (
    <ConfigProvider type="regular">
      <Switch
        defaultChecked={form?.getFieldValue(name) === true}
        data-testid={testID}
        onChange={onChange}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};

interface ToggleProps {
  /**
   * Disable editing of the input.
   */
  disabled?: boolean;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute.
   */
  testID?: string;
  /**
   * DO NOT SET: Automatically passed in via Form.Item being the direct parent element.
   */
  onChange?: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  /**
   * Parent form, needed for default value to work.
   */
  form?: FormInstance<any>;
  /**
   * Parent form name of input, needed for default value to work.
   */
  name?: string;
}
export { Toggle, ToggleProps };
