/* eslint-disable max-statements */
import { RootReducerState } from "../../rootReducer";
import {
  listPlanItems,
  addPlanItemsToPlan,
  createPlan,
} from "../../visitWidgetApi";
import { setPlan } from "../planActionTypes";

/*
 * Add items to user plan.
 */
const addPlanItemsAction =
  (planItemIds: number[]) => async (dispatch, getState) => {
    const state = getState() as RootReducerState;
    let plan = state.plan.plan;
    if (!plan?.id) {
      const newPlan = await createPlan();
      VisitWidget.store.currentPlanId = newPlan.id;
      VisitWidget.planItemListController.enablePlanNameEditing(newPlan);
      plan = {
        id: newPlan.id,
        plan_items: [],
      };
    }

    await addPlanItemsToPlan(planItemIds, plan.id);

    // the plan items returned by add to tour are a minimal version, refetch plan items to get
    // the full list data
    const planItems = await listPlanItems(plan.id);

    const updatedPlan = { ...plan };
    updatedPlan.plan_items = planItems;

    VisitWidget.store.planItems = updatedPlan.plan_items;

    planItems.forEach((x) => {
      VisitWidget.PlanItemControl.updatePlanItemControlsToRemoveState({
        plannable_id: x.plannable_id,
        plannable_type: x.plannable_type,
      });
    });

    VisitWidget.EventBus.publish(new VisitWidget.Events.PlanItemAdded());

    dispatch(setPlan(updatedPlan));
  };

export { addPlanItemsAction };
