import translate from "../../../../lib/queries/translate";
import React, { useCallback } from "react";
import "./styles.scss";

export default function ResetButton({ onClick }) {
  const onLocalClick = useCallback(() => {
    let selectedExclusiveCategoryId;
    const changedCategoryIds = [null];
    for (const storageMenuItem of VisitWidget.store.menuItems) {
      if (storageMenuItem.selectedExclusiveCategoryId) {
        selectedExclusiveCategoryId =
          storageMenuItem.selectedExclusiveCategoryId;
      }
      storageMenuItem.selectedExclusiveCategoryId = null;
    }

    if (selectedExclusiveCategoryId) {
      changedCategoryIds.push(selectedExclusiveCategoryId);
      const event = new VisitWidget.Events.CategoriesChanged(
        changedCategoryIds,
      );
      VisitWidget.EventBus.publish(event);
      onClick();
    }
  }, [onClick]);

  return (
    <div className="ResetButton list-header-label-font" onClick={onLocalClick}>
      {translate("list.resetButton")}
    </div>
  );
}
