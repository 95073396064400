import React, { FunctionComponent, useEffect } from "react";

interface UpdateWidgetMessagePayload {
  categoryIds?: number[];
}

const updateWidgetFromParent = (payload: UpdateWidgetMessagePayload) => {
  if (payload.categoryIds !== undefined && payload.categoryIds !== null) {
    VisitWidget.store.currentMenuItem.active_category_ids = payload.categoryIds;
    VisitWidget.EventBus.publish(
      new VisitWidget.Events.CategoriesChanged(payload.categoryIds),
    );
    VisitWidget.categoryFilterControl.updateCheckboxesWithCurrentCategoryIds();
  }
};

const handleMessage = (event: MessageEvent<string>) => {
  try {
    const message = JSON.parse(event.data);
    if (message.type === "updateWidget") {
      updateWidgetFromParent(message.payload as UpdateWidgetMessagePayload);
    }
  } catch {
    // do nothing, not a message intended for this code to handle
  }
};

/**
 * Manage window.postMessage events from a parent iFrame as they relate to updating Segmented
 * Views. For example, can be used to update selected categories from client's website that has
 * embedded the Widget as an iFrame.
 */
const SegmentedCommunications: FunctionComponent = () => {
  useEffect(() => {
    if (window.self === window.top) {
      // No need to run logic if Widget is not in an iFrame.
      return;
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return <></>;
};

export { SegmentedCommunications };
