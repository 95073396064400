VisitWidget.MenuItemsController = class MenuItemsController {
  outDatedListMenuItemIds: any;

  constructor(menuItemIds) {
    this.outDatedListMenuItemIds = menuItemIds;
    VisitWidget.settings.disableOnMapGeoBoxChangedHandler = false;
  }

  getListControllerClassName(menuItem) {
    return `${menuItem.entity}ListController`;
  }

  getListControllerInstanceNameFromMenuItem(menuItem) {
    const controllerClassName = this.getListControllerClassName(menuItem);
    let controllerName =
      controllerClassName.charAt(0).toLowerCase() +
      controllerClassName.slice(1);

    if (menuItem.entity !== "PlanItem") {
      controllerName += menuItem.id.toString();
    }
    return controllerName;
  }

  initializeListController(menuItem) {
    const controllerName =
      this.getListControllerInstanceNameFromMenuItem(menuItem);

    if (menuItem.entity === "SharedPlan") {
      // do nothing, as we convert to react this is done differently
      return;
    }

    VisitWidget[controllerName] = new VisitWidget[
      this.getListControllerClassName(menuItem)
    ](menuItem);

    VisitWidget[controllerName].initialize();
  }

  updateDataForSpecifiedEntity(entity) {
    let menuItem;
    const menuItems = (() => {
      const result = [];
      for (menuItem of VisitWidget.store.menuItems) {
        if (entity === menuItem.entity) {
          result.push(menuItem);
        }
      }
      return result;
    })();

    return (() => {
      const result1 = [];
      for (menuItem of menuItems) {
        this.addOutDatedListMenuItem(menuItem);
        if (menuItem.id === VisitWidget.store.currentMenuItem.id) {
          if (menuItem.entity === "PlanItem") {
            // if plan items are added or removed we need reload the routing
            result1.push(this.populateList(menuItem));
          } else {
            this.populateList(menuItem);
            result1.push(this.populateMap());
          }
        } else {
          result1.push(undefined);
        }
      }
      return result1;
    })();
  }

  addOutDatedListMenuItem(menuItem) {
    if (this.outDatedListMenuItemIds.indexOf(menuItem.id) === -1) {
      return this.outDatedListMenuItemIds.push(menuItem.id);
    }
  }

  populateList(menuItem, callback = undefined) {
    if (VisitWidget.global.shouldDisableMainSidebar(menuItem)) {
      if (callback != null) {
        callback([]);
      }
      return;
    }

    return this.populateData(
      "list",
      this.populateListQueueFunction,
      { menuItem },
      callback,
    );
  }

  populateListQueueFunction(parameters, callback) {
    const { menuItem } = parameters;
    if (this.outDatedListMenuItemIds.indexOf(menuItem.id) === -1) {
      callback([]);
      return;
    }

    const controllerName =
      this.getListControllerInstanceNameFromMenuItem(menuItem);

    if (menuItem.entity === "SharedPlan") {
      // must call the callback or menu items not already loaded will never load
      callback([]);
      return;
    }

    return VisitWidget[controllerName].populate((entities) => {
      this.outDatedListMenuItemIds.splice(
        this.outDatedListMenuItemIds.indexOf(menuItem.id),
        1,
      );
      if (VisitWidget.settings.adsOn && menuItem.entity !== "PlanItem") {
        return VisitWidget.adInterpolator.resetListAds(menuItem, () => {
          return callback(entities);
        });
      } else {
        return callback(entities);
      }
    });
  }

  public populateMap(
    options = { recenter: false, resetMarkers: false, preserveRoutes: false },
    callback = undefined,
  ): void {
    if (options.recenter === undefined) {
      options.recenter = false;
    }
    if (options.resetMarkers === undefined) {
      options.resetMarkers = false;
    }
    if (options.preserveRoutes === undefined) {
      options.preserveRoutes = false;
    }
    this.populateData("map", this.populateMapQueueFunction, options, callback);
  }

  populateMapQueueFunction(parameters, callback) {
    return VisitWidget.map.refreshMarkers(parameters, callback);
  }

  populateData(dataType, queueFunction, parameters = {}, callback = undefined) {
    if (this[`${dataType}PopulateStarted`]) {
      this[`${dataType}PopulatePendingParameters`] = parameters;
      this[`${dataType}PopulatePendingCallback`] = callback;
      this[`${dataType}PopulatePending`] = true;
      return;
    }

    this[`${dataType}PopulateStarted`] = true;

    return queueFunction.apply(this, [
      parameters,
      (entities) => {
        if (callback != null) {
          callback(entities);
        }
        this[`${dataType}PopulateStarted`] = false;
        if (this[`${dataType}PopulatePending`]) {
          this[`${dataType}PopulatePending`] = false;

          const populateQueueFunctionName = `populate${
            dataType[0].toUpperCase() + dataType.slice(1)
          }QueueFunction`;
          this.populateData.apply(this, [
            dataType,
            this[populateQueueFunctionName],
            this[`${dataType}PopulatePendingParameters`],
            this[`${dataType}PopulatePendingCallback`],
          ]);

          this[`${dataType}PopulatePendingCallback`] = null;
          return (this[`${dataType}PopulatePendingParameters`] = []);
        }
      },
    ]);
  }
};
