/* eslint-disable react/display-name */
import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import ReactOnRails from "react-on-rails";

/**
 * Wrap a component with provider so it has access to Redux store. This is
 * necessary because ReactOnRails has no root to wrap with a Provider in a
 * traditional setup. This should be deprecated once off ReactOnRails.
 */
const withReduxProvider = function <T>(Component: FunctionComponent<T>) {
  return (props: T) => {
    const store = ReactOnRails.getStore("store", false);
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
  };
};

export { withReduxProvider };
