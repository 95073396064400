import React, { FunctionComponent } from "react";
import { Form, FormInstance, FormListFieldData } from "antd";
import { CustomInput } from "../CustomInput";
import { CustomInputDto } from "src/store/apiTypes";
import "./CustomInputsList.scss";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../../../../components/shared/sorting/SortableItem";
import { Button } from "src/components/Button";
import { PlusIcon } from "src/components/icons";
import translate from "src/lib/queries/translate";

const CustomInputsList: FunctionComponent<CustomInputsListProps> = ({
  mode,
  form,
}) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd =
    (move: (from: number, to: number) => void, fields: FormListFieldData[]) =>
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const oldIndex = fields.findIndex(
          (input) => input.key + 1 === active.id,
        );
        const newIndex = fields.findIndex((input) => input.key + 1 === over.id);
        move(oldIndex, newIndex);
      }
    };

  const addBlankCustomInput =
    (add: (newElement: CustomInputDto) => void) => (_event: any) => {
      add({
        id: null,
        label: "",
        position: 0,
        required: false,
        input_type: "textinput",
        values: [],
      });
    };

  // const resetFieldsToDefault = (event) => {};

  return (
    <>
      <div className="sortable-item-list-container">
        <Form.List name="customInputs">
          {(fields, { add, remove, move }, { errors }) => (
            <>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd(move, fields)}>
                <SortableContext
                  items={fields.map((x) => {
                    return { ...x, id: x.key + 1 };
                  })}
                  strategy={verticalListSortingStrategy}>
                  {fields.map((field) => (
                    <SortableItem
                      key={field.key + 1}
                      id={field.key + 1}
                      isSortEnabled={mode === "edit"}>
                      <CustomInput
                        form={form}
                        mode={mode}
                        formListFieldData={field}
                        onRemove={remove}
                      />
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
              <div className="custom-input-controls">
                <div>
                  <Button
                    type="outline"
                    icon={<PlusIcon />}
                    onPress={addBlankCustomInput(add)}
                    disabled={fields.length > 9}>
                    {translate("challenge_submission_config.add_field")}
                  </Button>
                </div>
                <div>
                  {/* <Button type="linkSmall" onPress={resetFieldsToDefault}>
                    {translate(
                      "challenge_submission_config.reset_fields_to_default",
                    )}
                  </Button> */}
                </div>
              </div>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

interface CustomInputsListProps {
  mode?: "summary" | "view" | "edit";
  form: FormInstance<any>;
}

export { CustomInputsList, CustomInputsListProps };
