import { Category, Entity } from "../../lib/types";
import getCategoryFromId from "../../categories/queries/getCategoryFromId";
import getFirstCategoryThatMatchesCurrentCategories from "../../categories/queries/getFirstCategoryThatMatchesCurrentCategories";

const getCategoryForMapMarker = async (
  entities: Array<Entity>,
): Promise<Category> => {
  let category = await getFirstCategoryThatMatchesCurrentCategories(entities);

  if (!category) {
    // Shouldn't ever happen but just in case it does
    // addendum to above: can easily happen for tours
    let categoryId = entities[0].category_ids.find((x) => {
      return getCategoryFromId(x)?.exclusive === false;
    });
    if (categoryId === undefined) {
      categoryId = entities[0].category_ids[0];
    }
    category = getCategoryFromId(categoryId);
  }

  return category;
};

export default getCategoryForMapMarker;
