import React, { FunctionComponent } from "react";
import { Checkbox as AntCheckbox, Form, FormInstance } from "antd";
import "./styles.scss";
import { ConfigProvider } from "../ConfigProvider";

const Checkbox: FunctionComponent<CheckboxProps> = ({
  children,
  disabled = false,
  name,
}) => {
  return (
    <ConfigProvider type="regular">
      <Form.Item name={name} noStyle={true} valuePropName="checked">
        <AntCheckbox disabled={disabled}>{children}</AntCheckbox>
      </Form.Item>
    </ConfigProvider>
  );
};

export interface CheckboxProps {
  /**
   * The checkbox label
   */
  children: string;
  /**
   * Disable checkbox
   */
  disabled?: boolean;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute
   */
  testID?: string;
  /**
   * Name of the field.
   */
  name: string | Array<number | string>;
  /**
   * Reference to parent form.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
}

export { Checkbox };
