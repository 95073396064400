import { Theme } from "react-select";
import * as colors from "../constants";

export default function getCustomStyles(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral0: colors.white,
      primary25: VisitWidget.settings.currentClientPrimaryColor,
      primary: "black",
    },
  };
}
