VisitWidget.Commands.ShowMapItLocation = class ShowMapItLocation {
  // for legacy calls
  static call($mapIt, hideFlyout, callback) {
    const markerId = $mapIt.data("marker-id");
    const entityId = $mapIt.data("entity-id");
    const entityType = $mapIt.data("entity-type");
    const mapLayerUrl = $mapIt.data("entity-map-layer-url");

    // return if there is no location (virtual)
    if (!markerId) {
      return;
    }

    VisitWidget.map.addMarkerIfNotPresent(
      markerId,
      entityId,
      entityType,
      (marker) => {
        VisitWidget.map.removeSubpageMapLayers();
        if (VisitWidget.settings.isMobile) {
          VisitWidget.Commands.ShowMobileMapModal.call($mapIt);
        } else if (marker != null) {
          VisitWidget.map.showMarkerInfoWindow(marker, hideFlyout, true);
        }
        VisitWidget.map.loadSubPageMapLayer(mapLayerUrl, "listItem");
        if (callback) {
          callback();
        }
      },
    );
  }
};

// For React
export const showMapItLocation = (markerId, entityId, entityType) => {
  if (!markerId) {
    return;
  }

  return new Promise((resolve) => {
    VisitWidget.map.addMarkerIfNotPresent(
      markerId,
      entityId,
      entityType,
      (marker) => {
        VisitWidget.map.removeSubpageMapLayers();
        if (marker != null) {
          VisitWidget.map.showMarkerInfoWindow(marker, false, true);
        }
        resolve(marker);
      },
    );
  });
};
