import React, { FunctionComponent } from "react";
import { Button } from "../../../Button";
import { CopyIcon, PencilIcon, TrashIcon } from "../../../icons";
import "./SharedPlanEditBar.scss";
import { PopMenuButton } from "../../../PopMenuButton";
import { useDispatch } from "react-redux";
import { duplicateTourAction } from "../../../../store/plan/actions/duplicateTourAction";
import { deleteTourAction } from "../../../../store/plan/actions/deleteTourAction";
import { setTourEditAction } from "../../../../store/plan/actions/setTourEditAction";
import { AppDispatch } from "../../../../store/store";

const SharedPlanEditBar: FunctionComponent<SharedPlanEditBarProps> = ({
  onSave,
  tourId,
  isEditing,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleEditPress = (isEditingUpdate: boolean) => {
    dispatch(setTourEditAction(tourId, isEditingUpdate));
  };

  const handleDuplicate = () => {
    dispatch(duplicateTourAction(tourId));
  };

  const handleDelete = () => {
    dispatch(deleteTourAction(tourId));
  };

  return (
    <div className="plan-edit-bar-container">
      <div className="plan-edit-bar-container-left">
        <PopMenuButton
          type="icon"
          icon={<CopyIcon />}
          menuItems={[
            {
              id: "duplicate",
              title: "Duplicate",
              onPress: handleDuplicate,
            },
          ]}
        />
        <PopMenuButton
          type="icon"
          icon={<TrashIcon />}
          menuItems={[
            {
              id: "delete",
              title: "Delete",
              onPress: handleDelete,
            },
          ]}
        />
      </div>
      <div className="plan-edit-bar-container-right">
        {!isEditing && (
          <Button
            type="icon"
            icon={<PencilIcon />}
            onPress={() => {
              handleEditPress(true);
            }}
          />
        )}
        {isEditing && (
          <>
            <Button
              type="text"
              onPress={() => {
                handleEditPress(false);
              }}>
              Cancel
            </Button>
            <Button type="text" onPress={onSave}>
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

interface SharedPlanEditBarProps {
  onSave: () => void;
  tourId: number;
  isEditing: boolean;
}

export { SharedPlanEditBar, SharedPlanEditBarProps };
