import React, { useCallback, useState } from "react";
import "./styles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type RuleType = "anytime" | "time_based" | "streak" | "voting";
type StreakTimeUnit = "day" | "week" | "month";

interface GoalRuleInputsProps {
  ruleTypes: Array<RuleType>;
  streakTimeUnits: Array<StreakTimeUnit>;
}

const GoalRuleInputs = ({
  ruleTypes,
  streakTimeUnits,
}: GoalRuleInputsProps) => {
  const [selectedRuleType, setSelectedRuleType] = useState<RuleType>("anytime");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [minEndDate, setMinEndDate] = useState(new Date());

  const onRuleTypeChange = useCallback((event) => {
    const ruleType = event?.target?.value;
    setSelectedRuleType(ruleType);
  }, []);

  const onSetStartDate = useCallback(
    (date) => {
      const todayDate = new Date();
      setStartDate(date);
      const minDate = date < todayDate ? todayDate : date;
      setMinEndDate(minDate);
      if (endDate < minDate) {
        setEndDate(minDate);
      }
    },
    [endDate],
  );

  const onSetEndDate = useCallback((date) => {
    setEndDate(date);
  }, []);

  const getInputName = (attribute) =>
    `goal[goal_rule_attributes][${attribute}]`;

  return (
    <div className="GoalRuleInputs">
      <div className="clearfix mt15">
        <label htmlFor={getInputName("rule_type")}>Rule Type</label>
        <br />
        <select
          className="goalInputField"
          name={getInputName("rule_type")}
          onChange={onRuleTypeChange}
          required>
          {ruleTypes.map((ruleType) => (
            <option key={ruleType} value={ruleType}>
              {ruleType}
            </option>
          ))}
        </select>
      </div>
      <div className="clearfix mt15">
        <label htmlFor={getInputName("required_number_of_completions")}>
          {(selectedRuleType === "voting" && "Votes Allowed") ||
            "Required Number of Completions"}
        </label>
        <br />
        <input
          className="goalInputField"
          defaultValue="1"
          type="number"
          name={getInputName("required_number_of_completions")}
          min="1"
          required
        />
      </div>
      {selectedRuleType === "streak" && (
        <React.Fragment>
          <div className="clearfix mt15">
            <label htmlFor={getInputName("streak_time_unit_multiplier")}>
              Streak Time Unit
            </label>
            <br />
            <input
              className="goalInputField goalInputField--half-width"
              type="number"
              name={getInputName("streak_time_unit_multiplier")}
              min="1"
              defaultValue="1"
              placeholder={"Multiplier"}
              required
              data-testid="streak_time_unit_multiplier_input"
            />
            <select
              className="goalInputField goalInputField--half-width"
              name={getInputName("streak_time_unit")}
              data-testid="streak_time_unit_select"
              required>
              {streakTimeUnits.map((timeUnit) => (
                <option key={timeUnit} value={timeUnit}>
                  {timeUnit}
                </option>
              ))}
            </select>
          </div>
          <div className="clearfix mt15">
            <label
              htmlFor={getInputName(
                "required_number_of_completions_per_time_unit",
              )}>
              Required Number of Completions Per Time Unit
            </label>
            <br />
            <input
              className="goalInputField"
              type="number"
              name={getInputName(
                "required_number_of_completions_per_time_unit",
              )}
              min="1"
              defaultValue="1"
              required
            />
          </div>
        </React.Fragment>
      )}
      {(selectedRuleType === "time_based" || selectedRuleType === "voting") && (
        <React.Fragment>
          <div className="clearfix mt15">
            <label htmlFor={getInputName("starts_on")}>Starts On</label>
            <DatePicker
              dateFormat={"yyyy-MM-dd"}
              selected={startDate}
              required
              name={getInputName("starts_on")}
              onChange={onSetStartDate}
            />
          </div>
          <div className="clearfix mt15">
            <label htmlFor={getInputName("ends_on")}>Ends On</label>
            <DatePicker
              dateFormat={"yyyy-MM-dd"}
              selected={endDate}
              minDate={minEndDate}
              required
              name={getInputName("ends_on")}
              onChange={onSetEndDate}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export { GoalRuleInputs, GoalRuleInputsProps };
