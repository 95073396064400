/* eslint-disable */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "../../Button";
import { Plan, PlanItem } from "../../../lib/apiTypes";
import { ShareIcon, ChevronLeftIcon } from "../../icons";
import { PlanStopSummary } from "../PlanStopSummary";
import { PlanItemList } from "../PlanItemList/PlanItemList";
import { PlanNotesModal } from "../PlanNotesModal";
import { SharePlanModal } from "../SharePlanModal";
import "./SharedPlanScreen.scss";
import { withReduxProvider } from "../../../store/withReduxProvider";
import { useDispatch } from "react-redux";
import { setFlyoutAction } from "../../../store/flyout/actions/setFlyoutAction";
import { SharedPlanEditBar } from "./SharedPlanEditBar/SharedPlanEditBar";
import { useSelector } from "react-redux";
import {
  selectIsEditingSharedPlan,
  selectSharedPlan,
} from "../../../store/plan/planSelectors";
import { UserPlanAggregate } from "../../../store/apiTypes";
import { getTour, adaptTourDtoToPlan } from "../../../store/visitWidgetApi";
import { Form } from "../../form/Form/Form";
import { TextInput } from "../../TextInput";
import { setSharedPlanAction } from "../../../store/plan/actions/setSharedPlanAction";
import {
  createRoutesForPlanItems,
  createRoutesForSharedPlan,
  mergePlanItemsAndRoutes,
  PlanItemWithRoute,
} from "../../../domain/navigation/queries/getDirectionsForGeoCoordinates";
import { updateSharedPlanAction } from "../../../store/plan/actions/updateSharedPlanAction";
import { useWindowSize } from "../../../lib/hooks/useWindowSize";

/**
 * Root single Shared Plan screen displayed in flyout. Implicitly only for admins due to inability
 * to navigate to otherwise.
 */
const SharedPlanScreenBase: FunctionComponent<
  SharedPlanScreenProps & { sharedPlan: Plan }
> = ({ sharedPlan }) => {
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(setFlyoutAction() as any);
  };

  const [planItems, setPlanItems] = useState<
    Array<PlanItem & { distanceMeters?: number; durationSeconds?: number }>
  >([]);
  const [routes, setRoutes] = useState(null);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const isEditing = useSelector(selectIsEditingSharedPlan());
  const [state, setState] = useState<{ status: string }>({ status: "idle" });
  const windowSize = useWindowSize();
  const planItemListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = planItemListRef.current;
    if (div) {
      const newHeight = windowSize.height - div.getBoundingClientRect().top;
      planItemListRef.current.style.height = `${newHeight}px`;
    }
  }, [windowSize, planItemListRef]);

  useEffect(() => {
    (async () => {
      const routes = await createRoutesForPlanItems(sharedPlan.planItems);
      const planItemsWithRoutes = mergePlanItemsAndRoutes(
        sharedPlan.planItems,
        routes,
      );
      setPlanItems(planItemsWithRoutes);
    })();
  }, [sharedPlan]);

  useEffect(() => {
    (async () => {
      const routes = await createRoutesForSharedPlan(sharedPlan);
      setRoutes(routes);
    })();
  }, [sharedPlan]);

  const handleUpdatePlanItems = (planItems: PlanItemWithRoute[]) => {
    // set it immediately so items don't appear jumpy but then update with route data async
    setPlanItems(
      planItems.map((x) => {
        x.durationSeconds = undefined;
        x.distanceMeters = undefined;
        return x;
      }),
    );

    (async () => {
      const routes = await createRoutesForPlanItems(planItems);
      const planItemsWithRoutes = mergePlanItemsAndRoutes(planItems, routes);
      setPlanItems(planItemsWithRoutes);
      sharedPlan.planItems = planItems;
    })();
  };

  const planToAddTo = {
    id: sharedPlan.id,
    plan_items: sharedPlan.planItems.map((x) => {
      return {
        id: x.id,
        plannable_id: x.originalDto.plannable_id,
        plannable_type: x.originalDto.plannable_type,
      };
    }),
  } as UserPlanAggregate;

  const handleSave = useCallback(
    async (values: { title: string }) => {
      try {
        setState({ status: "fetching" });
        await dispatch(updateSharedPlanAction() as any);
        setState({ status: "idle" });
      } catch (error) {
        setState({ status: "error" });
      }
    },
    [sharedPlan.id],
  );

  const [form] = Form.useForm();

  return (
    <>
      <div>
        <div className="shared-plan-screen-details">
          <div style={{ padding: "12px" }}>
            <div className="shared-plan-screen-header">
              <div className="shared-plan-screen-back">
                <Button
                  icon={<ChevronLeftIcon />}
                  onPress={handleBack}
                  type="icon"
                />
              </div>
              <div className="shared-plan-screen-title">
                {!isEditing ? sharedPlan.title : "Edit Shared Plan"}
              </div>
              {!isEditing && (
                <div className="shared-plan-screen-share">
                  <Button
                    icon={<ShareIcon />}
                    onPress={() => {
                      setIsShareOpen(true);
                    }}
                    type={"icon"}
                  />
                </div>
              )}
            </div>

            {isEditing && (
              <div className="shared-plan-edit-title">
                <Form
                  form={form}
                  onFinish={handleSave}
                  disabled={state.status === "fetching"}
                  initialValues={{ title: sharedPlan.title }}
                  onValuesChange={(update: { title: string }) => {
                    sharedPlan.title = update.title;
                  }}>
                  <Form.Item name="title" noStyle={true}>
                    <TextInput
                      name="title"
                      size="large"
                      form={form}
                      placeholder="Plan name"
                    />
                  </Form.Item>
                </Form>
              </div>
            )}

            <div className="shared-plan-screen-plan-notes">
              <Button
                type="pillOutline"
                onPress={() => {
                  setIsNotesOpen(true);
                }}>
                Plan Notes
              </Button>
            </div>

            <PlanStopSummary routes={routes} />
          </div>
          <SharedPlanEditBar
            onSave={() => {
              form.submit();
            }}
            isEditing={isEditing}
            tourId={sharedPlan.id}
          />
        </div>
        <div ref={planItemListRef} style={{ overflowY: "auto" }}>
          <PlanItemList
            planItems={planItems}
            planToAddTo={planToAddTo}
            mode={isEditing ? "edit" : "view"}
            tourId={sharedPlan.id}
            routes={routes}
            updatePlanItems={handleUpdatePlanItems}
          />
        </div>
      </div>
      <PlanNotesModal
        type="plan"
        notes={sharedPlan.notes}
        open={isNotesOpen}
        coverPhotoUrl={sharedPlan.coverPhotoUrl}
        title={sharedPlan.title}
        onClose={() => {
          setIsNotesOpen(false);
        }}
        isEditMode={isEditing}
        tourId={sharedPlan.id}
      />
      <SharePlanModal
        tourId={sharedPlan.id}
        open={isShareOpen}
        shareUrl={sharedPlan.shareUrl}
        onClose={() => {
          setIsShareOpen(false);
        }}
        {...sharedPlan}
      />
    </>
  );
};

interface SharedPlanScreenProps {
  tourId: number;
  planToAddTo: Plan;
}

const SharedPlanScreenState = (props: SharedPlanScreenProps) => {
  const sharedPlan = useSelector(selectSharedPlan());
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setSharedPlanAction(undefined) as any);
      const tour = await getTour(props.tourId);
      dispatch(setSharedPlanAction(adaptTourDtoToPlan(tour)) as any);
    })();
  }, [props.tourId]);

  if (!sharedPlan) {
    return (
      <div className="shared-plan-screen-details loading">
        <img src="/images/ajax-loader.gif" />
      </div>
    );
  }

  return <SharedPlanScreenBase {...props} sharedPlan={sharedPlan} />;
};

const SharedPlanScreen = withReduxProvider(SharedPlanScreenState);
export { SharedPlanScreen, SharedPlanScreenProps };
