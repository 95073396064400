import React, { FunctionComponent, useCallback } from "react";
import { useSelector } from "react-redux";
import translate from "../../../../../lib/queries/translate";
import { selectUser } from "../../../../../store/auth/authSelectors";
import { withReduxProvider } from "../../../../../store/withReduxProvider";

// only used for mobile view, should be deprecated when desktop and mobile share views
const ProfileBase: FunctionComponent<{}> = () => {
  const user = useSelector(selectUser());

  const handleSignInClick = useCallback(() => {
    VisitWidget.registrationModal.openModalAction("sign_in");
  }, []);

  return (
    <>
      {!!user && (
        <a href={`/users/${user.id}`} data-push="true">
          <img
            src={user.profile_thumbnail}
            className="facebook-thumbnail"
            alt=""
          />
          <span className="profile_name">{user.name}</span>
        </a>
      )}
      {!user && (
        <div className="connect_action sign_in" onClick={handleSignInClick}>
          {translate("profile_menu.sign_in")}
        </div>
      )}
    </>
  );
};

const Profile = withReduxProvider(ProfileBase);
export { Profile };
