import React, { FunctionComponent } from "react";
import { Form, FormInstance, Radio as AntRadio, Space } from "antd";
import { ConfigProvider } from "../ConfigProvider";

const Radio: FunctionComponent<RadioProps> = ({
  testID,
  disabled,
  form,
  name,
  onChange,
  options,
  direction = "horizontal",
}) => {
  return (
    <ConfigProvider type="regular">
      <Form.Item name={name} noStyle={true}>
        <AntRadio.Group
          onChange={onChange}
          disabled={disabled}
          // defaultValue={form?.getFieldValue(name)}
          data-testid={testID}>
          <Space direction={direction}>
            {options.map((option) => (
              <AntRadio value={option.value} key={option.value}>
                {option.label}
              </AntRadio>
            ))}
          </Space>
        </AntRadio.Group>
      </Form.Item>
    </ConfigProvider>
  );
};

interface RadioOption {
  value: string | number;
  label: string;
}

interface RadioProps {
  /**
   * Disable editing of the input.
   */
  disabled?: boolean;
  /**
   * Test ID for selection in automated testing, sets `data-testid` attribute.
   */
  testID?: string;
  /**
   * DO NOT SET: Automatically passed in via Form.Item being the direct parent element.
   */
  onChange?: (event: {
    target: { checked: boolean };
    stopPropagation: () => void;
    preventDefault: () => void;
    nativeEvent: MouseEvent;
  }) => void;
  /**
   * Parent form, needed for default value to work.
   */
  form?: FormInstance<any>;
  /**
   * Parent form name of input, needed for default value to work.
   */
  name?: string | number | (string | number)[];
  /**
   * The options that appear in the Radio.
   */
  options: RadioOption[];
  direction?: "horizontal" | "vertical";
}

export { Radio, RadioProps, RadioOption };
