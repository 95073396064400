/* tslint:disable */
/* eslint:disable */

import { setCookie, CookieKeys, getCookie } from "../../../lib/cookies";

VisitWidget.TutorialModal = class TutorialModal {
  public slider: any;

  constructor() {
    this.onModalClosed = this.onModalClosed.bind(this);
  }

  initialize = async () => {
    this.slider = ($(".modal.tutorial .slider") as any).unslider({
      infinite: true,
      arrows: false,
      fluid: true,
    });

    this.wireModalEvents();
    this.setTitle();
    this.setNextAndPreviousButtonVisiblity();

    const isViewed = await getCookie(CookieKeys.tutorialViewed, false);
    // do not show tutorial when user comes to shared plan, eg has an access token
    const accessToken = new URLSearchParams(window.location.search).get(
      "access_token",
    );

    if (
      VisitWidget.settings.openTutorialOnSiteLoad &&
      !isViewed &&
      !accessToken
    ) {
      return this.openModal();
    }
  };

  wireModalEvents() {
    $(".tutorial-trigger").on("click", this.openModal);

    $(".modal.tutorial .modal-tutorial-close").on("click", (e) => {
      return VisitWidget.modal.close($(".modal.tutorial"));
    });

    $(".modal.tutorial a.unslider-arrow.prev").on("click", () => {
      return this.slider.unslider("prev");
    });

    $(".modal.tutorial a.unslider-arrow.next").on("click", () => {
      return this.slider.unslider("next");
    });

    this.slider.on("unslider.change", (_event: any, index: any, slide: any) => {
      this.setTitle();
      this.setNextAndPreviousButtonVisiblity();
    });

    return $(".open-tutorial").on("click", () => {
      $(".open-tutorial").addClass("active");
      return this.openModal();
    });
  }

  setTutorialViewCookie() {
    setCookie(CookieKeys.tutorialViewed, "true", 1825);
  }

  openModal() {
    return VisitWidget.modal.open(
      $(".modal.tutorial"),
      null,
      this.onModalClosed,
    );
  }

  onModalClosed() {
    $(".open-tutorial").removeClass("active");
    return this.setTutorialViewCookie();
  }

  setNextAndPreviousButtonVisiblity() {
    if (
      $(".modal.tutorial .slider li:not('.unslider-clone')")
        .first()
        .hasClass("unslider-active")
    ) {
      $(".modal.tutorial a.unslider-arrow.prev").hide();
      return $(".modal.tutorial a.unslider-arrow.next").css(
        "display",
        "inline",
      );
    } else if (
      $(".modal.tutorial .slider li:not('.unslider-clone')")
        .last()
        .hasClass("unslider-active")
    ) {
      $(".modal.tutorial a.unslider-arrow.prev").css("display", "inline");
      return $(".modal.tutorial a.unslider-arrow.next").hide();
    } else {
      $(".modal.tutorial a.unslider-arrow.prev").css("display", "inline");
      return $(".modal.tutorial a.unslider-arrow.next").css(
        "display",
        "inline",
      );
    }
  }

  setTitle() {
    const name = $(".modal.tutorial .slider li.unslider-active").data("name");
    return $(".modal.tutorial .title").html(name);
  }
};
