/* eslint-disable max-len */

VisitWidget.TourListController = class TourListController {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  pagingListControl: any;
  menuItem: any;
  sidebarSelector: string;
  containerSelector: string;
  $container: JQuery<HTMLElement>;
  tourListItemsControl: any;
  getTourListItemHtml: any;
  listItemTemplate: any;
  listDragAndDropControl: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  constructor(menuItem) {
    this.onScrollToBottom = this.onScrollToBottom.bind(this);
    this.menuItem = menuItem;

    this.sidebarSelector = `#sidebar_${this.menuItem.entity}${this.menuItem.id}`;
    this.containerSelector = `${this.sidebarSelector} .tour-list-items`;
  }

  initialize() {
    this.$container = $(this.containerSelector);
    this.tourListItemsControl = new VisitWidget.TourListItemsControl(
      this.containerSelector,
      { panMapOnHover: VisitWidget.settings.listHoverMapPanEnabled },
    );
    this.pagingListControl = new VisitWidget.PagingListControl(
      this.sidebarSelector,
      this.onScrollToBottom,
    );
    const templateName = `#tour_${VisitWidget.settings.entity_list_item_layout}_list_item_template`;
    this.listItemTemplate = Handlebars.compile($(templateName).html());
    this.getTourListItemHtml = new VisitWidget.GetTourListItemHtml();
    if (VisitWidget.settings.adminModeOn) {
      this.listDragAndDropControl = new VisitWidget.ListDragAndDropControl(
        this.containerSelector,
        this.getTourId,
        this.onDragAndDrop,
      );
    }
    this.pagingListControl.initialize();
    this.tourListItemsControl.initialize();
  }

  page() {
    return this.pagingListControl.page;
  }

  populate = async (callback) => {
    this.$container.empty();
    this.$container.removeClass("no-categories");
    const categoryIds = await this.getCategoryIds();

    this.pagingListControl.reset();
    return this.getTours(
      {
        page: this.pagingListControl.page,
        category_ids: categoryIds,
      },
      (tours) => {
        this.addToursToList(tours);
        this.toggleNoCategoriesNotice();
        if (VisitWidget.settings.adminModeOn) {
          this.listDragAndDropControl.initialize();
        }
        if (!!callback) {
          return callback(tours);
        }
      },
    );
  };

  addToursToList(tours) {
    const toursHtml = this.getTourListItemHtml.call(
      tours,
      this.listItemTemplate,
    );
    return this.tourListItemsControl.addListItemsHtml(toursHtml);
  }

  toggleNoCategoriesNotice() {
    if (this.$container.find(".tour.post").length === 0) {
      this.$container.addClass("no-categories");
      const text = VisitWidget.lib.translate("list.empty");

      if (VisitWidget.settings.isMobile) {
        return this.$container.append(
          `<div style='padding: 10px;'>${text}</div>`,
        );
      } else {
        return this.$container.append(
          `<div class='post tour clearfix' style='min-height: 20px;'>${text}</div>`,
        );
      }
    }
  }

  onScrollToBottom = async (page) => {
    const categoryIds = await this.getCategoryIds();
    return this.getTours({ page, category_ids: categoryIds }, (tours) => {
      let noMorePosts;
      this.addToursToList(tours);
      if (tours.length < VisitWidget.settings.tourPageSize) {
        noMorePosts = true;
      }
      return this.pagingListControl.onQueryComplete(noMorePosts);
    });
  };

  getCategoryIds() {
    return VisitWidget.global.getCategoryIds(this.menuItem, true) as Promise<
      string[]
    >;
  }

  onDragAndDrop(toursAttributes) {
    return VisitWidget.Tour.updatePositions({ tours: toursAttributes });
  }

  getTourId($post) {
    return $post.data("id");
  }

  getTours(params, callback) {
    params.type = null;
    params.include_all_tour_types_enabled =
      this.menuItem.include_all_tour_types_enabled;

    params.exclusive_category_id = this.menuItem.selectedExclusiveCategoryId;

    return VisitWidget.Tour.query(params, callback);
  }
};
