import React, { FunctionComponent, useCallback } from "react";

const Websites: FunctionComponent<WebsitesProps> = ({
  entityClassName,
  entityId,
  entityName,
  websiteUrl,
  label,
  imageUrl,
}) => {
  const onWebsiteClicked = useCallback(
    (event) => {
      if (VisitWidget.settings.isKiosk) {
        event.preventDefault();
        VisitWidget.modal.open($(".modal.web_view"), () => {
          VisitWidget.modal.open(
            $(".modal.web_view"),
            () => {
              $(".modal.web_view .header h2").text(label);
              return $(".modal.web_view iframe").attr("src", websiteUrl);
            },
            () => {
              return $(".modal.web_view iframe").removeAttr("src");
            },
          );
        });
      }

      VisitWidget.Analytics.createWebsiteClick(
        entityId,
        entityClassName,
        entityName,
      );
    },
    [entityId, entityClassName, entityName, label, websiteUrl],
  );

  if (!websiteUrl || !VisitWidget.settings.isWebsitesEnabled) {
    return <></>;
  }

  return (
    <div>
      <div className="inner-column-wrapper website">
        <a
          href={websiteUrl}
          target="_blank"
          rel="noreferrer"
          onClick={onWebsiteClicked}>
          <img src={imageUrl} className="container-image" />
          <p>{label}</p>
        </a>
      </div>
    </div>
  );
};

export interface WebsitesProps {
  entityClassName: string;
  entityName: string;
  entityId: string;
  websiteUrl: string;
  label: string;
  imageUrl: string;
}

export default Websites;
