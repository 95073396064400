import {
  SharedPlanScreen,
  SharedPlanScreenProps,
} from "../../components/plans/SharedPlanScreen";
import { RootReducerState } from "../rootReducer";

export const selectFlyoutChild = () => (x: RootReducerState) => x.flyouts;

export const selectFlyoutSharedPlanId = () => (x: RootReducerState) => {
  if (!!x.flyouts.component && x.flyouts.component === SharedPlanScreen) {
    return (x.flyouts.props as SharedPlanScreenProps).tourId;
  }
  return undefined;
};
